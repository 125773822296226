import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Product } from '../models/product.model';
import { ProductTemplateAttributeValue } from '../models/product.template.attribute.value.model';
import { PurchaseOrderLine } from '../models/purchase-order-line.model';
import { PurchaseOrder } from '../models/purchase-order.model';
import { StockMove } from '../models/stock-move';
import { StockPicking } from '../models/stock-picking';
import { GapiService } from '../shared/services/g-api.service';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { PRINTS_PURCHASE_CFG } from 'src/app/models/deal';
import { AccountTax } from '../models/account-tax.model';
import { ProductSupplierinfo } from '../models/product.supplierinfo';
import { first } from 'rxjs';

@Component({
  selector: 'app-purchase-print',
  templateUrl: './purchase-print.component.html'
})
export class PurchasePrintComponent implements OnInit {
  id: any;
  loading: boolean;
  pickings: StockPicking[];
  moves: StockMove[];
  order: PurchaseOrder;

  constructor(
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private gapiService: GapiService,
    private odooEm: OdooEntityManager
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(async params => {
      this.id = params['id']
      // available routes in sale
      await this.load()

      this.existCondition()
    })



  }


  existCondition() {
    var existCondition = setInterval(async () => {
      if (this.elementRef.nativeElement.querySelectorAll('[data-print-col]').length && this.elementRef.nativeElement.querySelectorAll('[data-print-col]').length > 0) {
        console.log("Exists! ", document.querySelectorAll('[data-print-col]').length);
        clearInterval(existCondition);
        await this.print()
      }
    }, 100); // check every 100ms
  }


  async load() {

    this.loading = true

    var order: PurchaseOrder = (await this.odooEm.search<PurchaseOrder>(new PurchaseOrder(), [["id", "=", this.id]]).toPromise())[0]
    await this.odooEm.resolveSingle(new StockMove(), order.partner_id).toPromise()
    // await this.odooEm.resolveSingle(new StockPickingType(), order.picking_type_id).toPromise()

    await this.odooEm.resolve(order.order_line).toPromise()
    await this.odooEm.resolveArray(new StockMove(), order.order_line.values, "move_ids").toPromise()

    var moveids = order.order_line.values.map(l => l.move_ids.values).reduce((acc, val) => acc.concat(val), []);
    await this.odooEm.resolveArrayOfSingle(new StockPicking(), moveids, "picking_id").toPromise()

    order.notes = order.notes.replace(/<[^>]*>?/gm, '');

    this.pickings = await this.odooEm.search<StockPicking>(new StockPicking(), [["origin", "=", order.name]]).toPromise()
    this.moves = await this.odooEm.search<StockMove>(new StockMove(), [["picking_id", "in", this.pickings.map(p => p.id)]]).toPromise()
    await this.odooEm.resolveArrayOfSingle(new Product(), order.order_line.values, "product_id").toPromise()


    await this.odooEm.resolveArray(new AccountTax(), order.order_line.values, "taxes_id").toPromise()

    var products = []
    order.order_line.values.forEach(s => {
      if (s.product_id && s.product_id.value){
        products.push(s.product_id.value)
      }
    })

    await this.odooEm.resolveArray(new ProductSupplierinfo(), products, "variant_seller_ids").toPromise()
    await this.odooEm.resolveArray(new ProductTemplateAttributeValue(), products, "product_template_attribute_value_ids").toPromise()
    this.order = order
    this.loading = false

  }

  isLineDoubleUM(line) {
    return true
  }

  getVariantAttribute(line: PurchaseOrderLine, name: string) {

    if (!line.product_id)
      return

    if (!line.product_id.value || !line.product_id.value.product_template_attribute_value_ids)
      return ""


    if (line.product_id.value.product_template_attribute_value_ids.values) {
      var f = line.product_id.value.product_template_attribute_value_ids.values.filter(value => {
        return value.attribute_id.name == name
      })
    }


    return (f && f.length > 0) ? f[0] : null
  }




  isLineEditable(line: PurchaseOrderLine) {
    if (!line.product_id || !line.product_id.value)
      return false
    var a = line.product_id.value.product_template_attribute_value_ids.values.filter(x => x.name == "su misura")
    return a.length == 1
  }

  row2printable(r: HTMLElement): any {
    const row = [];
    r.querySelectorAll('[data-print-col]').forEach(element => {

      console.log('row 2 printable', element, element.tagName === 'A');

      // if (element.tagName === 'B' || element.tagName === 'A' || element.tagName === 'SPAN') {
      //   row.push(element.innerText);
      // } else 
      if (element.tagName === 'SELECT') {
        var s = element as HTMLSelectElement
        if (s.selectedIndex > -1) {
          row.push(s.options[s.selectedIndex].innerHTML);
        }
      } else if (['INPUT', 'TEXTAREA'].includes(element.tagName)) {
        var i = element as HTMLInputElement
        row.push(i.value);
      } else if (element.childElementCount > 0) {

        console.log("dai dai 2", element)

        let t = '';
        element.querySelectorAll(':scope > *').forEach(e => {
          t += (((e as any).value ? (e as any).value : (e as any).innerText) + ' ');
        });
        row.push(t);
      } else {

        console.log("dai dai ", element)
        row.push((element as any).innerText);
      }
    });
    return row;
  }




  async print(): Promise<void> {
    this.loading = true;

    let headerRow; // just one

    // headerRow = ["Ordine di acquisto", "Fornitore", "Riferimento fornitore", "Valuta", "Data conferma", "Consegna a","Note","Termine di resa","Termini di pagamento" ]
    this.elementRef.nativeElement.querySelectorAll('[data-print-row-header]').forEach(element => {
      headerRow = (this.row2printable(element))
    });

    const headerDatas = []; // more than one
    this.elementRef.nativeElement.querySelectorAll('[data-print-row]').forEach(element => {
      headerDatas.push(this.row2printable(element));
    });

    var sheetid = await this.gapiService.printOrder(
      PRINTS_PURCHASE_CFG.template_sheet_id,
      PRINTS_PURCHASE_CFG.spool_folder_id,
      headerRow,
      headerDatas)
    window.open('https://docs.google.com/spreadsheets/d/' + sheetid, '_blank');
    this.loading = false;

  }



}
