<!-- <div class="card card-stage card-stage-last mb-4 " >
  <div class="card-header d-flex align-items-center justify-content-between">
      &nbsp;Budget
    </div>

    <div class="card-body text-center d-flex p-0">
      <table class="table table-flush text-left">
        <tr *ngFor="let c of arrayCategories ">
          <td class="align-middle w-100 ps-4 lead ">
            <a class="text-primary"> {{c.name}}</a>
          </td>
          <td>
            {{c.total}}
          </td>
        </tr>
      </table>
    </div>
  </div> -->
<!-- 

  <div class="card card-stage card-stage-last mb-4 " style="width:100px">
    <div class="card-header d-flex align-items-center justify-content-between">
        <span >Budget</span>
    </div>
    
    <div class="card-body text-center d-flex p-0 "   > -->

      <table class="table table-flush text-left  ">
        <tr *ngFor="let c of arrayCategories ">
          <td class="align-middle w-100 ps-4 lead ">
            <a class="text-primary"> {{c.name}}</a>
          </td>
          <td>
            {{c.total}}
          </td>
        </tr>
      </table>

    <!-- </div>
</div> -->