    <button class="btn bg-transparent keyboard" data-test-id="keyboardButton" (click)="keyboard()">
      <i class="fa fa-keyboard fa-fw fa-lg px-2"></i>
    </button>

    <div class="input-wrapper">
      <div class="input-group  mx-auto mt-3" *ngIf="isKeyboard">
        <input type="text" class="form-control"  data-test-id="keyboardInput"  placeholder="" [(ngModel)]="keyboardInput">
        <div class="input-group-append">
          <button class="btn btn-primary" type="button" data-test-id="keyboardConfirm" (click)="emitEvent(keyboardInput)">
            {{mutiple}}
            <i class="fa fa-check"  size="1x" class="text-white" ></i>
          </button>
        </div>
      </div>
    </div>

    <button *ngIf="result" class="btn btn-lg btn-success result d-flex justify-content-between" (click)="emitEvent(result)">
      <span>{{result}}</span>
      <i class="fa fa-check"  class="text-white" ></i>
    </button>
 
    <div class="d-flex mw-100 m-0 p-3 fixed-top">
      <div class="counter" *ngIf="barcodesNumber > 1">{{index}} / {{barcodesNumber}} {{barcodes}} </div>
      <div class="w-100"></div>
      <button class="btn bg-transparent close" (click)="emitEvent(null)">
        <i class="fa fa-times text-white fa-lg"></i>
      </button>
    </div>

    <div v-once class="component-barcode-scanner">
      <svg class="dbrScanner-bg-camera2"  viewBox="0 0 2048 1792"><path d="M1024 672q119 0 203.5 84.5t84.5 203.5-84.5 203.5-203.5 84.5-203.5-84.5-84.5-203.5 84.5-203.5 203.5-84.5zm704-416q106 0 181 75t75 181v896q0 106-75 181t-181 75h-1408q-106 0-181-75t-75-181v-896q0-106 75-181t181-75h224l51-136q19-49 69.5-84.5t103.5-35.5h512q53 0 103.5 35.5t69.5 84.5l51 136h224zm-704 1152q185 0 316.5-131.5t131.5-316.5-131.5-316.5-316.5-131.5-316.5 131.5-131.5 316.5 131.5 316.5 316.5 131.5z"/></svg>
      <video class="dbrScanner-video" playsinline="true"></video>
      <canvas class="dbrScanner-cvs-drawarea2"></canvas>
      <div class="dbrScanner-cvs-scanarea">
      </div>
      <select class="dbrScanner-sel-resolution"></select> 
  </div>

