import { Directive, ElementRef, OnInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '[input-wrapper], td.editable'
})
export class InputwrapperDirective implements OnInit {
  input: any;
  
  constructor(private elref: ElementRef) {
    
    let el = elref.nativeElement
    
    el.onmousedown = () => {
      el.querySelector("input").style.pointerEvents = "all"
      setTimeout(() => {
        el.querySelector("input").focus()
        el.querySelector("input").select()
      })
    }
    
    el.onkeydown = (e) => {
      var i = el.querySelector("input")
      if (!i) return
        // right
        if (e.keyCode == '39' && i.selectionEnd == i.value.length) {
          var ii = i.closest("tr").querySelectorAll(".editable input")
          var idx = [].indexOf.call(ii, i);
          if (ii.length > idx)
            ii[idx+1].focus()
        }
        // left
        if (e.keyCode == '37' && i.selectionStart == 0) {
          var ii = i.closest("tr").querySelectorAll(".editable input")
          var idx = [].indexOf.call(ii, i);
          if (idx > 0)
            ii[idx-1].focus()
        }

        // up
        if (e.keyCode == '38') {
          var ii = i.closest("tr").querySelectorAll("td")
          var idx = [].indexOf.call(ii, i.closest("td"));
          var nn = i.closest("tr").previousElementSibling.querySelectorAll("td") //
          if (nn.length && idx > 0)
            nn[idx].querySelectorAll(".editable input")? nn[idx].querySelectorAll(".editable input")[0].focus() : null
        }

        // down
        if (e.keyCode == '40') {
          var ii = i.closest("tr").querySelectorAll("td")
          var idx = [].indexOf.call(ii, i.closest("td"));
          var nn = i.closest("tr").nextElementSibling.querySelectorAll("td") //
          if (nn.length && idx > 0)
            nn[idx].querySelectorAll(".editable input")? nn[idx].querySelectorAll(".editable input")[0].focus() : null
        }
        
    }

    el.onblur = () => {
      el.querySelector("input").style.pointerEvents = "none"
    }
    
  }
  
  ngOnInit(): void {
    this.input = this.elref.nativeElement.querySelector("input")
    if (this.input)
      this.elref.nativeElement.querySelector("input").style.pointerEvents = "none"
  }
  
}
