import { Component, OnInit } from '@angular/core';
import { io } from 'socket.io-client';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
})
export class StatusComponent implements OnInit {
  socket: any;
  values: any;

  constructor() { }

  ngOnInit(): void {

    this.socket = io("http://localhost:1337");
    this.socket.on("health_check", (message) => {
      this.values =  JSON.parse( message )
      console.log(this.values)
    })

  }

}
