import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestapiService } from '../../shared/services/rest-api.service';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { Contact } from '../../models/contact.model';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { SaleOrder } from '../../models/sale-order.model';
import { PurchaseOrder } from '../../models/purchase-order.model';
import { OdooEntityManager } from '../../shared/services/odoo-entity-manager.service';


class VATResponse {
  valid:boolean
  name:string
  street:string
  city:string
  province:string
  zip:string
  vat:string
}

@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html'
})
export class ContactDetailComponent implements OnInit {
  id: number;
  contact: Contact;
  sales: SaleOrder[];
  purchases: any[];
  loading = false;
  isViewingChild: boolean;

  constructor(
    private route: ActivatedRoute,
    public restapi: RestapiService,
    private router: Router,
    private odooEm: OdooEntityManager,
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(async params => {
        this.id = params.id;
        this.isViewingChild = false
        this.loading = true;
        var cs = await this.odooEm.search<Contact>(new Contact(), [['id', 'ilike', this.id ]]).toPromise()
        this.contact = cs[0]
        var x = cs
        console.log("xxcon ", cs, this.contact)
        this.loading = false;
    })
      

    this.restapi.emitter.subscribe( (apiEvent:any) => {
      if (apiEvent.table === 'res.partner') {
        if (apiEvent.target.id === this.id) {
          this.contact = apiEvent.target;
        }
      }
    });
  }

  confirm() {
    this.changeState('quotation');
  }

  restore() {
    this.changeState('quotation');
  }

  obsolete() {
    this.changeState('cancel');
  }

  changeState(state: string) {
    this.loading = true;
    return this.odooEm.update<Contact>(this.contact, {state})
      .pipe(
        catchError(() => of(null))
      )
      .subscribe(response => {
        this.loading = false;
        response ? this.contact.state = state : console.log(`Errore nel cambio stato`);
      });
  }

  onModify() {
    this.router.navigate(['contact/' + this.id + '/edit']);
  }

  async onDelete() {
    if (!confirm('Sei sicuro di voler archiviare il contatto ?')) 
      return
    this.loading = true
    this.obsolete()
    // await this.odooEm.delete(new Contact(),[this.contact.id]).pipe(first()).toPromise()
    this.loading = false
  }

  async update(prop:string) {
    this.loading = true
    var p = {}
    p[prop] = this.contact[prop]
    
    // if (this.isAddingChild) {
    //   this.contact.state = "quotation"
    //   this.contact.company_type = "company"
    //   this.contact = await this.odooEm.create<Contact>(new Contact(), this.contact).pipe(first()).toPromise()
    //   this.isAddingChild = false
    // }
    await this.odooEm.update(this.contact,p).pipe(first()).toPromise()

    console.log("HERE ",p)
    this.loading = false
  }


  // createPurchaseOrder() {
  //   this.loading = true;
  //   this.odooEm.create<PurchaseOrder>(new PurchaseOrder(), {partner_id: this.contact.id})
  //     .pipe(catchError(() => of(null)))
  //     .subscribe(response => {
  //       this.loading = false;
  //       response ? this.router.navigate(['/purchases/' + response.id]) : console.log(`Errore nella creazione dell'ordine`);
  //     });
  // }

  public localizeOrderState(state: string): string {
    if (!state) {
      return '';
    }

    switch (state) {
      case OrderState.ASSIGNED : {
        return 'pronto';
      }
      case OrderState.DRAFT : {
        return 'preventivo';
      }
      case OrderState.PLANNING : {
        return 'in progettazione';
      }
      case OrderState.PRODUCTION : {
        return 'in produzione';
      }
      case OrderState.SALE : {
        return 'evaso';
      }
    }
    return state;
  }


  parseXMLVAT(r):VATResponse {
    var v = new VATResponse()
    
    const parser = new DOMParser();
    const xml = parser.parseFromString(r, "text/xml");
    
    console.log("r",r)

    v.valid = (xml.querySelector("valid").textContent == 'true') ? true : false
    if (!v.valid)
      return v

    v.name = xml.querySelector("name").textContent;

    v.vat = xml.querySelector("vatNumber").textContent;

    // badly split address based on cap position
    var address = xml.querySelector("address").textContent;
    
    var ar = address.split('\n')
    v.street = ar[0]
    var sr = ar[1].split(" ")
    v.zip = sr[0]
    v.province = sr[sr.length -1]
    v.city = sr.slice(1,-1).join(" ")
    
      // this.contact.name = xml.querySelector("name").textContent;
    console.log("VAT ", v)
    return v
  }

  onVAT(vat) {
    this.loading = true
    var xmlhttp = new XMLHttpRequest();
            xmlhttp.open('POST', 'checkvat/taxation_customs/vies/services/checkVatService', true);

            // build SOAP request
            var sr =
            "<s11:Envelope xmlns:s11='http://schemas.xmlsoap.org/soap/envelope/'>"+
              "<s11:Body>"+
                "<tns1:checkVat xmlns:tns1='urn:ec.europa.eu:taxud:vies:services:checkVat:types'>"+
                  "<tns1:countryCode>IT</tns1:countryCode>"+
                  "<tns1:vatNumber>" + vat +"</tns1:vatNumber>"+
                "</tns1:checkVat>"+
              "</s11:Body>"+
            "</s11:Envelope>"

            xmlhttp.onreadystatechange = () => {
              this.loading = false
                if (xmlhttp.readyState == 4) {
                    if (xmlhttp.status == 200) {
                        var r = this.parseXMLVAT(xmlhttp.responseText)
                        
                        if (r.valid) {
                          // this.contact.name = r.name
                          // this.contact.city = r.city
                          // this.contact.street = r.street
                          // this.contact.zip = r.postalcode

                          this.bulkUpdate(r)
                        } else 
                          alert("VAT non valida")
                    }
                }
            }
            // Send the POST request
            xmlhttp.setRequestHeader('Content-Type', 'text/xml');
            xmlhttp.send(sr);


  }

  async bulkUpdate(fields) {
    this.loading = true
    this.contact = await this.odooEm.update<Contact>(this.contact,fields).pipe(first()).toPromise()
    this.loading = false
  }



}

enum OrderState {
  ASSIGNED = 'assigned',
  DRAFT = 'preventivo',
  PRODUCTION = 'in_produzione',
  PLANNING = 'in_progettazione',
  SALE = 'sale'
}
