import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { QuerySearchOptions, RestapiService } from '../shared/services/rest-api.service';
import { Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { StockQuantPackage } from '../models/stock-quant-package';
import { Package } from '../models/package';
import { StockMoveLine } from '../models/stock-move-line';
import { StockMove } from '../models/stock-move';
import { StockPicking } from '../models/stock-picking';
import * as moment from 'moment';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html'
})
export class SearchComponent implements OnInit {

  // @ViewChild('articleBarcodeReader', { static: false }) barCodeScanner: BarcodeReader;

  barCode: string = null;
  packages: number[] = [];
  loading: false;
  isScanning: boolean = false;
  textInput:ReplaySubject<string> = new ReplaySubject(1)
  package: Package;
  activatedRoute: any;
  moveLines: StockMoveLine[];
  mode : string;

  constructor(
    public restapi: RestapiService,
    private el:ElementRef,
    private odooEM:OdooEntityManager,
    public router: Router
  ) {
    // Intercpt browser's backs and forwards and stop the barcode scanner
    this.router.events.subscribe(e => {
      // don't want to activate camera in sub-routes
      if (e['url'] !== '/search')
        this.isScanning = false
      else
        this.isScanning = true
    });
  }

  ngOnInit() {
  }


  async onCode(c) {
    // if (ps.length == 0) {
    //   this.needToCreate = true
    // }
    // else {
    //   this.onPackage.emit(ps[0])
    // }

  }

  searchBarCode(barcode: string[]) {
  }

  
  getDate(date) {
    return moment(date).format('DD/MM/YYYY')
  }

  async onPackageOrig(p:Package) {
    this.package = p
    await this.odooEM.resolve(p.quant_ids).pipe(first()).toPromise()

    this.moveLines = await this.odooEM.search<StockMoveLine>(new StockMoveLine(),[["result_package_id", "=", p.id]]).toPromise()
    this.moveLines.forEach(async (ml:StockMoveLine) => {
        await this.odooEM.resolveSingle(new StockMove(), ml.move_id).toPromise()
        await this.odooEM.resolveSingle(new StockPicking(), ml.move_id.value.picking_id).toPromise()
    })
  
    // console.log("ppp",this.package,ret)
    // var x = this.pickOrigin.id
    // this.pickOrigin = null
    // if (!p) return
  
    // var a = this.picking.move_ids_without_package.values.map(m => m.move_line_ids.values)
    // var flat = [].concat(...a).filter(l => l._checked == true)
    // if (flat.length == 0 ) 
    //   flat.push(new StockMoveLine(x))
  
    // // todo update multi
    // for (var f of flat) {
    //   await this.odooEM.update(new StockMoveLine(f.id), {
    //     'package_id': p.id
    //   }).toPromise()
    // }
  
    // await this.load()
    // this.loading = false
  }
}