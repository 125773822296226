<nav class="navbar text-white navbar-dark fixed-top px-1">
  <button class="btn btn-link text-white" routerLink="../..">
      <i class="fas fa-chevron-left"></i>      
  </button>
  <h1 class="ml-auto mr-auto">Dettagli Pacco</h1>

  <bar-loader [loading]="loading"></bar-loader>
</nav>

<h2 class="ml-3" style="margin-top: 78px"><i class="far fa-barcode-alt"></i> {{package.barcode}}</h2>
<br>

<div class="list-group" data-test-id="orders">
  <a class="list-group-item list-group-item-action rounded-0" 
      *ngFor="let p of package._pacchi_product">
      <span class="badge text-left mr-2 bg-success text-white" > 
        <h3 class="d-inline">
        {{p.qty}} {{p.product_uom[1]}}  </h3>
      </span>
      {{p.display_name}}
      
    </a>
</div>

<br>
<br>


<h2 class="ml-3">Movimenti</h2>

<ul class="list-group">
  <ng-container *ngFor="let m of moves">
    <!-- <li *ngIf="m.code_picking == 'outgoing'" class="list-group-item"><i class="text-danger fa fa-arrow-left"></i> {{m.origin}} {{m.product_uom_qty}} {{m.date}}</li> -->
    <li *ngIf="m.code_picking == 'outgoing'" class="list-group-item d-flex"> <span><i class="text-danger fa fa-arrow-left"></i>&nbsp;<b>{{m.origin}}</b>  {{getDate(m)}}</span>  <span class="ml-auto">{{m.product_uom_qty_po}}{{m.product_uom[1]}}</span></li>
    <li *ngIf="m.code_picking == 'incoming'" class="list-group-item d-flex"> <span><i class="text-success fa fa-arrow-right"></i>&nbsp;<b>{{m.origin}}</b>  {{getDate(m)}}</span>  <span class="ml-auto">{{m.product_uom_qty_po}}{{m.product_uom[1]}}</span></li>
    
  </ng-container>
</ul>


