import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';
import { first } from 'rxjs/operators';
import { HrEmployee } from '../models/hr-employee.model';
import { HrLeave } from '../models/hr-leave.model';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import * as moment from 'moment';
import {  HrLeaveType } from '../models/hr-leave-type.model';

@Component({
  selector: 'app-time-off',
  templateUrl: './time-off.component.html',
})
export class TimeOffComponent implements OnInit {
  employee: HrEmployee;

  constructor(private odooEM: OdooEntityManager, private route: ActivatedRoute ) { }

  type: number = 1
  to: string 
  from: string
  name : string 

  async ngOnInit() {
    this.route.params.subscribe(async (params) => {
      this.employee = (await this.odooEM.search<HrEmployee>(new HrEmployee(), [["id", "=", params.employee_id ]]).toPromise())[0]
    })
    // var typesArray = (await this.odooEM.search<HrLeaveType>(new HrLeaveType(), []).toPromise())
  }

  async submit() {

    var obj =
    {
      "state": "confirm",
      "name": this.name,
      "holiday_type": "employee",
      "employee_id": this.employee.id,
      "holiday_status_id": 1,
    }

    var format = "YYYY-MM-DD HH:mm:ss";
    obj["date_from"] = moment( this.from ).utc( false ).format(format)
    obj["date_to"] = moment( this.to ).utc( false ).format(format)
    await this.odooEM.create<HrLeave>(new HrLeave(), obj).pipe(first()).toPromise()
  }
}
