<nav class="navbar navbar-expand-md bg-dark navbar-dark">
  <div class="container-fluid">
    <button class="btn btn-link text-white mr2-auto" routerLink="../..">
      <i class="fas fa-chevron-left"></i>      
    </button>

    <div class="navbar-brand" >
      <b>
        {{title}}{{order?.name}}
      </b>
    </div>
    
    <div class="d-flex">
      <a data-test-id="inventoryButton" 
        *ngIf="showInventory" class="btn btn-default navbar-btn text-white"
        (click)="emitAction('toggleInventory')" title="Nuova riga">
      <i class="fa fa-plus"></i>
      </a>
      <!-- <a [ngClass]="{'disabled': !isSomethingChecked}" data-test-id="deleteButton"
        (click)="emitAction('deleteOrderLines')" class="btn btn-default navbar-btn text-white" title="Elimina riga">
        <i class="fas fa-trash fa-fw fa-lg"></i>
      </a>
     -->

     
      


      <!-- Action dropdown -->
      <!-- <div class="d-flex ms-3">
        <div class="btn-group">
          <button type="button" class="btn text-white" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  <i class="fa fa-square-check"></i>
          </button>

          
          <div class="dropdown-menu dropdown-menu-right">
            <a class="dropdown-item" (click)="emitAction('duplicateOrder', order.ORDER_TYPE_NAME==='SaleOrder' ? 'sale_copy' : 'purchase_copy')">Duplica ordine</a>
            <a class="dropdown-item" (click)="emitAction('cancelOrder', order.ORDER_TYPE_NAME==='SaleOrder' ? 'action_cancel' : 'button_cancel')">Annulla ordine</a>
          </div>
        </div>
      </div> -->
      


      <!-- <div class="btn-group dropleft">
        <button type="button" class="btn btn-secondary dropdown-toggle" data-bs.toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Dropleft
        </button>
        <div class="dropdown-menu">
        </div>
      </div> -->



      <div class="btn-group">


        <button class="btn btn-link text-white " type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-circle-info fa-lg text-white"></i>
        </button>
    
    
        <div *ngIf="order" class="dropdown-menu" aria-labelledby="dropdownMenu2" style="min-width: 300px;left: -300px;" data-print-row-header>
          <div class="px-3 pt-2">
            <b data-print-col>Vendita {{order.name}}</b> 
            &nbsp;<span *ngIf="order.state == 'sale'"class="badge badge-muted bg-success text-white">{{order.state}}</span>
            <br>
            Fascicolo F{{order.opportunity_id?.value?.tracking_code}}
            <span *ngIf="order.state == 'draft'"class="badge badge-muted bg-warning">{{order.state}}</span>
            
          </div>
          <br>
          <form class="px-3" style="min-width: 340px;">
    
          <!-- <a class="dropdown-item" class="btn btn-default navbar-btn text-light me-3"
             (click)="emitAction('print','O')">
            <i class="fa fa-lg fa-print"></i>
          </a> -->
    
              <div class="form-group">
                  <label>Deadline</label>
                  <input 
                    type="datetime-local"
                    class="form-control"
                    name="commitment_date"
                    [(ngModel)]="order.commitment_date"
                    (ngModelChange)="updateDatetime('commitment_date')"
                  >
                  <br>
                  <!-- <label>Data consegna</label>
                  <input 
                    type="datetime-local" 
                    class="form-control" 
                    name="commitment_date"
                    [(ngModel)]="saleOrder.commitment_date"
                    (change)="updateSaleOrder('commitment_date')"
                  >
                  <br> -->
              </div>
              <!-- <app-contact *ngIf="newDeal.area" class="embedded" (onSelect)="onContact($event)"></app-contact> -->
          </form>
    
          
           <hr>
          <button class="btn btn-primary text-white m-3" (click)="emitAction('print')">Stampa</button>
    
          <!--
          <div class="row">
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
          </div> -->
        </div>
        <a *ngIf="canConfirm" class="dropdown-item" class="btn btn-success navbar-btn text-light me-3" (click)="confirm()">
          Conferma
        </a>


      </div>


  <div class="dropright">
   
</div>



      <div class="dropdown" >
    
          <button class="btn btn-link text-white" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-circle-info fa-lg text-white"></i>
          </button>
          <div *ngIf="order" class="dropdown-menu dropdown-menu-right" style="min-width: 300px;" data-print-row-header>
            <div class="px-3 pt-2">
              <b data-print-col>Vendita {{order.name}}</b> 
              &nbsp;<span *ngIf="order.state == 'sale'"class="badge badge-muted bg-success text-white">{{order.state}}</span>
              <br>
              Fascicolo F{{order.opportunity_id?.value?.tracking_code}}
              <span *ngIf="order.state == 'draft'"class="badge badge-muted bg-warning">{{order.state}}</span>
              
            </div>
            <br>
            <form class="px-3" style="min-width: 340px;">

            
            <!-- <a class="dropdown-item" class="btn btn-default navbar-btn text-light me-3"
              (click)="emitAction('print','O')">
              <i class="fa fa-lg fa-print"></i>
            </a> -->


                <div class="form-group">
                    <label>Deadline</label>
                    <input 
                      type="datetime-local"
                      class="form-control"
                      name="commitment_date"
                      [(ngModel)]="order.commitment_date"
                      (ngModelChange)="updateDatetime('commitment_date')"
                    >
                    <br>
                    <!-- <label>Data consegna</label>
                    <input 
                      type="datetime-local" 
                      class="form-control" 
                      name="commitment_date"
                      [(ngModel)]="saleOrder.commitment_date"
                      (change)="updateSaleOrder('commitment_date')"
                    >
                    <br> -->
                </div>
                <!-- <app-contact *ngIf="newDeal.area" class="embedded" (onSelect)="onContact($event)"></app-contact> -->
            </form>

            
            <hr>
            <button class="btn btn-primary text-white m-3" (click)="emitAction('print')">Stampa</button>

            <!--
            <div class="row">
              <div class="col"></div>
              <div class="col"></div>
              <div class="col"></div>
            </div> -->
          </div>
          <a *ngIf="canConfirm" class="dropdown-item" class="btn btn-success navbar-btn text-light me-3" (click)="confirm()">
            Conferma
          </a>
      </div>
    </div>
  </div>
</nav>

<bar-loader [loading]="loading"></bar-loader>
