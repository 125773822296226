

<nav class="navbar text-white navbar-dark align-items-center" >
    <button class="btn btn-link text-white me-auto" routerLink="../.." >
        <i class="fas fa-chevron-left"></i>      
    </button>
    <h1 class="mx-auto">STATUS PLC</h1>
    <div class="ms-auto"></div>
  </nav>

  <div class="d-flex w-100" >

      <div class="card w-100">
        <div class="card-header">
        </div>
        <div class="card-body" >

            <table class="table">
                <tbody>
                    <tr *ngFor="let item of values">
                        <td>{{item.ip}}</td>
                        <td>


                            <i *ngIf="item.ret" class="fa-solid fa-circle text-success"></i>
                            <i *ngIf="!item.ret" class="fa-solid fa-circle text-danger"></i>


                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
      </div>
  </div>

