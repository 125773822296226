
<app-scanner [hidden]="employee" [canCreate]="true" (onCode)="onCode($event)"></app-scanner>



<div class="d-flex align-items-center justify-content-center" style="height: 500px">

    <div class="card" *ngIf="employee">
      <div class="card-header">
        <H3>BENVENUTO {{employee.name}}</H3>
        <p id="timeout"> Tra {{secTimeout}} secondi avverà il {{nextAction}}</p>
      </div>

      <div class="card-body">
       

        <!-- <p [ngClass]="{'d-none': nextAction != actions.Enter}">Vuoi entrare ? </p>
        <p [ngClass]="{'d-none': nextAction != actions.Exit}">Vuoi uscire ? </p> -->
        

        <div class="btn-group btn-group-toggle" data-toggle="buttons">
          <!--  -->

          <label class="btn btn-danger" *ngIf="timeout" (click)="stopNextAction()" >
            ANNULLA
          </label>
          <label class="btn btn-secondary" *ngIf="!timeout"  (click)="enter()"  [ngClass]="{'d-none': nextAction != actions.Enter}">
            ENTRA
          </label>
          <label class="btn btn-secondary" *ngIf="!timeout"  (click)="exit()" [ngClass]="{'d-none': nextAction != actions.Exit}">
            ESCI
          </label>
          <label class="btn btn-primary"  [routerLink]="['/time-off/',employee.id ]">
            FERIE
          </label>
        </div>

      </div>
    </div>

</div>