<app-navbar *ngIf="picking" [loading]="loading">
  <a class="navbar-brand">
    <!-- {{picking.name}} -->

    
      <span >
          {{picking.picking_type_id.name.split(":")[1]}} -
          {{picking.origin}} -
          {{picking.partner_id?.name}}
      </span>&nbsp;
      <i class="fa-duotone fa-circle-info" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample"></i>


  </a>
  
  <ng-container *ngIf="picking.state != 'done'">
    <app-keyboard-scanner 
      [active]="true" (onCode)="onScan($event)"></app-keyboard-scanner>
    <button 
      class="ms-3 btn btn btn-outline-primary text-white" 
      (click)="tryToValidate()">
        Chiudi
    </button>
  </ng-container>

  <button 
    *ngIf="picking.state == 'done'"
    class="btn btn-dark text-white ms-auto">Chiuso</button>

</app-navbar>

<div 
  *ngIf="picking"
  style="height:40vh"
  class="offcanvas offcanvas-top bg-white " tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <div class="offcanvas-header">

    <a class="navbar-brand">
      {{picking.picking_type_id.name.split(":")[1]}} -
      {{picking.origin}} -
      {{picking.partner_id?.name}}
    </a>
    <!-- <h5 class="offcanvas-title" id="offcanvasExampleLabel">Trasferimento {{picking.picking_type_id.name}} {{picking.name}}</h5> -->
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body" >
    <table class="table text-white">
      <tr>
        <td>Fornitore</td>
        <td><input class="form-control"></td>
      </tr>
      <tr>
        <td>Riferimento</td>
        <td><input class="form-control"></td>
      </tr>
      <tr>
        <td>Responsabile</td>
        <td><input class="form-control"></td>
      </tr>
      <tr>
        <td>Data prevista</td>
        <td><input class="form-control"></td>
      </tr>
      <tr>
        <td>Cliente</td>
        <td><input class="form-control"></td>
      </tr>
    </table>
  </div>
</div>

<div class="h-100" *ngIf="picking">
  <table class="table align-middle table-bordered">
    <thead>
      <tr>
        <th></th>
        <th>Ok</th>
        <th *ngIf="picking.state != 'done'">Attesi</th>
        <th>Prodotto</th>
        <th>Ubicazioni</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let m of picking?.move_ids_without_package.values"
      >
        <ng-container
          *ngFor="let l of m.move_line_ids.values"
        >
          <td class="text-center">
            <div class="form-check form-switch">
              <input 
                [disabled]="picking.state == 'done'"
                class="form-check-input" type="checkbox" role="switch" [checked]="l.qty_done >= l.product_uom_qty" (change)="onCheck($event,l)">
            </div>
          </td>
          <td class="editable" [ngClass]="{'changed':l._justChanged}">
            <input 
              [disabled]="picking.state == 'done'"
              type="text" 
              width="40" 
              class="form-control d-inline-block" 
              [(ngModel)]="l.qty_done" 
              (change)="updateLine('qty_done',l)" 
              style="width: 60px">
          </td>
          <td *ngIf="picking.state != 'done'">
            <span class="text-nowrap text-muted">{{l.product_uom_qty}}</span>
          </td>
          <td class="text-muted">
            {{m.product_id.name}} 
          </td>
          <td class="text-muted fs-6">
            <span class="w-100" style="white-space: nowrap; text-overflow: ellipsis;">{{l.location_id.name.slice(4)}}</span>
            &nbsp;<i class="fa fa-arrow-right text-muted"></i>&nbsp;<span class="w-100" style="white-space: nowrap; text-overflow: ellipsis;">{{l.location_dest_id.name.slice(4)}}</span>
          </td>
        </ng-container>
      </tr>
    </tbody>  
  </table>
</div>

<ng-template #modalorig let-modal>
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-0">
          <table class="table">
            <tr>
              <th>Qt disponibile</th>
              <th>Ubicazione</th>
              <th>Pacco</th>
            </tr>
            <tr *ngFor="let q of quants" (click)="onQuantOrig(q)">
              <td>{{q.available_quantity}} / {{q.quantity}}</td>
              <td>{{q.location_id?.name}}</td>
              <td>{{q.package_id?.name}}</td>
            </tr>
          </table>
      </div>
</ng-template>


<ng-template #modaldest let-modal>
  <div class="modal-header">
      <!-- <h4 class="modal-title" id="modal-basic-title">Profile update</h4> -->
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <table class="table">
          <tr>
            <th>Prodotto</th>
            <th>Qt</th>
            <th>Ubicazione</th>
          </tr>
          <tr *ngFor="let p of packages" (click)="onPackageDest(p, activeMoveLine)">
            <td>{{p.name}}</td>
            <td>{{p.name}}</td>
            <td>{{p.location_id.name}}</td>
            <td></td>
          </tr>
        </table>
    </div>
 
</ng-template>


<ng-template #notifyOwnerModal let-modal>
  <div class="modal-header">
      <!-- <h4 class="modal-title" id="modal-basic-title">Profile update</h4> -->
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <table class="table">
          <tr>
            <th>Prodotto</th>
            <th>Qt</th>
            <th>Ubicazione</th>
          </tr>
          
        </table>
    </div>
 
</ng-template>
