<nav class="navbar navbar-expand bg-dark navbar-dark">

    <div class="container-fluid">
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <button class="btn btn-link" routerLink="../..">
          <i class="fas fa-chevron-left"></i>      
        </button>

        <ng-content></ng-content>
      </div>
    </div>
</nav>
<bar-loader [loading]="loading" class="bg-dark"></bar-loader>