// Custom parser and formatter for NgbDate to support dd-mm-yyyy format
import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {

  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('-');
      if (dateParts.length === 1 && isNumber(Number(dateParts[0]))) {
        return {year: null, month: null, day: Number(dateParts[0])};
      } else if (dateParts.length === 2 && isNumber(Number(dateParts[0])) && isNumber(Number(dateParts[1]))) {
        return {year: null, month: (Number(dateParts[1])), day: parseInt(dateParts[0]),};
      } else if (dateParts.length === 3 && isNumber(Number(dateParts[0])) && isNumber(Number(dateParts[1])) && isNumber(Number(dateParts[2]))) {
        // Limit valid years from 1 to 9999
        if(Number(dateParts[2]) > 0 && Number(dateParts[2]) <= 9999 )
          return {day: Number(dateParts[0]), month: Number(dateParts[1]), year: Number(dateParts[2])};
      }
    }
    return null;
  }
 
  format(date: NgbDateStruct): string {
    return date ?
        `${isNumber(date.day) && date.day < 10 ? '0' + Number(date.day) : ''}`+
        `${isNumber(date.day) && date.day > 9  ? Number(date.day) : ''}`+'-'+
        `${isNumber(date.month) && date.month < 10 ? '0' + Number(date.month) : ''}`+
        `${isNumber(date.month) && date.month > 9 ? Number(date.month):''}`+ '-'+
        `${date.year}` :
        '';
  }

}

function isNumber(value: any) {
  return typeof value === 'number'
}

