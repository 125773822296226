import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'bar-loader',
  templateUrl: './loader.component.html'
})
export class LoaderComponent implements OnChanges {

  @Input() loading: boolean;

  constructor() {
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.loading) {
      this.loading = changes.loading.currentValue;
      // this.loading = true
    }
  }

}
