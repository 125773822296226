import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContactSearchService {

  private _searchString = '';

  constructor() {
  }

  get searchString(): string {
    return this._searchString;
  }

  set searchString(value: string) {
    this._searchString = value;
  }
}
