import { Component, ElementRef, HostListener, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestapiService, QueryPostOptions, QuerySearchOptions } from 'src/app/shared/services/rest-api.service';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from './date-formatter/date-formatter';
import { GapiService } from 'src/app/shared/services/g-api.service';
import { AREAS_CFG, Deal, Stage } from '../../models/deal'
import { TrelloCardEntry2 } from 'src/app/models/trello-card';
import { SaleOrder } from 'src/app/models/sale-order.model';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
import { Lead } from 'src/app/models/crm.lead.model';
import { first } from 'rxjs/operators';
import { PurchaseOrder } from 'src/app/models/purchase-order.model';
import { SaleOrderLine } from 'src/app/models/sale-order-line.model';
import { Contact } from 'src/app/models/contact.model';
import { orderBy, sortBy } from 'lodash';
import { CrmStage } from 'src/app/models/crm.lead.stage.model';
import { textChangeRangeIsUnchanged } from 'typescript';
import { MrpProduction } from 'src/app/models/mrp-production';
import { DriveFolder } from 'src/app/models/drive.folder';
import { CalendarEvent } from 'src/app/models/calendar-event.model';
import { StockMove } from 'src/app/models/stock-move';
import * as bootstrap from 'bootstrap';


const DEAL_TABLE: string = "crm.lead"

declare var Trello: any;


@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {
  transform(value: any[], order = '', column: string = ''): any[] {
    return orderBy(value, ['create_date'], ['asc']);
  }
}


@Component({
  selector: 'app-deal-detail',
  templateUrl: './deal-detail.component.html',
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class DealDetailComponent implements OnInit {
  purchaseOrdersIds: any[];
  contact: Contact;
  purchaseOrders: PurchaseOrder[];
  newSaleName: string = ""
  newProductionName: string = ""
  newPurchaseName: string;


  calendarEventModal: false
  newCalendarEventName: string;
  newCalendarEventDate: string;
  newCalendarEventNote: string;
  updateCalendarList: boolean = false

  contactForPurchase: Contact
  productions: MrpProduction[];

  existingsale: SaleOrder;
  arrayC: CalendarEvent[]
  arrayCategories: any[];
  sales: SaleOrder[];

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    return !this.loading
  }

  purchases: PurchaseOrder[];
  id: any
  stages: CrmStage[];
  commentBody: string = ''
  loading: boolean = false
  selectStageId: any
  selectFields: Object[]
  expectedDeliveryDate: NgbDate
  offers: any = []
  cfg
  trelloCards: TrelloCardEntry2[] = []
  lead: Lead;

  emails: string[] = []



  @ViewChild('map') mapElement: any;

  busyMessage: unknown;

  constructor(
    private odooEm: OdooEntityManager,
    private router: Router,
    private elRef: ElementRef,
    private route: ActivatedRoute,
  ) {
  }

  async ngOnInit() {
    console.log("---- serve", bootstrap.Modal)

    
    this.stages = await this.odooEm.search<CrmStage>(new CrmStage()).toPromise()

    this.route.params.subscribe(async params => {
      this.id = params['id'];
      this.loading = true;
      await this.loadDeal()

      console.log("GET STAGE ", this.getStage())
      this.loading = false
    })
  }

  async loadDeal() {
    const filters = [['id', '=', this.id]]
    var lead = (await this.odooEm.search<Lead>(new Lead(), filters).toPromise())[0]


    await this.odooEm.resolve(lead.trello_ids).pipe(first()).toPromise()
    await this.odooEm.resolve(lead.drive_ids).pipe(first()).toPromise()

    this.existingsale = (await this.odooEm.search<SaleOrder>(new SaleOrder(), [['opportunity_id', '=', lead.id]]).toPromise())[0]
    if (this.existingsale) {
      this.productions = await this.odooEm.search<MrpProduction>(new MrpProduction(), [['origin', 'ilike', this.existingsale.name]],2000,null,"name DESC").toPromise()
      this.productions = this.productions.sort()
    }


    this.sales = await this.odooEm.search<SaleOrder>(new SaleOrder(), [['opportunity_id', '=', lead.id]]).toPromise()


    this.lead = lead
    this.cfg = AREAS_CFG.filter(a => a.name == this.lead.area)[0]
  }


  filterDriveFolders(settore: string) {
    if (this.lead.drive_ids && this.lead.drive_ids.values)
      return this.lead.drive_ids.values.filter(x => x.name == settore)
    return []
  }


  filterTrelloCards(settore: string) {
    if (this.lead.trello_ids && this.lead.trello_ids.values)
      return this.lead.trello_ids.values.filter(x => x.name == settore)
    return []
  }

  filterProductionDriveFolders(p: MrpProduction) {
    // todo fix name/display_name/P+id confusing usage
    if (this.lead.drive_ids && this.lead.drive_ids.values) {
      
      
      var r = this.lead.drive_ids.values.filter((x: DriveFolder) => {
        
        return x.name == "Produzione" && x.origin == p.name
      })
      
      console.log("PRODD ", r)

      return r
    }
    return []
  }

  filterProductionTrelloFolders(p: MrpProduction) {
    if (this.lead.trello_ids && this.lead.trello_ids.values)
      return this.lead.trello_ids.values.filter(x => x.name == "Produzione" && x.origin == p.name)
    return []
  }


  beforeunloadHandler(event) {
    return false;
    //I have used return false but you can your other functions or any query or condition
  }

  async delete() {
    if (confirm("Sei sicuro di voler eliminare il lead ?")) {
      this.loading = true
      await this.odooEm.delete(new Lead(), [this.lead.id]).pipe(first()).toPromise()
      this.loading = false
      this.router.navigate(["deals"])
    }
  }

  async onAddressChange($event) {
    for (var x = 0; x < $event.address_components.length; x++) {
      var e = $event.address_components[x]
      if (e.types[0] == "locality") {
        this.lead.city = e.long_name.replaceAll("'", " ")
        break
      }
      if (e.types[0] == "administrative_area_level_3") {
        this.lead.city = e.long_name.replaceAll("'", " ")
        break
      }
    }
    await this.updateDealToOdoo('city')

    this.lead.cordinates = $event.geometry.location.lat() + "," + $event.geometry.location.lng()
    await this.updateDealToOdoo('cordinates')

    this.lead.street = $event.formatted_address.replaceAll("'", " ");
    console.log("STREE ", this.lead.street)
    await this.updateDealToOdoo('street')
  }

  getNextStage(): CrmStage {
    var ind
    this.stages.forEach((x, i) => {
      if (Number(x.id) == this.lead.stage_id.id)
        ind = i
    })

    if (this.stages.length > ind)
      return this.stages[ind + 1]
    return null
  }

  getStage() {
    var xx = this.stages.filter(x => this.lead.stage_id.id == Number(x.id))
    return xx[0]
  }

  async toStatus(s) {
    try {
      // get the id from the sequence position
      this.lead.stage_id.id = Number(this.stages[s].id)
      this.updateDealToOdoo('stage_id')
    } catch (e) {
      alert("Errore " + JSON.stringify(e))
    }
  }

  async moveToStatus(s: CrmStage) {
    try {
      // get the id from the sequence position
      this.lead.stage_id.id = Number(s.id)
      this.updateDealToOdoo('stage_id')
    } catch (e) {
      alert("Errore " + JSON.stringify(e))
    }
  }

  async attachCalendarEvent() {
    if (!this.newCalendarEventName)
      return
    var calendarEventTmpl = {
      name: this.newCalendarEventName,
      partner_id: [this.lead.partner_id.id, this.lead.partner_id.name],
      start: this.newCalendarEventDate,
      stop: this.newCalendarEventDate,
      description: this.newCalendarEventNote,
      res_id: this.lead.id,
      opportunity_id: this.lead.id
    }
    var c = await this.odooEm.create<CalendarEvent>(new CalendarEvent(), calendarEventTmpl).toPromise()
    this.elRef.nativeElement.querySelectorAll('#dm3')[0].classList.remove("show")
    this.newCalendarEventName = this.newCalendarEventDate = this.newCalendarEventNote = ""
    this.updateCalendarList = true

  }


  async attachNewSale() {
    this.loading = true
    var sale = {
      // name: "F" + this.lead.name ,
      opportunity_id: this.lead.id,
      partner_id: this.lead.partner_id.id,
      pricelist_id: 1
    }

    var s = await this.odooEm.create<SaleOrder>(new SaleOrder(), sale).toPromise()

    await this.loadDeal()
    this.loading = false
  }

  async attachProduzione() {
    // if (!this.newProductionName)
    //   return

    if (!this.cfg.production_product_id) {
      alert("Nessun prodotto da produrre")
      return
    }

    // hack to force close
    document.querySelectorAll(".dropdown-menu.show").forEach(s => {
      s.classList.remove("show")
    })

    this.loading = true
    // if (this.lead.stage_id)

    var existingsale: SaleOrder = (await this.odooEm.search<SaleOrder>(new SaleOrder(), [['opportunity_id', '=', this.lead.id]]).toPromise())[0]
    if (!existingsale) {
      var saletmpl = {
        // name: "F" + this.lead.name ,
        opportunity_id: this.lead.id,
        partner_id: this.lead.partner_id.id,
        pricelist_id: 1
      }
      existingsale = await this.odooEm.create<SaleOrder>(new SaleOrder(), saletmpl).toPromise()
      existingsale = (await this.odooEm.search<SaleOrder>(new SaleOrder(), [['opportunity_id', '=', this.lead.id]]).toPromise())[0]
    }

   



    var stockmove ={
      "name": "P" + this.lead.tracking_code,
      "product_id": this.cfg.production_product_id,
      "product_uom_qty": 1,
      "product_uom": 1,
      "location_id": 15,
      "location_dest_id": 8,
      "origin": "P" + this.lead.tracking_code,
      // "group_id": 268,
      // "propagate_cancel": false,
      // "warehouse_id": 1,
      // "operation_id": false,
      // "byproduct_id": false
      "production_id" : null
    }

    var productiontmpl = {
      // name: this.newProductionName,
      product_id: this.cfg.production_product_id,
      product_qty: 1,
      product_uom_id: 1,
      // company_id: 30268,
      bom_id: false,
      picking_type_id: 21,
      location_src_id: 8,
      location_dest_id: 8,
      //  move_finished_ids: [productiontmpl2],
      origin: existingsale.name,
      title: this.newProductionName
    }

    
    var p = await this.odooEm.create<MrpProduction>(new MrpProduction(), productiontmpl).pipe(first()).toPromise()

    stockmove.production_id = p.id
    var s = await this.odooEm.create<StockMove>(new StockMove(), stockmove).pipe(first()).toPromise()


    console.log("XXXxxx", p)
    // await (await this.odooEm.onChange2(p,p.id,{id: p.id,product_id: this.cfg.production_product_id})).pipe(first()).toPromise()
    // this.odooEm.update(p, {product_id: this.cfg.production_product_id}).toPromise()
    // .pipe(first()).toPromise()
    alert("load")
    await this.loadDeal()
    this.loading = false
  }

  close() {
    this.router.navigate(["leads"])
  }

  updateDealToOdoo(field) {

    return new Promise<void>(async (res, rej) => {
      this.loading = true;

      var value = this.lead[field];

      // Set value ad number for field stage_id and stato_lavoro otherwhise the field value would be a string
      if (field == 'stage_id')
        value = parseInt(this.lead[field].id)

      if (Array.isArray(value) && field != 'settori')
        value = value[0]

      var fields = {}
      fields[field] = value

      await this.odooEm.update(this.lead, fields).toPromise()
      this.loading = false;
      res()
    })
  }

  async attachPurchase() {
    this.loading = true
    if (this.lead.stage_id)

      var o = await this.odooEm.create<PurchaseOrder>(new PurchaseOrder(), { name: this.newPurchaseName ? this.newPurchaseName : "", opportunity_id: this.lead.id, partner_id: this.contactForPurchase.id }).pipe(first()).toPromise()
    // move to production stage
    // await this.odooEm.update(this.lead, {stage_id: 12})
    this.loadDeal()
    this.loading = false
  }


  async loadPurchases() {
    return
    // hotfix
    // var f = this.lead.order_ids.ids.filter(x => x != null)
    // var criteria = [
    //   [ 'order_id', 'in', f]
    // ]
    // var plines:SaleOrderLine[] = await this.odooEm.search<SaleOrderLine>(new SaleOrderLine(), criteria).pipe(first()).toPromise()
    // this.purchaseOrdersIds = []
    // plines.forEach(l => {
    //   if (l.purchase_id && !this.purchaseOrdersIds.includes(l.purchase_id.id))
    //     this.purchaseOrdersIds.push(l.purchase_id.id)
    // })

    // try {
    //   var pids = this.purchaseOrdersIds.filter(x => x != null)

    //   var criteria2 = [
    //     ['opportunity_id',"=", this.lead.id.toString(),"integer"],
    //     ['state',"!=", "cancel"]
    //   ]
    //   this.purchaseOrders = await this.odooEm.search<PurchaseOrder>(new PurchaseOrder(), criteria2).toPromise()
    // } catch(e) {
    //   alert("Errore -" + e)
    // }

  }



  // Ricerca preventivi associati al deal
  // async searchAssociatedOffers() {
  //   this.offers = await this.restapi.search2("sale.order", [{column: "opportunity_id", operator : "=", value : this.deal.id}])
  //   console.log("OFFERS ", this.offers)
  // }
  redirectOffer(id) {
    window.open('/offers/' + id, '_blank')
  }

  async onContact(c: Contact) {
    this.loading = true
    await this.odooEm.update(this.lead, { 'partner_id': c.id }).toPromise()
    this.lead.partner_id.id = c.id
    this.lead.partner_id.name = c.name
    this.loading = false
  }

  pickContact(field) {
    var w = window.open('/contact?mode=embedded', '_blank', 'height=700,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');

    var h = event => {
      var d = JSON.parse(event.data)
      this.lead[field] = [d.id, d.name]
      this.updateDealToOdoo(field)
      w.close()
    }
    window.addEventListener("message", h, false);

    w.onbeforeunload = () => {
      window.removeEventListener("message", h)
    }
  }

}
