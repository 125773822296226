import { Component, OnInit } from '@angular/core';
import { PurchaseOrder } from '../../models/purchase-order.model';
import { map, switchMap } from 'rxjs/operators';
import { PurchaseOrderLine } from '../../models/purchase-order-line.model';
import { OrderDetailComponent } from '../order-detail/order-detail.component';
import { forkJoin, Observable, of } from 'rxjs';

@Component({
  selector: 'app-purchase-order-detail',
  templateUrl: './purchase-order-detail.component.html',
})

export class PurchaseOrderDetailComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

  /**
   * Ricordo che essendo passato l'ordine per riferimento le proprietà vengono modificate direttamente,
   * il valore ritornato dalla forkjoin serve puramente per capire quando le chiamate parallele sono terminate
   * • Risolve gli ordini
   *    - Risolve le orderLines
   *    - Risolve gli ancillaryOrders
   *  TODO TODORC
   */
  getOrder = (orderDetailComponent: OrderDetailComponent) => {
    
    // return orderDetailComponent.odooEm.get<PurchaseOrder>(new PurchaseOrder(), orderDetailComponent.id).pipe(
    //   switchMap(async odooOrder => {
    //     orderDetailComponent.order = odooOrder as PurchaseOrder;
    //     console.log(orderDetailComponent);
    //     const $orderLine = orderDetailComponent.odooEm.resolve<PurchaseOrderLine>(orderDetailComponent.order.order_line);
    //     $orderLine.toPromise().then(x => {
                    
    //     })

    //     const $ancillaryOrders = this.getAncillaryOrders(orderDetailComponent);
    //     return forkJoin([$orderLine, $ancillaryOrders]);
    //   })
    // );
  };

  // getAncillaryOrders(orderDetailComponent: OrderDetailComponent): Observable<any> {
  //   const order = orderDetailComponent.order as PurchaseOrder;
  //   // return orderDetailComponent.odooEm.resolve<PurchaseOrder>(order.ancillary_purchase_order_ids);
  // }

  getAncillaryOrderLines = (orderDetailComponent: OrderDetailComponent, ancillaryOrder: PurchaseOrder): Observable<any> => {
    return orderDetailComponent.odooEm.resolve<PurchaseOrderLine>(ancillaryOrder.order_line);
  };

  changeOrderState = (orderDetailComponent: OrderDetailComponent, method: string) => {
    orderDetailComponent.loading = true;
    orderDetailComponent.odooEm.call<PurchaseOrder>(new PurchaseOrder(), method,  orderDetailComponent.order.id).subscribe(() => {
      orderDetailComponent.loading = false;
      if (method === 'button_confirm') {
        orderDetailComponent.order.state = 'purchase';
      }
    });
  };

}
