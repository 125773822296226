import { OdooModel } from './odoo-model.model';
import { OdooSerializableInterface } from '../shared/interfaces/odoo-serializable-interface';
import { OdooMultiRelationship } from './odoo-multi-relationship.model';
import { OdooRelationship } from './odoo-relationship.model';

export class ContactLink extends OdooModel implements OdooSerializableInterface<Contact> {

  public ODOO_MODEL = 'res.partner.link';

  type: string = ""
  note: string = ""
  partner_id:OdooRelationship = new OdooRelationship() 
  value: string = ""
  
  constructor(id?:number, type?:string, value?:string, note?:string) {
    super(id);
    this.type = type 
    this.value = value
    this.note = (note != 'false') ? note : ""
  }

  create() {
    return new ContactLink()
  }
}

export class Contact extends OdooModel implements OdooSerializableInterface<Contact> {
  public readonly ODOO_MODEL = 'res.partner';

  id: number;
  name: string;
  // partner_id: OdooRelationship = new OdooRelationship();
  state: string;
  parent_id: OdooRelationship;
  comment: string;
  company_type: string;
  vat: string;
  // fiscal_code?: string;
  // unique_code?: string;
  // iban?:string;
  // credit_limit?:string;
  street: string;
  city: string;
  zip: string;
  // clientid: number;
  child_ids: OdooMultiRelationship<Contact>;
  // contatti_ids: OdooMultiRelationship<Contact>;
  
  link_ids: OdooMultiRelationship<ContactLink>;

  constructor(id?: number, name?: string,  state?: string, comment?: string, company_type?: string, vat?: string,  street?: string, city?: string, zip?:string, clientid?: number,
              child_ids: OdooMultiRelationship<Contact> = new OdooMultiRelationship<Contact>(Contact)) {
    super(id);
    
    this.id = id;
    this.name = name;
    // Object.defineProperty(this, "name", {
    //   // only returns odd die sides
    //   get: function () { return (Math.random() * 6) | 1; }
    // });
    console.log('vat ', vat)
    // this.partner_id = partner_id;
    this.state = state;
    this.comment = (comment != 'false') ? comment : ""
    this.company_type = company_type;
    this.vat =  vat ? vat : "";
    // this.fiscal_code = "xxx";
    // this.unique_code = (unique_code != 'false') ? unique_code : "";
    // this.phone = phone;
    // this.mobile = mobile;
    // this.email = email;
    // this.website = website;
    // this.dipendente_gali = dipendente_gali;
    this.street = street ? street : '';
    this.city = city ? city : '';
    this.zip = zip ? zip : '';
    // this.clientid = clientid;
    this.child_ids = child_ids;
    // this.contatti_ids = contatti_ids;
    // this.partner_id = new OdooRelationship();
    this.parent_id = new OdooRelationship();
    this.link_ids = new OdooMultiRelationship<ContactLink>(ContactLink);
  }

  create(): Contact {
    return new Contact();
  }

  deserialize<T>(input: any): T {
    return super.deserialize(input);
  }
}
