import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { OdooEntityManager } from '../../shared/services/odoo-entity-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PurchaseOrder } from '../../models/purchase-order.model';
import { SaleOrder } from '../../models/sale-order.model';
import { AccountPaymentTerm } from '../../models/account-payment-term.model';
import { AccountIncoTerm } from '../../models/account-inco-term.model';
import { catchError, switchMap } from 'rxjs/operators';
import { SaleOrderLine } from '../../models/sale-order-line.model';
import { from, Observable, of } from 'rxjs';
import { PurchaseOrderLine } from '../../models/purchase-order-line.model';
import { Contact } from '../../models/contact.model';
import { User } from '../../models/user.model';
import { HeaderEmitterFormat } from '../order-header/order-header.component';
import { PRINTS_CFG } from '../../models/deal';
import { GapiService } from '../../shared/services/g-api.service';
import { Product } from '../../models/product.model';
import { OdooRelationship } from '../../models/odoo-relationship.model';
import { GenericOrderLine } from '../../models/generic-order-line.model';
import { TableEmitterFormat } from '../sale-order-table/sale-order-table.component';
import { OrderLine } from '../../models/order';
import { GenericOrder } from '../../models/generic-order';
import { ToastService } from '../../shared/services/toast.service';
import { OdooMultiRelationship } from '../../models/odoo-multi-relationship.model';
import { HttpClient } from '@angular/common/http';
import { TrelloCard, TrelloCardEntry2 } from '../../models/trello-card';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
})
export class OrderDetailComponent implements OnInit, OnChanges {
  @Input() getOrder: (component: OrderDetailComponent) => Observable<GenericOrder>;
  @Input() getAncillaryOrderLines: (component: OrderDetailComponent, ancillaryOrder: PurchaseOrder) => Observable<GenericOrder>;
  @Input() changeOrderState: (component: OrderDetailComponent, method: string) => void;

  id: number;
  loading = false;
  paymentTerms: AccountPaymentTerm[];
  incoTerms: AccountIncoTerm[];
  referentContacts: Contact[];
  order: SaleOrder | PurchaseOrder;
  selectedAncillaryOrder: PurchaseOrder;
  users: User[];
  isSomethingChecked = false;
  inventoryClosed = true;
  ancillartInventoryClosed = true;
  infoSideClosed = true;
  supplier: OdooRelationship;
  orderLinesChecked: SaleOrderLine[] | PurchaseOrderLine[];
  pickedPartner: OdooRelationship;

  constructor(
    public odooEm: OdooEntityManager,
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private gapiService: GapiService,
    public toastService: ToastService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.odooEm.search<AccountPaymentTerm>(new AccountPaymentTerm())
      .subscribe((accountPaymentTerms) => {
        this.paymentTerms = accountPaymentTerms;
      });
    this.odooEm.search<AccountIncoTerm>(new AccountIncoTerm())
      .pipe(catchError(() => of([])))
      .subscribe((accountIncoTerms) => {
        this.incoTerms = accountIncoTerms;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.getOrder || !changes.getOrder.currentValue) {
      return;
    }
    this.route.params.pipe(
      switchMap(params => {
        this.id = Number(params.pid);
        if (this.id !== 0) {
          this.loading = true;
          return this.getOrder(this);
        }
      }),
      catchError(() => of(null))
    ).subscribe((el) => {
      // this.getReferentContacts();
      this.getUsers();
      this.loading = false;
    });
  }

  getReferentContacts() {
    this.odooEm.search<Contact>(new Contact(), [['parent_id', '=', this.order.partner_id.id, 'integer']]).pipe(
      catchError(() => of([]))
    ).subscribe(contacts => {
      this.referentContacts = contacts;
    });
  }

  getUsers() {
    this.odooEm.search<User>(new User()).pipe(
      catchError(() => of([]))
    ).subscribe(users => {
      this.users = users;
    });

    this.odooEm.call(this.order, 'create_purchase_from_inventary', 'product_ids=508,2059').subscribe(res => {
    });
  }

  checkIfSomethingChecked(): void {
    this.isSomethingChecked = false;
    this.orderLinesChecked = [];
    this.order.order_line.values.forEach(orderLine => {
      if (orderLine.checked) {
        this.isSomethingChecked = true;
        this.orderLinesChecked.push(orderLine);
      }
    });
    if (!this.selectedAncillaryOrder) {
      return;
    }
    this.selectedAncillaryOrder.order_line.values.forEach(orderLine => {
      // if (orderLine.checked) {
      //   this.isSomethingChecked = true;
      //   // @ts-ignore
      //   this.orderLinesChecked.push(orderLine);
      // }
    });
  }

  deleteOrderLines(): void {
    if (!confirm('Confermi di voler eliminare i prodotti selezionati ?')) {
      return;
    }
    this.loading = true;
    const ids = [];
    const order = this.order;
    order.order_line.values.forEach((orderLine) => {
      if (orderLine.checked) {
        ids.push(orderLine.id);
      }
    });
    this.odooEm.deleteMulti<GenericOrderLine>(order.order_line, ids).pipe(catchError(() => of(null)))
      .subscribe(orderline => {
        this.loading = false;
        if (!orderline) {
          this.toastService.show(`Impossibile eliminare la riga d'ordine corrente`, {classname: 'bg-danger'});
          return;
        }
        this.toastService.show(((ids.length === 1) ? `Riga d'ordine eliminata` : `Righe d'ordine eliminate`) + ' con successo');
      });
  }

  deleteAncillaryOrderLines(): void {
    if (!confirm('Confermi di voler eliminare i prodotti selezionati ? ')) {
      return;
    }
    this.loading = true;
    const ids = [];
    const order = this.selectedAncillaryOrder;
    order.order_line.values.forEach((orderLine) => {
      // if (orderLine.checked) {
      //   ids.push(orderLine.id);
      // }
    });

    this.odooEm.deleteMulti<GenericOrderLine>(order.order_line, ids).pipe(catchError(() => of(null)))
      .subscribe(orderline => {
        this.loading = false;
        if (!orderline) {
          this.toastService.show(`Impossibile eliminare la riga d'ordine corrente`, {classname: 'bg-danger'});
          return;
        }
        // this.toastService.show(((ids.length === 1) ? `Riga d'ordine eliminata` : `Righe d'ordine eliminate`) + ' con successo');
      });
  }

  toggleInventory(): void {
    this.inventoryClosed = !this.inventoryClosed;
  }

  toggleAncillaryInventory(): void {
    this.ancillartInventoryClosed = !this.ancillartInventoryClosed;
  }

  toggleInfo(): void {
    this.infoSideClosed = !this.infoSideClosed;
  }

  handleEventFromHeader(event: HeaderEmitterFormat) {
    switch (event.functionName) {
      case 'toggleInventory':
        this.toggleInventory();
        break;
      case 'deleteOrderLines':
        this.deleteOrderLines();
        break;
      // case 'print':
      //   this.print(event.value);
      //   break;
      case 'toggleInfo':
        this.toggleInfo();
        break;
      case 'cancelOrder':
        this.cancelOrder(event.value);
        break;
      case 'duplicateOrder':
        this.duplicateOrder(event.value);
        break;
      case 'changeOrderState':
        this.changeOrderState(this, event.value);
        break;
    }
  }

  cancelOrder(functionName: string) {
    if (!confirm(`Sei sicuro di voler annullare l\'ordine ?`)) {
      return;
    }
    this.loading = true;
    this.odooEm.call(this.order, functionName,this.order.id).subscribe(res => {
      if (!res) {
        this.toastService.show(`Impossibile annullare l'ordine`, {classname: 'bg-danger'});
        return;
      }
      this.order.state = 'cancel';
    });

    this.loading = false;
  }

  duplicateOrder(functionName: string) {
    if (!confirm(`Sei sicuro di voler duplicare l'ordine ?`)) {
      return;
    }
    this.loading = true;
    this.odooEm.callArray(this.order, functionName, null, String(this.order.id)).subscribe(newOrder => {
      if (!newOrder) {
        this.toastService.show(`Impossibile duplicare l'ordine`, {classname: 'bg-danger'});
        return;
      }
      this.selectedAncillaryOrder = null;
      this.router.navigate([`/${(this.order instanceof SaleOrder) ? 'sales' : 'purchases'}/${newOrder[0].id}`]);
    });
    this.loading = false;
  }

  updateOrderLineToOdoo(event: TableEmitterFormat) {
    if (!event.field) {
      this.checkIfSomethingChecked();
      return;
    }
    this.loading = true;
    event.orderLine[event.field] = event.orderLine[event.field].replace(`,`, '.');
    this.updateOrderLine(event).pipe(catchError(() => of(null)))
      .subscribe(
        odooOrderLine => {
          this.loading = false;
          if (!odooOrderLine) {
            this.toastService.show('Impossibile aggiornare il campo', {classname: 'bg-danger'});
            return;
          }
          // this.toastService.show('Campo aggiornato con successo');
          this.updateSingleOrderLine(odooOrderLine, event.orderLine);
        }
      );
  }

  updateOrderLine(event): Observable<GenericOrderLine> {
    return this.odooEm.onChange<GenericOrderLine>(new this.order.ORDER_LINE_TYPE(), event.orderLine.id, event.field, event.orderLine[event.field]);
  }

  updateSingleOrderLine(odooOrderLine, orderLineToUpdate) {
    Object.keys(orderLineToUpdate).forEach(k => {
      orderLineToUpdate[k] = odooOrderLine[k];
    });
  }

  updateOrderToOdoo(event: { order: GenericOrder, field: string }) {
    let value = event.order[event.field];
    if (event.order[event.field] instanceof OdooRelationship) {
      value = event.order[event.field].id;
    }
    this.loading = true;
    this.updateOrder(event, value).pipe(catchError(() => of(null))) // Handle Error
      .subscribe((order) => {
        this.loading = false;
      });
  }

  updateOrder(event, value): Observable<GenericOrder> {
    return this.odooEm.update<GenericOrder>(event.order, {[event.field]: value});
  }

  addOrderLine(product: Product) {
    if (this.loading) {
      return;
    }
    this.loading = true
    const order = this.order;
    const newOrderLine = order.ORDER_LINE_TYPE.createFromProduct(order.id, product);
    this.odooEm.create<GenericOrderLine>(new order.ORDER_LINE_TYPE(), newOrderLine).pipe(
      switchMap(orderLine => {
        return this.odooEm.onChange<GenericOrderLine>(orderLine, orderLine.id, 'product_id', orderLine.product_id.id).pipe(switchMap(() => {
          return this.odooEm.onChange<GenericOrderLine>(orderLine, orderLine.id, 'pezzi', 1);
        }));
      }), catchError(() => of(null))
    ).subscribe((orderLine : any) => {
      this.loading = false;
      if (!orderLine) {
        return;
      } // Push in current order
      order.order_line.values.push(orderLine);
    });
  }

  addAncillaryOrderLine(product: Product) {
    const order = this.selectedAncillaryOrder;
    const newOrderLine = order.ORDER_LINE_TYPE.createFromProduct(order.id, product);
    this.odooEm.create<GenericOrderLine>(new order.ORDER_LINE_TYPE(), newOrderLine).pipe(
      catchError((e) => {
        return of(e);
      }),
      switchMap(orderLine => {
        return this.odooEm.onChange<GenericOrderLine>(orderLine, orderLine.id, 'product_id', orderLine.product_id.id).pipe(switchMap(() => {
          return this.odooEm.onChange<GenericOrderLine>(orderLine, orderLine.id, 'pezzi', 1);
        }));
      }), catchError(() => of(null))
    ).subscribe( ( orderLine : any) => {
      this.loading = false;
      if (!orderLine) {
        return;
      } // Update current ancillary order and parent order
      order.order_line.values.push(orderLine);
      this.order = this.order as PurchaseOrder;
      // this.order.ancillary_purchase_order_ids.values.forEach(el => {
      //   if (el.id === order.id) {
      //     el.order_line.ids.push(orderLine.id);
      //   }
      // });
    });
  }

  // getPrintConfiguration(typeOfPrint: string): string {
  //   // switch (typeOfPrint) {
  //   //   case 'O':
  //   //     return (this.order instanceof SaleOrder) ? PRINTS_CFG.sale_order_sheet_id : PRINTS_CFG.purchase_order_sheet_id;
  //   //   case 'H':
  //   //     return PRINTS_CFG.sale_hundegger_sheet_id;
  //   //   case 'M':
  //   //     return PRINTS_CFG.sale_assembly_sheet_id;
  //   //   case 'F':
  //   //     return PRINTS_CFG.sale_finishing_sheet_id;
  //   //   case 'S':
  //   //     return PRINTS_CFG.sale_store_sheet_id;
  //   // }
  // }

  // print(typeOfPrint: string): void {
  //   this.loading = true;

  //   let headerRow; // just one
  //   this.elementRef.nativeElement.querySelectorAll('[data-print-row-header]').forEach(element => {
  //     headerRow = this.row2printable(element);
  //   });

  //   const headerDatas = []; // more than one
  //   this.elementRef.nativeElement.querySelectorAll('[data-print-row]').forEach(element => {
  //     headerDatas.push(this.row2printable(element));
  //   });

  //   from(this.gapiService.printOrder(this.getPrintConfiguration(typeOfPrint),
  //     PRINTS_CFG.spool_folder_id, headerRow, headerDatas)).pipe(
  //     catchError(e => {
  //       alert(e.result.error.message);
  //       throw new Error('Impossibile stampare'); // Use snackbar or other
  //     })
  //   ).subscribe(sheetid => {
  //     this.loading = false;
  //     window.open('https://docs.google.com/spreadsheets/d/' + sheetid, '_blank');
  //   });

  // }

  row2printable(r): any {
    const row = [];
    r.querySelectorAll('[data-print-col]').forEach(element => {
      console.log('row 2 printable', element, element.tagName === 'A');

      if (element.tagName === 'A') {
        row.push(element.innerText);
      } else if (element.tagName === 'SELECT') {
        if (element.selectedIndex > -1) {
          row.push(element.options[element.selectedIndex].innerHTML);
        }
      } else if (['INPUT', 'TEXTAREA', 'INPUT-NUMBER'].includes(element.tagName)) {
        row.push(element.value);
      } else {
        let t = '';
        element.querySelectorAll('*').forEach(e => {
          t += ((e.value ? e.value : e.innerText) + ' ');
        });
        row.push(t.trim());
      }
    });
    return row;
  }

  getTotal() {
    if (!this.order.order_line.values) {
      return;
    }
    let t = 0;
    this.order.order_line.values.forEach((l) => {
      t = Number(t) + Number(l.price_subtotal);
    });
    return Math.round(t * 100) / 100;
  }

  pickPartners() {
    const w = window.open('/contact?mode=embedded', '_blank', 'height=700,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
    const h = event => {
      const responseData = JSON.parse(event.data);
      this.pickedPartner = new OdooRelationship(responseData.id, responseData.name);
      w.close();
    };
    window.addEventListener('message', h, false);
    w.onbeforeunload = () => {
      window.removeEventListener('message', h);
      this.odooEm.update<GenericOrder>(this.selectedAncillaryOrder, {partner_id: this.pickedPartner.id})
        .pipe(switchMap(() => {
          // @ts-ignore
          return this.odooEm.resolve<PurchaseOrder>(this.order.ancillary_purchase_order_ids);
        }))
        .subscribe((res) => {
          if (!res) {
            return;
          }
          this.toastService.show('Partner cambiato con successo');
        });
    };
  }

  /**
   * Crea e associa un nuovo ordine
   */
  createNewAncillaryOrder() {
    // this.odooEm.createAndLinkToMulti<PurchaseOrder>(this.order, 'ancillary_purchase_order_ids', {partner_id: this.order.partner_id.id}).pipe(switchMap(purchaseOrder => {
    //   if (!purchaseOrder) {
    //     this.toastService.show(`Impossibile creare l'ordine ausiliario`, {classname: 'bg-danger', delay: 10000});
    //     return;
    //   }
    //   this.order = this.order as PurchaseOrder;
    //   return this.odooEm.resolve<PurchaseOrder>(this.order.ancillary_purchase_order_ids);
    // })).subscribe((ancillaryPurchaseOrders) => {
    //   this.toastService.show(`Ordine ausiliario creato con successo`);
    //   const maxOrderId = ancillaryPurchaseOrders.values.reduce(
    //     (maxId, order) => (order.id > maxId ? order.id : maxId),
    //     ancillaryPurchaseOrders.values[0].id
    //   );
    //   this.loadAndToggleAcillaryOrderLines(ancillaryPurchaseOrders.values.filter(order => order.id === maxOrderId)[0]);
    // });
  }

  deleteAncillaryOrder(ancillaryOrder: PurchaseOrder): void {
    if (!confirm(`Sei sicuro di voler disassociare l'ordine ausiliario?`)) {
      return;
    }
    this.odooEm.unlinkToMulti<PurchaseOrder>(this.order, 'ancillary_purchase_order_ids', ancillaryOrder.id).subscribe(res => {
      if (!res) {
        this.toastService.show(`Errore nell' eliminazione dell'ordine`, {classname: 'bg-danger'});
        return;
      }
      this.selectedAncillaryOrder = null;
      this.toastService.show(`Ordine disassociato con successo`);
    });
  }

  loadAndToggleAcillaryOrderLines(ancillaryOrder: PurchaseOrder) {
    if (ancillaryOrder === this.selectedAncillaryOrder) {
      this.selectedAncillaryOrder = null;
      return;
    }
    this.getAncillaryOrderLines(this, ancillaryOrder).subscribe(() => {
      this.selectedAncillaryOrder = ancillaryOrder;
      this.pickedPartner = ancillaryOrder.partner_id;
    });
  }
}
