import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UserInfo } from 'src/app/models/users';
import { BaseObj } from 'src/app/models/base';

export class QueryCriteria {
  column: string;
  type_column?: string;
  operator: string;
  value: string;
}


export class WebhookOptions {
  id: string;
  name: string;
  email: string;
}


export class CallOptions {
  id?: string;
  table: string;
  method: string;
  params?: string;
}

export class MessageOptions {
  dealId: number;
  subject: string;
  body: string;
}

export class OnChangeOptions {
  id: string;
  table: string;
  field: string;
  value: string | number;
}

export class QueryCriteriaOR extends QueryCriteria {
}

export class QueryResolve {
  key: string;
  table: string;
  fields?: string[];
}

export class QueryGetListOptions {
  table: string;
  ids: string[];
  fields?: string[];
  extra?: string;
  order?: string;
}

export class QuerySearchOptions {
  table: string;
  criteria: QueryCriteria[];
  resolve?: QueryResolve[];
  limit?: number;
  extra?: string;
  order?: string;
  fields?: string[];
}

export class QueryGetOptions {
  table: string;
  id?: string;
  fields?: string[];
  resolve?: QueryResolve[];
}

/* delete */
export class QueryDeleteOptions {
  table: string;
  ids: number[];
}

/* update */
export class QueryPutOptions {
  table: string;
  id?: string;
  ids?: number[];
  json_fields: any;
}

/* create */
export class QueryPostOptions {
  table: string;
  json_fields: any;
  id?: string;
}

export enum EventType {
  create,
  update,
  delete
}

export class ApiEvent {
  eventType: EventType;
  target: Object;
  table: string;
}


@Injectable({
  providedIn: 'root'
})
export class RestapiService {

  public emitter: EventEmitter<ApiEvent> = new EventEmitter();
  serverAdress = '/api/restapi/1.0/object/';
  arPendingRequest = [];
  static INSTANCE: RestapiService;

  constructor(private http: HttpClient) {
    RestapiService.INSTANCE = this;
  }

  getUserInfo(): Promise<UserInfo> {
    var query = 'api/restapi/1.0/get_user_info';

    return this.http.get(query).toPromise();
    //  Promise(resolve=> {
    //   this.http.get(query).subscribe((res) => {
    //     resolve(res)
    //   })
    // })
  }

  call(options: CallOptions): any {

    var query = this.serverAdress + options.table + '/function/' + options.method;
    query = options.id ? query + '/' + options.id : query;
    query = options.params ? query + '?' + options.params : query;
    return new Promise(resolve => {
      this.http.get(query).subscribe((res) => {
        resolve(res);
      });
    });
  }

  webhook(options: WebhookOptions) {

    var query = '/api/restapi/1.0/webhook/' + options.name + '/' + options.id + '?email=' + options.email;
    return new Promise(resolve => {
      this.http.get(query).subscribe((res) => {
        resolve(res);
      });
    });
    // this.loading = true
    // this.restapi.call({
    //   table: 'sale.order',
    //   id: this.order.id,
    //   method: method
    // }).then(() => {
    //   this.loading = false
    //   if(method == 'action_confirm')
    //     this.router.navigate(['sales/'+this.order.id]);
    //   else if(method == 'cambia_in_progettazione')
    //     this.order.state = "in_progettazione"
    //   else if(method == 'cambia_in_produzione')
    //     this.order.state = "in_produzione"
    // })
  }

  postMessage(options: MessageOptions) {
    ///restapi/1.0/object/crm.lead/function/message_post/<id?integer>?subject=SOGGETTO&body=TESTO
    var query = this.serverAdress + 'crm.lead' + '/function/' + 'message_post' + '/'
      + options.dealId + '?subject=' + options.subject + '&body=' + options.body;
    return new Promise(resolve => {
      this.http.get(query).subscribe((res) => {
        resolve(res);
      });
    });
  }

  onChange(options: OnChangeOptions): Promise<any> {
    return new Promise(resolve => {
      var query = this.serverAdress + options.table + '/' + options.id + '/onchange?values={\'' + options.field + '\': ';
      if (Array.isArray(options.value)) {
        query = query + '[' + options.value + ']}';
      } else if (isNumber(options.value)) {
        query = query + options.value + '}';
      } else if (!isNumber(options.value)) {
        query = query + '\'' + options.value + '\'}';
      }

      this.http.get(query).subscribe((res) => {
        // create a itemsChanged event
        var event: ApiEvent = new ApiEvent();
        event.eventType = EventType.update;
        event.target = res;
        event.table = options.table;
        // itemsChanged event
        this.emitter.emit(event);
        resolve(res);
      });
    });
  }

  get(options: QueryGetOptions): Promise<any> {
    return new Promise((resolve, reject) => {
      var query = this.serverAdress + options.table;

      if (options.id) {
        query = query + '/' + options.id;
      }

      var fields: string[] = [];
      if (options.fields) {
        options.fields.forEach((field) => {
          fields.push(`%27${field}%27`);
        });
      }

      if (fields.length > 0) {
        query = query + '?fields=[' + fields.join(',') + ']';
      }

      this.http.get(query).subscribe(
        (res) => {

          if (options.resolve) {
            var promises: Promise<any>[] = [];

            options.resolve.forEach((toresolve) => {
              if (res[options.table][toresolve.key].length) {
                ((tor: QueryResolve) => {
                  promises.push(
                    new Promise(resolve2 => {
                      // load-detail pass a id value ["id","string"], strip string
                      var ids = res[options.table][tor.key];
                      for (var i = 0; i < ids.length; i++) {
                        if (!isNumber(ids[i])) {
                          ids.splice(i, 1);
                          i--;
                        }
                      }

                      this.getList({
                        ids,
                        table: tor.table,
                        fields: tor.fields
                      }).then((r) => {
                        res[options.table]['_' + tor.key] = r;
                        resolve2(null);
                      }).catch(error => {
                        reject(error);
                      });
                    })
                  );
                })(toresolve);
              }
            });

            Promise.all(promises).then((r) => {
              resolve(res[options.table]);
            });
          } else {
            resolve(res[options.table]);
          }
        }, (error) => {
          error.error.error && error.error.error.message ? reject(error.error.error.message) : reject(error.message);
        });
    });
  }

  getList(options: QueryGetListOptions): Promise<any> {
    return new Promise((resolve, reject) => {
      var query = this.serverAdress + options.table;


      if (options.ids.length == 0) {
        return Promise.resolve([]);
      }

      query = query + '?ids=' + options.ids.join(',');

      var fields: string[] = [];
      if (options.fields) {
        options.fields.forEach((field) => {
          fields.push(`%27${field}%27`);
        });
      }

      if (fields.length > 0) {
        query = query + '&fields=[' + fields.join(',') + ']';
      }

      if (options.order) {
        query = query + '&order=' + options.order;
      }

      this.http.get(query).subscribe((res) => {
        if (res[options.table].length) {
          resolve(res[options.table]);
        } else {
          resolve([res[options.table]]);
        }
      }, (error) => {
        error.error.error && error.error.error.message ? reject(error.error.error.message) : reject(error.message);
      });
    });
  }

  deleteObj(o: BaseObj) {
    return new Promise((resolve, reject) => {
      var query = this.serverAdress + o.table;
      query = query + '?ids=' + o.id;
      this.http.delete(query).subscribe((res) => {
        resolve(res);
      }, error => {
        resolve(null);
      });
    });
  }

  delete(options: QueryDeleteOptions): Promise<any> {
    return new Promise(resolve => {
      var query = this.serverAdress + options.table;
      query = query + '?ids=' + options.ids.join(',') + '';
      this.http.delete(query).subscribe((res) => {
        resolve(res);
      }, error => {
        resolve(null);
      });
    });
  }

  async put2<T extends BaseObj>(o: T): Promise<T> {
    return this.put({id: o.id, table: o.table, json_fields: o.serialize()});
  }

  async put(options: QueryPutOptions): Promise<any> {
    return new Promise((resolve, reject) => {
      var query = this.serverAdress + options.table;
      query = query + '/' + options.id;
      this.http.put(query, options.json_fields).subscribe((res) => {
        // create a itemsChanged event
        var event: ApiEvent = new ApiEvent();
        event.eventType = EventType.update;
        event.target = res[options.table];
        event.table = options.table;
        // itemsChanged event
        this.emitter.emit(event);
        resolve(res[options.table]);
      }, error => {
        error.error.error && error.error.error.message ? reject(error.error.error.message) : reject(error.message);
      });
    });
  }

  post(options: QueryPostOptions): Promise<any> {
    return new Promise((resolve, reject) => {
      var query = this.serverAdress + options.table;
      if (options.id) {
        query += '/' + options.id;
      }
      this.http.post(query, options.json_fields).subscribe((res) => {
        resolve(res[options.table]);
      }, error => {
        error.error.error && error.error.error.message ? reject(error.error.error.message) : reject(error.message);
      });
    });
  }

  post2<T extends BaseObj>(o: T): Promise<T> {
    return new Promise((resolve, reject) => {
      this.http.post(this.serverAdress + o.table, o.serialize()).subscribe((res) => {

        const c = Object.assign(o, res[o.table]);
        resolve(c);
      }, error => {
        error.error.error && error.error.error.message ? reject(error.error.error.message) : reject(error.message);
      });
    });
  }

  async search2<T extends BaseObj>(table, q: QueryCriteria[]): Promise<T[]> {
    var options: QuerySearchOptions = {
      criteria: q, table,
      resolve: [{
        table,
        key: 'id',
        // fields : ["name", "state","parent_id", "comment", "id", "name", "company_type", "vat"]
      }]
    };
    var r = (await this.search(options));
    if (r && !Array.isArray(r)) {
      r = [r];
    }
    return r;
  }

  private executeSearch(options: QuerySearchOptions, method: string = 'search'): Promise<any> {
    return new Promise((resolve, reject) => {

      var query = this.serverAdress + options.table;
      var domains: string[] = [];

      options.criteria.forEach((domain: QueryCriteria) => {
        if (domain instanceof QueryCriteriaOR) {
          domains.push('%27|%27');
        } else {
          let value:any = Array.isArray(domain.value) ? `[${domain.value}]` : '%27' + domain.value + '%27';
          
          console.log("ooo ", domain, domain.type_column)


          if (domain.type_column && domain.type_column === 'integer') {
            console.log("ooo inter", domain, domain.type_column)
            value = value.replace(/%27/g, '');
            domains.push(`(%27${domain.column}%27,%27${domain.operator}%27,${value})`);
          } else if (domain.type_column && domain.type_column == 'boolean') {
            if (value == "true")
              domains.push(`(%27${domain.column}%27,%27${domain.operator}%27,True)`);
            else 
              domains.push(`(%27${domain.column}%27,%27${domain.operator}%27,False)`);
          }
          else
            domains.push(`(%27${domain.column}%27,%27${domain.operator}%27,${value})`);
        }
      });

      if (domains.length > 0) {
        query = `${query}/${method}?domain=[${domains.join(',')}]`;
      }

      if (options.fields) {
        if (!query.includes('?')) {
          query += '?';
        } else {
          query += '&';
        }
        query += `fields=[%27${options.fields.join('%27,%27')}%27]`;
      }

      if (options.extra) {
        query = query + options.extra;
      }

      if (options.order) {
        query = query + '&order=' + options.order;
      }

      if (options.limit) {
        var sep = (query.indexOf('?') > -1) ? "&" : "?"
        query = query + sep + 'limit=' + options.limit;
      }

      this.http.get(query).subscribe(
        res => {
          if (options.resolve) {
            var promises: Promise<any>[] = [];
            options.resolve.forEach((toresolve) => {
              ((tr: QueryResolve) => {
                promises.push(
                  this.getList({
                    order: options.order ? options.order : null,
                    ids: res[tr.table],
                    table: tr.table,
                    fields: tr.fields
                  }).catch(error => {
                    reject(error);
                  }));
              })(toresolve);
            });
            Promise.all(promises).then((x) => {
              resolve(x[0]);
            });
          } else {
            resolve(res);
          }
        }, error => {
          // odoo give a 404 when no entry founded
          if (error.error.code == 404) {
            resolve([]);
            return;
          }

          error.error.error && error.error.error.message ? reject(error.error.error.message) : reject(error.message);
        });
    });
  }

  search(options: QuerySearchOptions): Promise<any> {
    return this.executeSearch(options);
  }

  searchRead(options: QuerySearchOptions): Promise<any[]> {
    return this.executeSearch(options, 'search_read');
  }

  /**** sales  ******/
  // searchSales(word: any) {

  //   var arrayPromises = []
  //   var arrayWords = word.split(" ")
  //   var string = "%27|%27,"
  //   for (let x = 0; x < arrayWords.length; x++)
  //     string = string + "(%27partner_id%27,%27ilike%27,%27"+arrayWords[x]+"%27)"
  //   for (let x = 0; x < arrayWords.length; x++)
  //     string = string + "(%27name%27,%27ilike%27,%27"+arrayWords[x]+"%27)"
  //   string = string.replace(/\)\(/g,"),(")
  //   if(this.arPendingRequest.length > 0){
  //     for (let i = 0; i < this.arPendingRequest.length; i++) {
  //       const pr = this.arPendingRequest[i]
  //       pr.unsubscribe()
  //     }
  //   }
  //   this.arPendingRequest.push( this.http.get(this.serverAdress + 'sale.order/search?domain=['+string+']&limit=50')
  //     .subscribe(
  //       data => {
  //           for (let i = 0; i < data['sale.order'].length; i++) {
  //             const id =  data['sale.order'][i];
  //             ( (id) => {
  //               arrayPromises.push(
  //                 new Promise( (resolve, reject) => {

  //                   var field =
  //                   this.arPendingRequest.push(
  //                     this.http.get(this.serverAdress + 'sale.order/' + id ).subscribe( (item) => {

  //                       console.log( item)
  //                       resolve(item)
  //                     })
  //                   )
  //                 })
  //               )
  //             })(id);
  //           }
  //           Promise.all(arrayPromises).then(values => {
  //             this.items = values;
  //             this.itemsChanged.emit(this.items);
  //           })
  //     },error => {
  //       console.log( error )
  //       this.items = [];
  //       this.itemsChanged.emit(this.items);
  //     }
  //   ))
  // }

  // non lo usa nessuno!
  // getSale(id) {
  //   var arrayPromises = [];
  //   return new Promise(resolveP => {
  //     this.http.get(this.serverAdress + 'sale.order/'+id).subscribe( item => {
  //       var item = item['sale.order'];
  //       arrayPromises.push(
  //         new Promise( (resolve, reject) => {
  //           this.arPendingRequest.push(  this.http.get(this.serverAdress + 'sale.order.line?domain=[(%27order_id%27,%27ilike%27,%27'+id+'%27)]').subscribe( (items ) => {
  //             resolve(items)
  //           }))
  //         })
  //       )
  //       Promise.all(arrayPromises).then(values => {
  //           item['products'] = values;
  //           resolveP(item);
  //       })
  //     })
  //   })
  // }

  // non usato da nessuno
  // saveSale(id, json){
  //   return new Promise(resolve => {
  //      this.http.put(this.serverAdress + "sale.order/" +id, json).subscribe(
  //        data => {
  //          resolve(true)
  //        },
  //        err => console.log(err)
  //      )
  //    })
  // }

}

function isNumber(value: any) {
  return typeof value === 'number'
}

