import { forwardRef, Injectable } from '@angular/core';

import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { GmailImportComponent } from 'src/app/gmail/gmail-import/gmail-import.component';
import { SaleOrder } from 'src/app/models/order';


export class Sheet {
    
}

@Injectable({
    providedIn: 'root'
})

export class GapiService {
   
    private readonly API_URL: string = 'https://www.googleapis.com/drive/v3/';
    public $ready = new ReplaySubject(1)
    public static SESSION_STORAGE_KEY: string = 'accessToken';
    user: any;
    driveBusyMessage= new Subject();
    CLIENTID: string = '470272109138-p98fm5g5qs8i21o01rir4tbteann1a1i.apps.googleusercontent.com';
    
    constructor() { 
        gapi.load('client', () => {
            gapi.client.load('drive', 'v3', () => {
                gapi.client.load('gmail', 'v1', () => {
                    

                    gapi.client.load('sheets', 'v4', () => {
                        gapi.auth2.init({
                            client_id: this.CLIENTID,
                            scope: ['https://www.googleapis.com/auth/gmail.labels',
                                    'https://www.googleapis.com/auth/gmail.send',
                                    'https://www.googleapis.com/auth/gmail.modify',
                                    'https://www.googleapis.com/auth/drive',
                                    'https://www.googleapis.com/auth/drive.file',
                                    'https://www.googleapis.com/auth/drive.readonly',
                                    'https://www.googleapis.com/auth/spreadsheets',
                                    'https://www.googleapis.com/auth/spreadsheets.readonly'].join(" ")
                        }).then(x => {
                            this.$ready.next(true)
                        })
                    }); // sheets

                })  
            })

        })

        
    }
    

    async searchEmailsForLead(leadId) {
         // todo fix types ts
         var g = (gapi.client as any).gmail
         g.users.messages.list({'userId':'me', 'q': " #F" + leadId}).execute(x => {
             console.log("SSS", x)
         })
         
    }

    async insertEmail(id,tc) {

        return new Promise(async (res,rej) => {
            console.log("msg22 ff")

            // todo fix types ts
            var g = (gapi.client as any).gmail
            var msg = (await  g.users.messages.get({'userId':'me', 'id':id, 'format':'raw'})).result
            var raw = atob(msg.raw.replace(/_/g, '/').replace(/-/g, '+'))

            const regex = /.*Subject:\s(.*)/gm;
            const subst = `Subject: $1` + tc;
            raw = raw.replace(regex, subst);
            
            g.users.messages.insert({
                    'userId':'me',
                    'internalDateSource':'dateHeader',
                    "labelIds": ["INBOX"],
                    'raw': btoa(raw)
            }).execute(async x => {

                await this.addLabelToMessage(x.id)
                raw = raw.replace(/.*To:\s.*/g, "To: odoo@galimberti.eu")
                var regex = /Subject: (.*)(#F\d*$)/gm;
                var subst = `Subject: $2 - $1` ;
                raw = raw.replace(regex, subst);

                g.users.messages.send({
                    'userId':'me',
                    'resource': {
                        'raw': btoa(raw)
                    }
                }).execute(x => {
                    res(x)
                })
            })
        })
        

    }

    public async addLabelToMessage(id) {

        return new Promise( (res,rej)=> {
            console.log("import wati singnin ")
            console.log("import dopo wati singnin ")
            var g = (gapi.client as any).gmail
            var labels =  g.users.labels.list({
                'userId': 'me'
            }).execute((r) => {
                console.log("labels1", r.labels)    
                var r = r.labels.filter(x => x.name == 'LEAD')
    
            console.log("labels", r)
              g.users.messages.modify({
                'userId': 'me',
                'id': id,
                'resource': {
                    'addLabelIds': [r[0].id],
                    'removeLabelIds': []
    
                }
              }).execute((x) => {
                  res(x)
              })
    
            })
        })
                
    }

    public async printOrder(sheet_id:string, target_folder_id:string, header:[], rows:any[]):Promise<Sheet> {
        
        await this.signIn()
        // create the sheeet
        const o = (await gapi.client.drive.files.copy({
                supportsAllDrives: true,
                fileId: sheet_id,
                resource: {
                    parents: [target_folder_id]
                },
                fields: 'id, parents'
            })
        )

        // await gapi.client.drive.files.update({
        //     fileId: o.result.id,
        //     addParents: target_folder_id,
        //     removeParents: o.result.parents[0],
        //     supportsAllDrives: true,
        //     fields: 'id, parents',
        //     resource: {},
        // })

        console.log( " PPPPPPRR o.result.id ", o.result.id)

        await gapi.client.sheets.spreadsheets.values.append(
            {
                spreadsheetId : o.result.id,
                range: 'Testata',
                valueInputOption: 'RAW',
            }, {
                values: [header]
            }
        )


        await gapi.client.sheets.spreadsheets.values.append(
            {
                spreadsheetId : o.result.id,
                range: 'Dati',
                valueInputOption: 'RAW',
            }, {
                values: rows
            }
        )
        return o.result.id
    }

    public signIn() {
        
        return new Promise(async (resolve) => {
            console.log("+sign in ", gapi.auth2, gapi.auth2.getAuthInstance())
            var is = await gapi.auth2.getAuthInstance().isSignedIn.get()
            if (!is)
                gapi.auth2.getAuthInstance().signIn().then(r => {
                    resolve(true)
                })
            else
                resolve(true)
        })
    }
    
    async linkFolder(src: any, dst: string, name) {
            var r = await gapi.client.drive.files.create({
                supportsAllDrives: true,
                resource: {
                    name: name,
                    mimeType: 'application/vnd.google-apps.shortcut',
                    parents: [dst],
                    shortcutDetails: {
                        targetId: src,
                        targetMimeType: "application/vnd.google-apps.folder"
                    }
                }
            })
    }

    async checkToken():Promise<boolean> {
        return new Promise(res => {
            res(true)            
        })
    }


    private async listFiles(from) {
        const files = (await gapi.client.drive.files.list({
            supportsAllDrives: true,
            includeItemsFromAllDrives: true,
            corpora: "allDrives",
            // driveId: "0AF6XiGxtf1FwUk9PVA",
            q: `'${from.id}' in parents and mimeType != 'application/vnd.google-apps.folder' and trashed = false`,
        // pageSize: 100,
            fields: 'files(id, name)'
        })).result.files
        console.log("FILES", files)
        return files
    }
    
    async cloneFolder(from, to, skipCreate?:boolean, newname?,observable?:Subject<string>) {
            await this.signIn()
            
            // Create new folder
            var newFolder:{id?:string} = to
            
            
            if (!skipCreate) {
                
                console.log("OBS x", skipCreate, newFolder)

                // try {
                    this.driveBusyMessage.next("Creo " + (newname ? newname : from.name))
                    console.log("CREATED folder ", newname ? newname : from.name)
                    
                    var r = await gapi.client.drive.files.create({
                        supportsAllDrives: true,
                        resource: {
                            name: newname ? newname : from.name,
                            mimeType: 'application/vnd.google-apps.folder',
                            parents: [to.id]
                        }
                    })
                    
                    newFolder = r.result
                    
                    console.log("OBS 111", observable)

                    if (observable != undefined) {
                        console.log("OBS 222", observable)
                        console.log("-->OBS NEXT x", observable)
                        observable.next(newFolder.id)
                    }
                // } catch(e) {
                //     if (e.status == 401 || e.status == 403) {
                //         console.log("+clone folder 410 exception ")
                //         // const newFolder = r.result
                //         await this.signIn()
                //         return await this.cloneFolder(from,to, skipCreate, newname)
                //     }
                // }
            } else {
                newFolder = to
                observable.next(to.id)
            }

            // Find all sub-folders
            var folders


            folders = (await gapi.client.drive.files.list({
                supportsAllDrives: true,
                includeItemsFromAllDrives: true,
                corpora: "allDrives",
                // driveId: "0AF6XiGxtf1FwUk9PVA",
                q: `parents in '${from.id}' and mimeType = 'application/vnd.google-apps.folder' and trashed = false`,
                //  pageSize: 100,
                 fields: 'files(id, name)'
            })).result.files

            console.log("FOLDRS", folders)
        

            // Find all files 
            console.log("FILES from ", from.id)
            // try {
            //     var files = await this.listFiles(from)
            // } catch(e) {
            //     console.log("catchlllll ", e)
            //     var files = await this.listFiles(from)
            // }
            const files = (await gapi.client.drive.files.list({
                supportsAllDrives: true,
                includeItemsFromAllDrives: true,
                corpora: "allDrives",
                // driveId: "0AF6XiGxtf1FwUk9PVA",
                q: `'${from.id}' in parents and mimeType != 'application/vnd.google-apps.folder' and trashed = false`,
            // pageSize: 100,
                fields: 'files(id, name)'
            })).result.files
            console.log("FILES", files)
            
        
            for (var x = 0; x < files.length;x++) {
                let file = files[x]
                this.driveBusyMessage.next("Copio " + file.name) 
                // Create Copy of File
                await gapi.client.drive.files.copy({
                        supportsAllDrives: true,
                        fileId: file.id,
                        resource: {
                            parents: [newFolder.id]
                        },
                })
            }
            
            // Recursion
            for (var f of folders) {
                await this.cloneFolder(f,newFolder)
            }
            // this.driveBusyMessage.next(null) 

            
            return newFolder.id
       }

    
}