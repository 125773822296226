<nav class="navbar navbar-expand-md  fixed-top border-bottom bg-dark navbar-dark">

  <div class="container-fluid">
      <button class="btn btn-link text-white mr2-auto" routerLink=".." >
          <i class="fas fa-chevron-left"></i>      
      </button>
    <a class="navbar-brand" href="#">
      &nbsp;<b>{{title}}</b>
    </a>
    
    <button class="navbar-toggler navbar-toggler-right border-0" type="button" data-toggle="collapse" data-target="#navbar4">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbar4">
      <!-- NUOVO DEAL -->

      <div class="dropdown ms-auto dropstart">
        <button class="btn btn-primary text-white dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          Nuovo
        </button>
        <ul class="dropdown-menu">
          <form class="px-4 py-3" style="min-width: 440px;">
            <div class="form-group">
              <select 
                name="area" 
                class="form-control" 
                [(ngModel)]="newDeal.area"
                (change)="filter.next(newDeal.area)"
                placeholder="Settore"
              >
                <option value="" disabled>Settore</option>
                <option *ngFor="let a of areas" [ngValue]="a.name">{{a.name}}</option>
              </select>
            </div>
            <app-contact *ngIf="newDeal.area" [mode]="'embedded'" class="embedded" (onSelect)="onContact($event)"></app-contact>
          </form>
        </ul>
      </div>


      
      <!-- new deal -->
    </div>
  </div>
  <bar-loader [loading]="loading"></bar-loader>
</nav>

<div class="nav d-flex border p-2 pe-4 bg-light align-items-center" style="margin-top:60px">
  <!-- <div class="btn btn-link"><i class="fa fa-calendar"></i> 3 mesi</div> / <div class="btn btn-link">Tutto</div> -->
  <a href="https://m.galimberti.eu/leads" target="_blank" class="btn btn-dark">Fascicoli &lt; #3000</a>
  <span class="ms-auto">Settore</span>
  <button (click)="filter.next('')" [ngClass]="(!filter.getValue() || filter.getValue() == '')  ? 'btn-primary': 'btn-dark'" class="btn ms-2 text-white"><i class="fa fa-asterisk"></i></button>
  <button (click)="filter.next('Aziendale')" [ngClass]="filter.getValue() == 'Aziendale' ? 'btn-primary': 'btn-dark'" class="btn ms-2 text-white">Aziendale</button>
  <button (click)="filter.next('Tetti')" [ngClass]="filter.getValue()=='Tetti' ? 'btn-primary': 'btn-dark'" class="btn ms-2 text-white">Tetti</button>
  <button (click)="filter.next('Case')" [ngClass]="filter.getValue()=='Case' ? 'btn-primary': 'btn-dark'" class="btn ms-2 text-white">Case</button>
  <button (click)="filter.next('Facciate e Decking')" [ngClass]="filter.getValue() == 'Facciate e Decking' ? 'btn-primary': 'btn-dark'" class="btn ms-2 text-white">Facciate e Decking</button>
  <button (click)="filter.next('Pavimenti')" [ngClass]="filter.getValue()=='Pavimenti' ? 'btn-primary': 'btn-dark'" class="btn ms-2 text-white">Pavimenti</button>
  <div clas="form-inline">
    <input class="form-control ms-2" #searchInput>
  </div>
  <button class="btn btn-link ms-3" data-toggle="tooltip" data-placement="bottom" title="Mostra solo recenti" label="Mostra solo recenti" (click)="toggleRecents()"><i class="fa fa-calendar" [ngClass]="{'text-primary': justRecents, 'text-muted': !justRecents}"></i></button>
  <!-- <select class="form-control ms-3 w-auto" [(ngModel)]="months">
    <option value="3">3</option>
  </select> -->
</div>

<div class="w-100 trello-like p-4" >
  <ul *ngFor="let stage of stages;">
    <li>
      <h3>
        {{stage.name}}
      </h3>
    </li>
    <li (click)="redirectDeal(card.id)" *ngFor="let card of filterCards(stage.id)" class="d-flex flex-column">
      <p class="lead mb-1">{{card.tracking_code}} - {{card.partner_id.name | uppercase}}</p>
      <p class="text-muted mb-0 ">{{card.opportunity}}</p>
      <p class="text-muted mb-0 ">{{card.city}}</p>
      <div class=" align-items-end d-flex mt-2">
        <p class="badge lead  ms-auto mb-0 badge-info">{{card.area}}</p>
        <p class="badge lead ms-2 mb-0 badge-primary text-white">{{card.user_id.name}}</p>
      </div>
    </li>
  </ul>
</div>
