import { OdooMultiRelationship } from './odoo-multi-relationship.model';
import { SaleOrderLine } from './sale-order-line.model';
import { OdooSerializableInterface } from '../shared/interfaces/odoo-serializable-interface';
import { GenericOrder } from './generic-order';
import { OdooRelationship } from './odoo-relationship.model';
import { Placeholder } from './placeholder.model';
import { IConnectable, TrelloCardEntry2 } from './trello-card';
import { Lead } from './crm.lead.model';

export class SaleOrder extends GenericOrder  {
  
  public readonly ODOO_MODEL = 'sale.order';
  public readonly ORDER_TYPE = SaleOrder;
  public readonly ORDER_TYPE_NAME = 'SaleOrder';
  public readonly ORDER_LINE_TYPE = SaleOrderLine;

  id: number;
  confirmation_date: string;
  incoterm: OdooRelationship;
  order_line: OdooMultiRelationship<SaleOrderLine>;
  parcels: number;
  payment_note: string;
  reference: string;
  signature: boolean;
  volume: number;
  weight: number;
  commitment_date: string = "";
  opportunity_id: OdooRelationship<Lead> = new OdooRelationship<Lead>();


  constructor(id?: number) {
    super(id);
    this.incoterm = new OdooRelationship();
    this.order_line = new OdooMultiRelationship<SaleOrderLine>(SaleOrderLine);
  }

  create() {
    return new SaleOrder()
  }

}
