import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MrpProduction } from 'src/app/models/mrp-production';
import { ProductCategory } from 'src/app/models/product.category';
import { Product } from 'src/app/models/product.model';
import { ProductTemplate } from 'src/app/models/product.template.model';
import { StockMove } from 'src/app/models/stock-move';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';

class CategoryBudget{
  name: string
  product_uom : string = ""
  quantity: any
  list_price : number
  total : number
}

@Component({
  selector: 'app-deal-budget',
  templateUrl: './budget.component.html',
})
export class BudgetComponent implements OnInit {

  @Input() productions:MrpProduction[]
  arrayCategories: CategoryBudget[];

  categories: ProductCategory[];
  constructor(private odooEm: OdooEntityManager) { }

  async ngOnInit() {

    return  
    this.arrayCategories = []


    this.categories = await this.odooEm.search<ProductCategory>(new ProductCategory()).toPromise()
    for (let index = 0; index < this.categories.length; index++) {
      const c = this.categories[index];
      if(c.name.indexOf("/") == -1){
        this._pushQuantity( c.name ,"", 0, 0 )
      }
    }

    console.log("categories ", this.categories)

    for (let index = 0; index < this.productions.length; index++) {

      const p = this.productions[index];
      await this.odooEm.resolve(p.move_raw_ids).toPromise()
      if (p.move_raw_ids.values) {
        await this.odooEm.resolveArrayOfSingle<Product, StockMove>(new Product(), p.move_raw_ids.values, 'product_id').toPromise()
        await this.odooEm.resolveArrayOfSingle<ProductTemplate, StockMove>(new ProductTemplate(), p.move_raw_ids.values, 'product_tmpl_id').toPromise()

        for (let index = 0; index < p.move_raw_ids.values.length; index++) {
          const r  = p.move_raw_ids.values[index];
          var nameC = r.product_id.value.categ_id.name.split("/")[0]
          this._pushQuantity( nameC,r.product_uom[1], r.product_uom_qty, r.product_id.value.list_price )
        }
      }
   
    }

    this.arrayCategories.sort( (a,b) =>a.name.localeCompare(b.name) )
    
  }
  private _pushQuantity(nameC: string, product_uom: string ,product_uom_qty: number,  list_price: number ) {


    var c = this.arrayCategories.find( (c)=> c.name == nameC )
    if( c ){
      c.quantity = c.quantity + product_uom_qty
      c.list_price = list_price
      c.total = c.quantity * c.list_price
    }else{

      var budgetC = new CategoryBudget
      budgetC.name = nameC
      budgetC.quantity = product_uom_qty
      budgetC.list_price = list_price
      budgetC.product_uom = product_uom

      budgetC.total = product_uom_qty * list_price
      this.arrayCategories.push(budgetC)

    }
  }

}

