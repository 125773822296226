import { Component, Input, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Contact, ContactLink } from 'src/app/models/contact.model';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';

@Component({
  selector: 'app-contact-picker',
  templateUrl: './contact-picker.component.html'
})
export class ContactPickerComponent implements OnInit {
  @Input() loading:boolean
  @Input() contact:Contact

  openContact: Contact;
  editingContact: boolean;
  // recents:RecentCalls = new RecentCalls()

  constructor(
    private odooEm: OdooEntityManager
  ) { }

  async ngOnInit() {
    console.log("init",this.contact)
    if (!this.contact || !this.contact.child_ids.values) {
      await this.loadChild()
    }

    console.log("LINK load", await this.odooEm.search(new ContactLink()).toPromise())
  }

  async loadChild() {
    this.loading = true
    await this.odooEm.resolve(this.contact.child_ids).pipe(first()).toPromise()
    await this.odooEm.resolveArray<ContactLink,Contact>(new ContactLink(), this.contact.child_ids.values, 'link_ids').toPromise()

    console.log("RESOLVEXXX ", this.contact)
    this.loading = false
  }

  async newLink(c:Contact) {
    console.log("new link")

    const f = {
      value : "",
      type:"Cell",
      note: "",
      partner_id: c.id
    }
    var newlink = await this.odooEm.create<ContactLink>(new ContactLink(), f).pipe(first()).toPromise()
    console.log("LINK new ", newlink)
    c.link_ids.ids.push(newlink.id)
    if (!c.link_ids.values)
      c.link_ids.values = [newlink]
    else
      c.link_ids.values.push(newlink)
  }

  async deleteChild(c:Contact) {
    if (!confirm('Confermi di voler eliminare il contatto ?')) {
      return;
    }

    this.loading = true
    await this.odooEm.deleteMulti(c.child_ids,[c.id]).pipe(first()).toPromise()
    
    this.contact.child_ids.ids = this.contact.child_ids.ids.filter(i => i != c.id)
    this.contact.child_ids.values = this.contact.child_ids.values.filter(i => i != c)

    this.loading = false
  }

  newChild() {
    console.log("new child")

    
    const fields = {
      name: "",
      comment: "",
      parent_id: this.contact.id
    }
    console.log( "new child ", fields)
    
    this.odooEm.create<Contact>(new Contact(),fields).pipe(first()).subscribe(c => {
      this.contact.child_ids.values.push(c)
      
      this.openContact = c
      this.editingContact = true
    }) 

  }


  getTypeForLink(l:ContactLink):'mailto'|'href'|'tel' {
    if (l.value && l.value.indexOf('@') > -1)
      return 'mailto'
    else if (l.value &&  l.value.match(/[a-zA-Z]\.[a-zA-Z]/g)) 
      return 'href'
    else 
      return 'tel'
  }


  onChangeContact(subContact:Contact, prop:string) {
    this.loading = true
    var p = {}
    p[prop] = subContact[prop]
    return this.odooEm.update(subContact,p).pipe(first()).toPromise()
  }

  async onDeleteContact(contact:Contact) {
    
    if (!confirm('Sei sicuro di volerlo eliminare ?')) 
      return
    await this.odooEm.delete(new Contact(),[contact.id]).pipe(first()).toPromise()

    this.contact.child_ids.ids = this.contact.child_ids.ids.filter(i => i != contact.id)
    this.contact.child_ids.values = this.contact.child_ids.values.filter(i => i != contact)
  }

  onClickLink(ev, c:Contact, l:ContactLink) {
    // this.recents.addToRecents(this.contact)
  }

  onChangeLink(link:ContactLink, field:string) {

    console.log("ON CHANEG LINK ", link, field)
    var x = {}
    x[field] = link[field]
    return this.odooEm.update<ContactLink>(link, x);
  }

  async onDeleteLink(c:Contact, l:ContactLink) {
    if (!confirm('Sei sicuro di volerlo eliminare ?')) 
      return
    
    await this.odooEm.delete(new ContactLink(),[l.id]).pipe(first()).toPromise()

    c.link_ids.ids = c.link_ids.ids.filter(i => i != l.id) 
    c.link_ids.values = c.link_ids.values.filter(i => i != l) 
    // arrOriginal.filter(function(el){return el !== elementToRemove});
  }
}


