import { OdooModel } from './odoo-model.model';
import { OdooSerializableInterface } from '../shared/interfaces/odoo-serializable-interface';
import { OdooRelationship } from './odoo-relationship.model';

export class AccountIncoTerm extends OdooModel implements OdooSerializableInterface<AccountIncoTerm> {
  public readonly ODOO_MODEL = 'account.incoterms';

  create_date: string;
  active: boolean;
  write_uid: OdooRelationship;
  write_date: string;
  display_name: string;
  code: string;
  id: number;
  create_uid: OdooRelationship; // Avrà un tipo
  __last_update: string;
  name: string;


  constructor(id?: number, create_date?: string, active?: boolean, write_uid?: OdooRelationship, write_date?: string,
              display_name?: string, code?: string, create_uid?: OdooRelationship, _last_update?: string, name?: string) {
    super(id);
    this.create_date = create_date;
    this.active = active;
    this.write_uid = write_uid;
    this.write_date = write_date;
    this.display_name = display_name;
    this.code = code;
    this.id = id;
    this.create_uid = create_uid;
    this.__last_update = _last_update;
    this.name = name;
  }

  create(): AccountIncoTerm {
    return new AccountIncoTerm();
  }
}
