<app-navbar>
  <a class="navbar-brand">
    Muletto {{forkliftid}}
  </a>
 
</app-navbar>

<!-- <nav class="navbar text-white navbar-dark align-items-center" >
    <button class="btn btn-link text-white me-auto" routerLink="../.." >
        <i class="fas fa-chevron-left"></i>      
    </button>
    <h1 class="mx-auto"></h1>
    <div class="ms-auto"></div>

  
    <bar-loader [loading]="loading"></bar-loader>
  </nav> -->


  <div class="d-flex w-100" style="flex:1 1 auto;">
    
    <div class="w-75 bg-danger d-flex">
      <canvas></canvas>
      <video autoplay controls class="" ></video> 
    </div>

    <div class="w-25 scrollable bg-dark p-3 text-white">
      
      
      <div class="card bg-dark">
        <div class="card-header">
          Pacchi
        </div>

        <div class="card-body p-0"  *ngIf="!this.package">
          <input class="w-100 form-control rounded-0" type="number" placeholder="Cerca" 
            [ngModel]="search$ | async"
            (ngModelChange)="search$.next($event)"
          >
          <ul  class="list-group text-dark list-group-flush" *ngIf="!this.package">
            <li *ngFor="let p of packages" class="text-dark list-group-item d-flex justify-content-between" (click)="onPackage(p)" >
              <span class="text-muted">
                {{p.name}}
              </span>
              <span class="text-muted">
                {{formatLocation(p.location_id.name)}}
              </span>
            </li>
          </ul>
        </div>


        <div class="card-body "  *ngIf="this.package">
          <div class="d-flex align-items-center justify-content-center flex-column pb-3" *ngIf="this.package">
            <i *ngIf="this.packageReaded == false" class="fa-thin fa-barcode-read fa-beat fa-3x my-4"></i> 
            <i *ngIf="this.packageReaded == true" class="fa-solid fa-barcode-read text-success fa-3x my-4"></i> 
            <b>Pacco {{this.package.name}}</b>
            <span>{{formatLocation(this.package.location_id.name)}}</span>
          </div>

          <div class="d-flex justify-content-center align-items-center">
            <button *ngIf="s7variable" (click)="openRow(this.s7variable)" class="btn btn-primary text-white mx-2">Apri {{s7variable}}</button>
            <!-- <button (click)="getPack(this.package)"class="btn btn-primary text-white mx-2">Prendi</button> -->
            <button *ngIf="this.package" (click)="onPackageDone()" class="btn btn-secondary text-white mx-2">Fatto</button>
          </div>
        </div>


          <!-- <div class="badge bg-primary">100.122</div>
          <div class="badge">100.122</div> -->
        <!-- </div> -->
        </div>
      <br><br>
      <div class="card bg-dark">
        <div class="card-header">Stato Compattabile 
          <div class="badge bg-danger" *ngIf="variables.emergenza && variables.emergenza.value == 1">Emergenza</div>
          <div class="badge bg-success" *ngIf="variables.abilitato && variables.abilitato.value == 1">Abilitato</div>
          <div class="badge bg-success" *ngIf="variables.inmovimento && variables.inmovimento.value == 1">In movimento</div>
          
        </div>
        <div class="card-body">
          <div class="form-check form-switch my-3">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" (change)="this.openRow('F1')" [checked]="variables && variables.F1 && variables.F1.value == true">
            <label class="form-check-label" for="flexSwitchCheckDefault">Fila 1</label>
          </div>
          <div class="form-check form-switch my-3">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" (change)="this.openRow('F2')" [checked]="variables && variables.F2 && variables.F2.value == true">
            <label class="form-check-label" for="flexSwitchCheckChecked">Fila 2</label>
          </div>

          <div class="form-check form-switch my-3">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" (change)="this.openRow('F3')" [checked]="variables && variables.F2 && variables.F3.value == true">
            <label class="form-check-label" for="flexSwitchCheckChecked">Fila 3</label>
          </div>

        </div>

      </div>

      <h2> </h2>

      <!-- <ul class="list-group" >
        <li class="list-group-item" *ngFor="let p of pickings">
          {{p.name}}
        </li>
      </ul> -->
    </div>
  </div>

