import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { BarcodeReaderDynamsoftComponent } from 'src/app/barcode-reader-dynamsoft/barcode-reader-dynamsoft.component';
import { RestapiService } from 'src/app/shared/services/rest-api.service';
import { QuantityPickerResult, SaleQuantityPickerResult } from 'src/app/models/picking';


class Pack  {
  id : string
  pacchi_product: [number]
}

class ProductPack {
  product_id: [number] // ->product
  qty
  barcode
  display_name
}

class Product {
  confezione_vendita: [number] // -> package
}

class Package {
  qty: number
}

@Component({
  selector: 'app-sale-picking-modal',
  templateUrl: './picking-modal.component.html',
})
export class SalePickingModalComponent extends BarcodeReaderDynamsoftComponent implements OnInit {

  @Input() total:number = 0
  @Input() alreadyDone:number = 0
  @Input() orderLine
  pickingResult:SaleQuantityPickerResult = new SaleQuantityPickerResult()
  @Output() protected onSuccess: EventEmitter<SaleQuantityPickerResult> = new EventEmitter<SaleQuantityPickerResult>()
  

  error: string;
  


  constructor(
    private restapi: RestapiService,
    private elr:ElementRef) {
    super(elr);
  }

  ngOnInit() {
    // this.result = 
  }

  getTotal() {
    return this.total
  }

  getProgress() {
    var r = 0
    this.pickingResult.picks.forEach((p) => {
      // if (p.confezioni_qty_lorda)
      //   r = r + p.confezioni_qty_lorda
      // else
        r = r + p.qty
    }) 
    return r
  }

  scan() {
    this.error = null
    this.result = null
    super.scan()
  }


  done() {
    this.onSuccess.emit(this.pickingResult)
    this.result = null
    
  }

  undo() {
    this.result = null
    this.pickingResult.picks = this.pickingResult.picks.slice(0,-1)
  }

  
  async emitEvent(text: string) {

    
    
    this.error = null
    this.isKeyboard = false

    if (text == null) {
      this.onSuccess.emit(null)
      return
    }

    

    var p:ProductPack = await this.restapi.search(
      {
        table : "na.pacchi.product",
        criteria : [{column: "barcode",operator : "=", value:text}],
      }
    ).catch(() => {
      this.error = "Pacco non valido";
      return
    })

    

    p = await this.restapi.get(
      {
        table : "na.pacchi.product",
        id : p["na.pacchi.product"][0]
      }
    ).catch(() => {
      this.error = "Pacco non valido";
      return
    })

    this.result = null
      
    // console.log("!!!PKS", productPack)

    // var product:Product = await this.restapi.get(
    //   {
    //     table : "product.template",
    //     id : p.na_pacchi_product[0].toString()
    //   }
    // )
    

    // if (product.confezione_vendita) {
    
    //   var salePackage:Package = await this.restapi.get(
    //     {
    //       table : "product.packaging",
    //       id : product.confezione_vendita[0].toString()
    //     }
    //   )
  
    //   console.log("sale package", salePackage)
      
    //   if (productPack.qty <= (this.total - this.getProgress())) {    // if we have to load the whole productpack
    //     console.log("pp ", productPack.qty , salePackage.qty)
    //     this.pickingResult.picks.push({confezioni_qty_lorda: (productPack.qty / salePackage.qty), barcode: text})
    //   } else if (productPack.qty > (this.total - this.getProgress())) {   // if we have a part of the productpack
    //     console.log("pp2 ", productPack.qty , salePackage.qty)

    //     this.pickingResult.picks.push({confezioni_qty_lorda: ((this.total - this.getProgress()) / salePackage.qty), barcode: text})
    //   }

    // } else {

      var missing = this.total - this.getProgress() - this.alreadyDone

      // console.log("@@@@", p, productPack)
      if (p.qty <= missing) {    // if we have to load the whole productpack
        console.log("pp3 ", p.qty )
        this.pickingResult.picks.push({qty: p.qty , barcode: text})
      } else if (p.qty > missing) {   // if we have a part of the productpack
        console.log("pp4 ", p.qty )
        this.pickingResult.picks.push({qty: missing, barcode: text})
      }
    // }
    

    console.log("--_", this.pickingResult)
   
    this.result = null
    // we are done
    if (this.total == this.getProgress()) {
      this.onSuccess.emit(this.pickingResult)
    }
    
  }
}
