import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SaleOrderLine } from '../../models/sale-order-line.model';
import { StockMove } from 'src/app/models/stock-move';
import { MrpProduction } from 'src/app/models/mrp-production';
import { ProductSupplierinfo } from 'src/app/models/product.supplierinfo';
import { Product } from 'src/app/models/product.model';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-production-table',
  templateUrl: './production-table.component.html',
})
export class ProductionTableComponent implements OnInit {
  @Input() stockMoves: StockMove[] = []
  @Input() productsSupplierinfo: ProductSupplierinfo[] =[]
  @Input() production: MrpProduction
  @Output() updateOrderLineEmitter: EventEmitter<StockMoveEmitterFormat> = new EventEmitter()

  constructor(private odooEm:OdooEntityManager) {
  }

  ngOnInit() {
    
  }

  updateMove(field, move, value) {
    this.updateOrderLineEmitter.emit({"field":field, "move":move, value: value.target.value});
  }

  updateOrderLine(field?: string, orderLine?: SaleOrderLine) {
    // this.updateOrderLineEmitter.emit(null);
  }

  onEnter($event): void {
  }

  addNote(saleOrderLine: SaleOrderLine): void {
  }

  isVariantEditable(move:StockMove) {
    if (!move.product_id || !move.product_id.value)
      return false
    var a = move.product_id.value.product_template_attribute_value_ids.values.filter(x=> x.name == "su misura")
    return a.length == 1
  }


  async test() {

    // await this.odooEm.call(new MrpProduction(),"createFromProduct", 12).toPromise()

    // var ppp = 1
    // // this.odooEm.update(this.production, {product_id: ppp}).toPromise()
    // await (await this.odooEm.onChange2(this.production,this.production.id,{id: this.production.id,product_qty: 3})).pipe(first()).toPromise()
  }

  getAttribute2(move:StockMove, name:string) {
    console.log("MOVE ", move)
    if (!move.product_id.value || !move.product_id.value.product_template_attribute_value_ids)
    return ""
    
    
    if (move.product_id.value.product_template_attribute_value_ids.values) {
      console.log("getattr2 " ,move.product_id.value.product_template_attribute_value_ids.values)
      var f = move.product_id.value.product_template_attribute_value_ids.values.filter(value => {
        return value.attribute_id.name == name
      })
    }

    return (f && f.length > 0) ? f[0] : null
  }

  getAttribute(move:StockMove, name:string) {

    if (!move.product_id.value || !move.product_id.value.product_template_attribute_value_ids)
      return ""
    
    var f = move.product_id.value.product_template_attribute_value_ids.values.filter(value => {
      return value.attribute_id.name == name
    })

    return (f.length > 0) ? f[0].name : "2"
  }



  isDoubleUM(p:Product) {
    // console.log("KKKK", p.product_template_attribute_value_ids.values)
    // p.product_template_attribute_value_ids.values.filter(x => x.)
    return true
  }


}



export interface StockMoveEmitterFormat {
  field: string;
  move: StockMove;
  value:any
}
