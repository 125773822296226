<app-navbar>
    <a class="navbar-brand">
        Home
    </a>
    <button class="btn btn-link text-white d-block ms-auto"  routerLink="/status"  type="button" >
        <i class="fa-sharp fa-solid fa-shield-check"></i>
    </button>
    <app-user-info class="me-2"></app-user-info>
  </app-navbar>



<div class="p-0" >

    <div class="list-group">
        <a routerLink="/contact" class="list-group-item list-group-item-action rounded-0">
            <i class="me-2 fas fa-fw fa-phone  text-muted"></i>     
            <b>Clienti e Fornitori</b>
        </a>
        <a routerLink="/leads" class="list-group-item list-group-item-action rounded-0">
            <i class="me-2 text-muted fa-fw fas fa-filter"></i>      
            <b>Fascicoli</b>
        </a>
    </div>  
    <br>  


    <label class="list-label">Acquisti</label>

    <div class="list-group">
        <a
            href="https://o2.galimberti.eu/web#cids=1&menu_id=256&action=397&model=product.template&view_type=list" 
            class="list-group-item list-group-item-action rounded-0" 
            target="_blank">
                <i class="fab fa-trello text-muted fa-fw me-2 "></i>
            Prodotti
        </a>
        <a 
            href="https://o2.galimberti.eu/web#cids=1&menu_id=256&action=429&model=stock.warehouse.orderpoint&view_type=list" 
            class="list-group-item list-group-item-action rounded-0"
            target="_blank">
                <i class="fab fa-trello text-muted fa-fw me-2 "></i>
            Rifornimenti
        </a>
    </div>

    <br><br>
    <label class="list-label">Magazzino</label>
    <div class="list-group">
        
        <a routerLink="/pickings/internal" class="list-group-item list-group-item-action rounded-0">
            <i class="fa fa-forklift text-muted fa-fw me-2 "></i>
            Trasferimenti
        </a>

        
        <a routerLink="/search" class="list-group-item list-group-item-action rounded-0">
            <i class="fas fa-barcode-read me-2 text-muted"></i>
            Cerca
        </a>
    </div>

    <br><br>
    <label class="list-label">Veicoli</label>
    <div class="list-group">
        <a routerLink="/forklift/5" class="list-group-item list-group-item-action rounded-0">
            <i class="fa fa-forklift text-muted me-2 "></i>
            Muletto 5
        </a>
        <a routerLink="/forklift/6" class="list-group-item list-group-item-action rounded-0">
            <i class="fa fa-forklift text-muted me-2 "></i>
            Muletto 6
        </a>
    </div>

</div>


<br>
<div class="text-center"><small class="text-muted ">Aggiornamento 5.2.23</small></div>
<br>
