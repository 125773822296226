import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestapiService, QueryPostOptions } from 'src/app/shared/services/rest-api.service';
import { SaleQuantityPickerResult, QuantityPickerResult } from 'src/app/models/picking';
import { OrderLine } from 'src/app/models/order';

@Component({
  selector: 'app-package-detail',
  templateUrl: './package-detail.component.html',
})


export class PackageDetailComponent implements OnInit {
  id: any;
  loading: boolean;
  picking: any;
  sale: any;
  loadables: any[];
  selectedOrderLine: any;

  LOCATION_ID = 12
  LOCATION_DEST_ID = 9
  showBarcodeProductScanner: boolean;
  showQuantityPicker: boolean = false;
  showLineScanner: boolean;
  showMoveEditor: any;
  selectedMoveLine: any;
  selectedRelation: any;
  showQuantityEditor: boolean;
  orderId: any;

  constructor(
    private route: ActivatedRoute,
    public restapi: RestapiService,
    public router: Router,
    private changeDet: ChangeDetectorRef
  ) {
  }


  ngOnInit() {

    this.route.params.subscribe(params => {
      this.orderId = params["id"]
      this.id = params['idpackage'];
      if (this.id != 0) {
        this.loading = true
        this.loadMoves()
      }
    })
  }


  getMoves(orderLine) {
    let r = []
    this.picking._move_lines.forEach(move => {
      if (orderLine.move_ids && orderLine.move_ids.indexOf(move.id) > -1 && move.qty != 0) {
        r.push(move)
      }
    })
    return r
  }

  async loadMoves() {

    await this.restapi.get({
      table: "sale.order",
      id: String(this.orderId),
      resolve: [
        { key: "order_line", table: "sale.order.line" },
        { key: "partner_id", table: "res.partner" },
      ]
    }).then((res) => {
      this.sale = res
      this.loadables = []
      this.sale._order_line.forEach(line => {
          this.loadables.push(line)
      });
      
       this.sale._order_line.forEach(order_line => {
         console.log("load product ", )
         
        this.restapi.get({
          table: "product.product",
          id: String(order_line.product_id[0]),
          resolve: [ ]
          }).then((res) => {
            order_line['_product'] = res;
          });
      });
    })


    var res = await this.restapi.get({
      table: "stock.picking",
      id: String(this.id),
      resolve: [
        { key: "move_lines", table: "stock.move" },
      ]
    })
    
    // deal every not done as draft BAD
    if (res.state == 'waiting' || res.state == 'confirmed' || res.state == 'assigned')
      res.state = 'draft'

    this.picking = res

    console.log("PICKING UPDATE MOVES ", this.picking)
    this.loading = false

  }

  async setState(state) {

    if (state == "done") {
      this.close()
      return
    }
    
    return await this.restapi.put({
        table: "stock.picking",
        id: this.picking.id,
        json_fields: JSON.stringify({state: state})
      }).then((w) => {
        this.picking.state = state
      })
  }

 

  filterLoadables() {
    if (!this.sale)
      return []
    // return this.sale._order_line.filter((x)=>  this.getMissing(x) >0 )
    return this.sale._order_line
  }

  async load(orderLine) {
    this.showQuantityEditor = true

    var selectedProduct = orderLine._product
    if (orderLine.flag_pacco_only){
      if (!selectedProduct.barcode){
        this.selectedOrderLine = null
        alert("Il prodotto non ha un barcode configurato, impossibile caricare");
        return;
      }
    }
    
    this.selectedOrderLine = orderLine
    this.showQuantityPicker = true
  }

  getStateLabel() {
    if (!this.picking)
      return 
   
    var m = {
      draft: "In produzione",
      package_production: "In produzione",
      done: "Pronto",
      package_ready_to_ship: "Da spedire"
    }

    return m[this.picking.state]
  }

  onPickings(r:SaleQuantityPickerResult) {
    console.log("PICKINGS ", r)
    if (r == null) {
      this.showQuantityPicker = false
      this.selectedOrderLine = null
      return
    }

    console.log("PARAMS ", "picking_id=" + this.picking.id + "&relations=" + JSON.stringify(r))

    this.restapi.call({
      id: this.selectedOrderLine.id,
      table : "sale.order.line",
      method: "create_move_line",
      params: "picking_id=" + this.picking.id + "&relations=" + JSON.stringify(r)
    }).then(async (r:any) => {
      this.showQuantityPicker = false
      this.selectedOrderLine = null
    
      if (r.odoo_error)
        alert(r.message)
      
      this.loadMoves()
    })
  }

  onAddProduct() {
    this.showBarcodeProductScanner = true
  }

  public onProductScan(barcode) {
    this.showBarcodeProductScanner = false
    if (!barcode)
      return 
   
    this.sale._order_line.forEach(line => {
      if (line.barcode == barcode) {
        this.selectedOrderLine = line
        this.onBarcodeDone(barcode)
      }
    });
  }

  onMoveEdit(move, relation) {
    // console.log("RELATION ", move)
    // VERY BAD NAMING !!!!!!!!
    this.showMoveEditor = true
    // this.selectedRelation = relation
    this.selectedMoveLine = move

    this.sale._order_line.forEach((ol) => {
      if (ol.id == this.selectedMoveLine.sale_line_id[0]) {
        this.selectedOrderLine = ol
      }
    })
    console.log("edit", this.selectedMoveLine,this.selectedOrderLine)
  }


  onMoveEditDone(q:QuantityPickerResult) {
    this.showMoveEditor = false
    if (q == null)
      return

    this.loading = true

    // we can load units or packages
    var mode  = q.unit ? "qty" : "confezioni_qty_lorda"
    var num = (mode == "qty") ? q.unit : q.packages
    num = num ? num : 0
    
    if (num < 1) { // delete
      // this.restapi.delete({
      //   table : "na.move.righe.pacco_from_rel",
      //   ids: [this.selectedRelation.id]
      // }).then(() => {
      //   const index = this.selectedMoveLine._relations.indexOf(this.selectedMoveLine);
      //   if (index > -1) 
      //     this.selectedMoveLine._relations.splice(index, 1);
      //   this.loading = false
      //   this.loadMoves()
      // })
    } else { // update
      var fields = {}
      fields[mode] = Number(num)
      this.restapi.put({
        table: "stock.move",
        id: this.selectedMoveLine.id,
        json_fields: JSON.stringify(fields)
      }).then((r) => {
        this.loading = false
        // update
        this.selectedMoveLine.confezioni_qty_lorda = q.packages
        this.selectedMoveLine.qty = q.unit
      }).catch((e) => {
        this.loading = false
        alert(e)
      })
    }
  }

   
  async onQuantityDone(qty) {
    this.showQuantityPicker = false
    if (qty != null && qty > 0) {
      this.restapi.call({
        id: this.selectedOrderLine.id,
        table : "sale.order.line",
        method: "create_move_line",
        params: "picking_id=" + this.picking.id + "&qty=" + qty 
      }).then(async (res:any) => {
        if (res.odoo_error) 
          alert(res.message)
          
        console.log("QUANTITY DONE", this.picking)
        await this.loadMoves()
        this.changeDet.detectChanges()
        this.loading = false
      })
    }
  }

   onBarcodeDone(barcode) {
    if (!barcode) {
      this.selectedOrderLine = null
      return
    }

    // this.loading = true
    var orderLine = this.selectedOrderLine
    this.showLineScanner = false
    this.showQuantityPicker = true
    this.changeDet.detectChanges()
    
    return true


    if (orderLine._product.barcode != barcode) {
      alert("Il barcode non coincide con il pacco predisposto per il prodotto ")
      return
    }

    console.log("PICKIGN ID ", this.picking.id)
    this.restapi.call({
      id: orderLine.id,
      table : "sale.order.line",
      method: "create_move_line",
      params: "picking_id=" + this.picking.id + "&qty=1" 
    }).then((res) => {
      this.loadMoves()
      this.loading = false
    })

    return

   

  }

  deleteMove(id) {
    this.restapi.delete({
        table : "stock.move",
        ids: [id]
      }).then(() => {
        this.loadMoves()
      })
  }


  public async updateProductLine(move, relation) {
    if (relation.confezioni_qty_lorda < 1) {
      this.restapi.delete({
        table : "na.move.righe.pacco_from_rel",
        ids: [relation.id]
      }).then(() => {
        const index = move._relations.indexOf(relation);
        if (index > -1) {
          move._relations.splice(index, 1);
        }
      })
    } else {
      this.restapi.put({
        table: "na.move.righe.pacco_from_rel",
        id: relation.id,
        json_fields: JSON.stringify({ qty: relation.confezioni_qty_lorda })
      }).then(r => {
        // if (r.onAddProductoo)
      })
    }
  }

  getPackage(l) {
    if (!l) return
    if (l.product_packaging)
      return l.product_packaging[1]
    if (l.sale_product_packaging)
      return l.sale_product_packaging[1]
  }

  getAlreadyDone(line:OrderLine):number {
    var x = 0;
    this.getMoves(line).forEach(m => {
      x = x + m.qty
    })
    return x
  }


  getMissingQty(loadable:OrderLine) {
    var m = Number(loadable.qty_lorda - loadable.qty_delivered)
    this.picking._move_lines.forEach(move => {
      // if (move.product_id[0] == loadable.product_id[0] && move._relations.length > 0) {
      //   move._relations.forEach(rel => {
      //     m =  m - Number(rel.qty)
      //   })
      // }
    })
    return m
  }

  getMissing(loadable:OrderLine) {
    console.log()
    var m = loadable.confezioni_qty_lorda - loadable.confezioni_qty_lorda_delivered
    this.picking._move_lines.forEach(move => {
      // if (move.product_id[0] == loadable.product_id[0] && move._relations.length > 0) {
      //   move._relations.forEach(rel => {

      //     m =  m - Number(rel.confezioni_qty_lorda)
      //   })
      // }
    })
    return m
  }

  close() {
    this.loading = true

   

      this.restapi.call({
        table: 'stock.picking',
        id: this.picking.id,
        method: 'webapp_validate'
      }).then((x:any) => {
        this.loading = false
        if (x.odoo_error) {
          alert(x.message)
          return
        }
        
        this.picking.state = "done"
      })
  }


}
