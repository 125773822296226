
<div>
    
    <div class="d-flex flex-column result justify-content-end" *ngIf="result">

        <div class="code p-3 bg-success text-white d-flex flex-row" (click)="selectCode(result)">
            <div class="" >Codice {{result}}  </div>
            <!-- <i class="fa fa-close fa-3x"></i> -->
        </div>

        <div *ngIf="needToCreate" class="needToCreate p-3 d-flex justify-content-between mt-3 bg-dark" (click)="create()">
            Crea pacco
            <!-- <button class="btn btn-outline-primary btn-lg text-white" (click)="create()">Crea</button> -->
        </div>

        <div *ngIf="needToCreate" class="needToCreate p-3 d-flex justify-content-between mt-3 bg-dark" (click)="result = null; needToCreate = null">
            Scansione un altro codice
            <!-- <button class="btn btn-outline-primary btn-lg text-white" (click)="create()">Crea</button> -->
        </div>

    </div>

    <video class="dbrScanner-video"></video>


    <canvas></canvas>
</div>
