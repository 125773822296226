import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ContactSearchComponent } from './contact/contact-search.component';
import { ContactDetailComponent } from './contact/contact-detail/contact-detail.component';
import { EditContactDetailComponent } from './contact/contact-edit/edit-contact-detail.component';
import { RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TreeModule } from 'angular-tree-component';
import { LoaderComponent } from './loader/loader.component';
import { ClipboardModule } from 'ngx-clipboard';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LoadSearchComponent } from './purchase-picking/load-search.component';
// import { LoadDetailComponent } from './purchase-picking/load-detail/load-detail.component';
// import { LoadPickingComponent } from './purchase-picking/load-package-detail/load-package-detail.component';
// import { PickingDetailComponent } from './sale-picking/picking-detail/picking-detail.component';

import { PickingSearchComponent } from './picking-search/picking-search.component';
import { DealsDashboardComponent } from './deals/deals-dashboard/deals-dashboard.component';
import { DealDetailComponent, SortByPipe } from './deals/deal-detail/deal-detail.component';
import { PackageDetailComponent } from './sale-picking/package-detail/package-detail.component';
import { NgbDropdownModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { SearchComponent } from './search/search.component';
import { SearchDetailComponent } from './search/search-detail/search-detail.component';
import { BarcodeReaderDynamsoftComponent } from './barcode-reader-dynamsoft/barcode-reader-dynamsoft.component';
import {AUTH_SERVICE, AuthModule, PROTECTED_FALLBACK_PAGE_URI, PUBLIC_FALLBACK_PAGE_URI} from 'ngx-auth';
import {AuthenticationService} from './shared/services/authentication.service';
import {TokenStorage} from './shared/services/token-storage.service';
import { CallbackComponent } from './callback/callback.component';
import { InputwrapperDirective } from './shared/directives/inputwrapper.directive';
import { AutoLengthDirective } from './shared/directives/auto-length.directive';
import { OrderNavComponent } from './shared/order-nav/order-nav.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { SalePickingModalComponent } from './sale-picking/picking-modal/picking-modal.component';
import { UserInfoComponent } from './shared/user-info/user-info/user-info.component';
import { SearchPackageComponent } from './search/search-package/search-package.component';
import { SearchResultsComponent } from './search/search-results/search-results.component';


import {
  NgGapiClientConfig,
} from 'ng-gapi';
import { TrelloInsertComponent } from './deals/trello-insert/trello-insert.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { InputNumberComponent } from './shared/input-number/input-number.component';
import { ConnectToComponent } from './connect-to/connect-to.component';
import { GaliNumberDirective } from './shared/directives/gali-number.directive';
import { SaleOrderDetailComponent } from './components/sale-order-detail/sale-order-detail.component';
import { PurchaseOrderDetailComponent } from './components/purchase-order-detail/purchase-order-detail.component';
import { OrderDetailComponent } from './components/order-detail/order-detail.component';
import { OrderHeaderComponent } from './components/order-header/order-header.component';
import { PurchaseOrderTableComponent } from './components/purchase-order-table/purchase-order-table.component';
import { OrderInventoryComponent } from './components/order-inventory/order-inventory.component';
import { OutsideClickDirective } from './shared/directives/outside-click.directive';
// import { InfoSideOrderComponent } from './components/info-side-order/info-side-order.component';
import { ToastsContainerComponent } from './shared/components/toasts-container/toasts-container.component';
// import { AddEntityDropdownComponent } from './components/add-entity-dropdown/add-entity-dropdown.component';
import { ContactPickerComponent } from './contact/contact-picker/contact-picker.component';
import { ContactFidoComponent } from './contact/contact-fido/contact-fido.component';
import { ProductionDetailComponent } from './production/production-detail/production-detail.component';
// import { PickingMoveEditorComponent } from './picking/picking-move-editor/picking-move-editor.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ContactNewComponent } from './contact/contact-new/contact-new.component';
import { TrelloInsertPosComponent } from './deals/trello-insert-pos/trello-insert-pos.component';
import { GmailImportComponent } from './gmail/gmail-import/gmail-import.component';
import { AuthInterceptor } from './shared/services/auth-interceptor.service';
import { ProductionTableComponent } from './components/production-table/production-table.component';
import { InputDoubleUmComponent } from './components/input-double-um/input-double-um.component';
import { DealCalendarListComponent } from './deals/deal-calendar-list/deal-calendar-list.component';
import { BudgetComponent } from './deals/budget/budget.component';
// import { ProductionHeadingComponent } from './production/production-heading/production-heading.component';
import { PickingEditorPage } from './picking/picking-editor-page/picking-editor-page.component';
import { SaleOrderEditorComponent } from './sale-order/sale-order-editor/sale-order-editor.component';
import { ScannerComponent } from './barcode-scanner/scanner/scanner.component';
import { PickingPackageLocationPickerComponent } from './picking/picking-package-location-picker/picking-package-location-picker.component';
import { SaleOrderTableComponent } from './components/sale-order-table/sale-order-table.component';
import { PickingModalComponent } from './purchase-picking/picking-modal/picking-modal.component';
import { InfoSideOrderComponent } from './components/info-side-order/info-side-order.component';
import { BadgeKioskComponent } from './badge-kiosk/badge-kiosk.component';
import { TimeOffComponent } from './time-off/time-off.component';
import { ForkliftPageComponent } from './forklift/forklift-page/forklift-page.component';
import { PickingEditorComponent } from './picking/picking-editor/picking-editor.component';
import { StatusComponent } from './status/status.component';
import { KeyboardScannerComponent } from './shared/components/keyboard-scanner/keyboard-scanner.component';
import { PurchasePrintComponent } from './purchase-print/purchase-print.component';
// import { AuthInterceptor } from 'ngx-auth/auth.interceptor';


const gapiClientConfig: NgGapiClientConfig = {
  client_id: '470272109138-p98fm5g5qs8i21o01rir4tbteann1a1i.apps.googleusercontent.com',
  discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
  scope: [
      'https://www.googleapis.com/auth/drive'
  ].join(' ')
};


registerLocaleData(localeIt, 'it');

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ContactSearchComponent,
    ContactDetailComponent,
    NotFoundComponent,
    EditContactDetailComponent,
    LoaderComponent,
    LoadSearchComponent,
    PickingSearchComponent,
    DealsDashboardComponent,
    DealDetailComponent,
    PackageDetailComponent,
    SearchComponent,
    SearchDetailComponent,
    BarcodeReaderDynamsoftComponent,
    CallbackComponent,
    InputwrapperDirective,
    AutoLengthDirective,
    OrderNavComponent,
    SaleOrderTableComponent,
    OutsideClickDirective,
    PurchaseOrderTableComponent,
    UserInfoComponent,
    SearchPackageComponent,
    SearchResultsComponent,
    InputNumberComponent,
    TrelloInsertComponent,
    GaliNumberDirective,
    SaleOrderDetailComponent,
    SalePickingModalComponent,
    PurchaseOrderDetailComponent,
    OrderDetailComponent,
    OrderHeaderComponent,
    PurchaseOrderTableComponent,
    OrderInventoryComponent,
    ProductionDetailComponent,
    InfoSideOrderComponent,
    PickingModalComponent,
    TrelloInsertComponent,
    InputNumberComponent,
    ConnectToComponent,
    ToastsContainerComponent,
    ContactPickerComponent,
    ContactFidoComponent,
    SortByPipe,
    NavbarComponent,
    ContactNewComponent,
    TrelloInsertPosComponent,
    GmailImportComponent,
    ProductionTableComponent,
    InputDoubleUmComponent,
    DealCalendarListComponent,
    BudgetComponent,
    PickingEditorPage,
    SaleOrderEditorComponent,
    ScannerComponent,
    PickingPackageLocationPickerComponent,
    BadgeKioskComponent,
    TimeOffComponent,
    ForkliftPageComponent,
    PickingEditorComponent,
    StatusComponent,
    KeyboardScannerComponent,
    PurchasePrintComponent
  ],
  imports: [
    GooglePlaceModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    ClipboardModule,
    RouterModule.forRoot([], { relativeLinkResolution: 'legacy' }),
    TreeModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgbDropdownModule,
    NgbDatepickerModule,
   // ModalModule,
    AuthModule,
    NgbModule,
    // GoogleApiModule.forRoot({
    //   provide: NG_GAPI_CONFIG,
    //   useValue: gapiClientConfig
    // })
  ],

  providers: [

    //   {
    //     provide: HTTP_INTERCEPTORS,
    //     useClass: AuthInterceptor,
    //     multi: true
    // },
    {
      provide: LOCALE_ID, useValue: 'it-IT',
    },
    { provide: PROTECTED_FALLBACK_PAGE_URI, useValue: '/' },
    { provide: PUBLIC_FALLBACK_PAGE_URI, useValue: '/login' },
    { provide: AUTH_SERVICE, useClass: AuthenticationService },
    TokenStorage,
    AuthenticationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    // library.add( fas , far, fab);
  }
}
