import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';
import { Package } from 'src/app/models/package';
import { Product } from 'src/app/models/product.model';
import { PurchaseOrder } from 'src/app/models/purchase-order.model';
import { StockMove } from 'src/app/models/stock-move';
import { StockMoveLine } from 'src/app/models/stock-move-line';
import { StockPicking } from 'src/app/models/stock-picking';
import { StockProductionLot } from 'src/app/models/stock-production-lot.model';
import { StockQuant } from 'src/app/models/stock-quant';
import { StockQuantPackage } from 'src/app/models/stock-quant-package';
import { StockBackorderConfirmation, StockBackorderConfirmationLine } from 'src/app/models/stock.backorder.confirmation.model';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';

@Component({
  selector: 'app-picking-editor-page',
  templateUrl: './picking-editor-page.component.html',
  styleUrls: ['./picking-editor-page.component.scss']
})
export class PickingEditorPage implements OnInit {
  id: any;
  loading: boolean;
  picking: StockPicking;
  @ViewChild('modalorig', {static:true}) templateRefOrig: NgbModalRef;
  @ViewChild('modaldest', {static:true}) templateRefDest: NgbModalRef;

  @ViewChild('notifyOwnerModal', {static:true}) notifyOwnerModal: NgbModalRef;

  packages: StockQuantPackage[];
  activeMoveLine: StockMoveLine;
  quants: StockQuant[] = []
  scanning: boolean;
  scanningPackageDest: StockMoveLine;
  pickOrigin: StockMoveLine;
  pickDestination: StockMoveLine;
  keybuffer: string = ""

  constructor(
    private odooEM:OdooEntityManager,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) { }

  async ngOnInit() {
    this.route.params.subscribe(async params => {
      this.id = params['id'];
      this.loading = true;
      await this.load()
      this.loading = false
    }) 
  }


  async onScan(keybuffer: string) {
    console.log("on scan", keybuffer)
    var ps = await this.odooEM.search<Product>(new Product(), [['barcode', '=', keybuffer]]).toPromise()
    if (!ps.length) return

    var ml:StockMoveLine = null
    var a = this.picking.move_ids_without_package.values.map(m => m.move_line_ids.values)
    var flat = [].concat(...a)

    flat.forEach(l => {
      if (l.product_id.id == ps[0].id)
        ml = l
    })
    
    console.log("on scan ml", keybuffer)
    
    if (ml != null) {
      ml._checked = true
      ml._justChanged = true
    
      setTimeout(() =>  ml._justChanged = false,400)

      await this.odooEM.update(ml, {
        qty_done : ml.qty_done + 1
      }).toPromise()
      ml.qty_done = ml.qty_done + 1
    }
  }

 

  // async handleHeaderEvent(e) {
  //   this.loading = true
  //   if (e.functionName == 'confirm') {

  //     // check qty
  //     var qty = false
  //     this.picking.move_line_ids_without_package.values.forEach(async ml => {
  //       if (ml.qty_done != 0)
  //         qty = true
  //     })

  //     // associate lot name
  //     this.picking.move_line_ids_without_package.values.forEach(async ml => {
  //       console.log("MOVE LINE ",ml )
  //       await this.odooEM.update(ml, {
  //           'lot_name' : this.picking.origin + " - " + this.picking.name,
  //         }).toPromise()
  //     })

  //     await this.odooEM.call(new StockPicking(), "button_validate", this.picking.id).toPromise()
  //     await this.load()
  //     this.loading = false
  //   }
  // }
  async tryToValidate() {
    var r = await this.odooEM.call(new StockPicking(), "button_validate", this.picking.id).toPromise()
    if (r.view_mode) {
      if (confirm("Ordine arrivato parzialmente, creo una nuova ricezione per il rimanente ?")) {
        await this.closeWithBackorder()
      }
    }
    this.load()

  }

  async load() {
    var ps = await this.odooEM.search<StockPicking>(new StockPicking(),[["id","=",this.id]]).toPromise()
    if (ps.length == 0)
      throw "Error"

    // await this.odooEM.resolve(ps[0].move_line_ids_without_package).toPromise()
    
    await this.odooEM.resolve(ps[0].move_ids_without_package).toPromise()

    console.log("MOVES ", ps[0])
    await this.odooEM.resolveArray(new StockMoveLine(),ps[0].move_ids_without_package.values,"move_line_ids").toPromise()
    
    // await this.odooEM.resolveArrayOfSingle(new StockMove(), ps[0].move_line_ids_without_package.values, "move_id").toPromise()
    // var moves = []
    // ps[0].move_line_ids_without_package.values.forEach(ml => {
    //   moves.push(ml)
    // })
    await this.odooEM.resolveArrayOfSingle(new Product(), ps[0].move_ids_without_package.values, "product_id").toPromise()

    // await this.odooEM.resolveArray(new StockMove(),moves,"move_orig_ids").toPromise()
    // await this.odooEM.resolveArray(new StockMove(),moves,"move_dest_ids").toPromise()
    this.picking = ps[0];
  }

  async updateLine(fieldName:string,line:StockMoveLine) {
    this.loading = true
    var n = {}
    n[fieldName] = line[fieldName]
    var lll = await this.odooEM.update(line, n).toPromise()
    this.loading = false
  }


  onCheck(e:any, ml:StockMoveLine) {
    console.log("ee", e)

  }



  async completeLine(ml:StockMoveLine) {
    // check for multiple selection
    this.loading = true
    var a = this.picking.move_ids_without_package.values.map(m => m.move_line_ids.values)
    var flat = [].concat(...a).filter(l => l._checked == true)

    // if not checkboxs, use just the pressed one
    if (flat.length == 0 ) 
      flat.push(ml)
    
    // todo update multi
    for (var f of flat) {

      await this.odooEM.update(f, {
        qty_done : f.product_uom_qty
      }).toPromise()

      f.qty_done = f.product_uom_qty 

      console.log("FF", f)
    }
    
    // await this.load()
    this.loading = false
  }

  

  async uncompleteLine(ml:StockMoveLine) {
    // check for multiple selection
    
    var a = this.picking.move_ids_without_package.values.map(m => m.move_line_ids.values)
    var flat = [].concat(...a).filter(l => l._checked == true)
    if (flat.length == 0 ) 
      flat.push(ml)

    // todo update multi
    for (var f of flat) {
      await this.odooEM.update(f, {
        qty_done : 0
      }).toPromise()
    }
    
    this.load()
  }

  // scanPackage(ml:StockMoveLine) {
  //   this.scanning = true
  // }

  async pickPackage(ml:StockMoveLine) {
    this.pickOrigin = ml
    
    return
    this.loading = true
    // use subselecor to make 1 query
    this.activeMoveLine = ml
    var q = await this.odooEM.search<StockQuant>(new StockQuant(), [["product_id", "=", ml.product_id.id]]).toPromise()
    this.packages = await this.odooEM.search<StockQuantPackage>(new StockQuantPackage(), [["quant_ids", "in", q.map(x => x.id)]]).toPromise()
    this.quants = await this.odooEM.search<StockQuant>(new StockQuant(), 
      [
        ["product_id", "=", ml.product_id.id],
        ["on_hand", "=", true],
      ]).toPromise()

    this.modalService.open(this.templateRefOrig)
    this.loading = false
  }

  async onPackageOrig(p:Package) {
    var x = this.pickOrigin.id
    this.pickOrigin = null
    if (!p) return

    var a = this.picking.move_ids_without_package.values.map(m => m.move_line_ids.values)
    var flat = [].concat(...a).filter(l => l._checked == true)
    if (flat.length == 0 ) 
      flat.push(new StockMoveLine(x))

    // todo update multi
    for (var f of flat) {
      await this.odooEM.update(new StockMoveLine(f.id), {
        'package_id': p.id
      }).toPromise()
    }

    await this.load()
    this.loading = false
  }


  async onQuantOrig(q:StockQuant) {
    await this.odooEM.update(this.activeMoveLine, {
      'package_id': q.package_id.id ? q.package_id.id : null,
      'location_id' : q.location_id.id
    }).toPromise()

    await this.modalService.dismissAll()
    this.load()
  }

  async pickPackageDestination(ml:StockMoveLine) {
    this.pickDestination = ml
  }

  async onPackageDest(p:StockQuantPackage) {
    var x = this.pickDestination.id
    this.pickDestination = null
    if (!p) return

    var a = this.picking.move_ids_without_package.values.map(m => m.move_line_ids.values)
    var flat = [].concat(...a).filter(l => l._checked == true)
    if (flat.length == 0 ) 
      flat.push(new StockMoveLine(x))

    // todo update multi
    for (var f of flat) {
      await this.odooEM.update(new StockMoveLine(f.id), {
        'result_package_id': p.id
      }).toPromise()
    }

    await this.load()
    this.loading = false
    
    // // this.scanning = null
    // // this.scanningPackageDest = null
    // var d = this.pickDestination
    // this.pickDestination = null

    // if (!p) 
    //   return

    // this.loading  = true

   
    //   await this.odooEM.update(d, {
    //     result_package_id : p.id
    //   }).toPromise()
    // this.load()
    // this.loading = false
  }
 // async pickPackageDest(ml:StockMoveLine) {
  //   this.loading = true
  //   // use subselecor to make 1 query
  //   this.activeMoveLine = ml
  //   this.quants = await this.odooEM.search<StockQuant>(new StockQuant(), 
  //     [
  //       ["product_id", "=", ml.product_id.id],
  //       ["on_hand", "=", true],
  //     ]).toPromise()
  //   this.packages = await this.odooEM.search<StockQuantPackage>(new StockQuantPackage(), [["quant_ids", "in", this.quants.map(x => x.id)]]).toPromise()
  //   this.modalService.open(this.templateRefDest)
  //   this.loading = false
  // }

  // async onCodeDest(c:StockQuantPackage) {
  //   // var ps = await this.odooEM.search<StockQuantPackage>(new StockQuantPackage(),[["name", "=", c.name]]).toPromise()
  //   // var p:StockQuantPackage
  //   if (c! && confirm("Pacco non trovato, vuoi crearlo ?")) {
  //     c = await this.odooEM.create<StockQuantPackage>(new StockQuantPackage(), {
  //       // 'location_id': this.scanningPackageDest.location_dest_id.id,
  //       'name': c.name
  //     }).toPromise()
  //   } 
    
  //   // else if (ps.length > 0) {
  //   //   p = ps[0]
  //   // } else 
  //   //   return 
    

  //   this.onPackageDest(c, this.scanningPackageDest)
  //   this.loading = false

  // }



  async closeWithBackorder() {

      var o = {
        pick_ids : [this.picking.id]
      }
      var bo = await firstValueFrom(this.odooEM.create<StockBackorderConfirmation>(new StockBackorderConfirmation(), o))

      var ol = {
        to_backorder: true,
        backorder_confirmation_id: bo.id,
        picking_id: this.picking.id
      }
      await firstValueFrom(this.odooEM.create(new StockBackorderConfirmationLine(),ol))

      await firstValueFrom(this.odooEM.call(new StockBackorderConfirmation(), 'process', [bo.id], {
        'button_validate_picking_ids': [this.picking.id]
      }))

      this.load()

    }
    // let b = {
    //   "picking_type_id" : this.picking.picking_type_id.id,
    //   "backorder_id": this.picking.id,
    //   "location_id": this.picking.location_id.id,
    //   "location_dest_id": this.picking.location_dest_id.id,
    //   "group_id": this.picking.group_id.id,
    //   "origin": this.picking.origin
    // }

    // var bo = await firstValueFrom(this.odooEM.create<StockPicking>(new StockPicking(), b))

    // var lines = []
    // this.picking.move_ids_without_package.values.forEach(ml => {
    //   let l = { 
    //     'name': ml.name,
    //     'product_uom': ml.product_uom.id,
    //     // "qty_done": 0,
    //     // 'product_uom_qty': Number(ml.product_uom_qty) - Number(ml.qty_done),
    //     'picking_id' : bo.id,
    //     'product_id' : ml.product_id.id,
    //     'location_id' : ml.location_id.id,
    //     'location_dest_id' : ml.location_dest_id.id,
    //     // 'product_uom_id' : ml.product_id.value.uom_po_id.id
    // //   lineCloned['product_uom_qty'] = Number( line.product_uom_qty  ) - Number( line.qty_done )
    // //   lineCloned['location_id'] = line.location_id.id
    // //   lineCloned['lot_id'] = line.lot_id.id
    // //   lineCloned['move_id'] = line.move_id.id
    // //   lineCloned['package_id'] = line.package_id.id
    // //   lineCloned['product_id'] = line.product_id.id
    // //   lineCloned['result_package_id'] = line.result_package_id.id
    // //   lineCloned['location_dest_id'] = line.location_dest_id.id
    //   }
    //   lines.push(l)
    // })
    
    // var ml = await firstValueFrom(this.odooEM.create<StockMove>(new StockMove(), lines))
    // console.log(ml)



    // let p = new StockPicking()
    // let b = {
        
    // }


    // var pickingCloned = Object.assign({}, this.picking) as {}
    // delete pickingCloned['ODOO_MODEL']
    // pickingCloned['name'] = '/'
    // pickingCloned['move_lines'] = []
    // pickingCloned['move_line_ids_without_package'] = []
    // pickingCloned['backorder_id'] = this.picking.id
    // pickingCloned['location_dest_id'] = this.picking.location_dest_id.id
    // pickingCloned['location_id'] = this.picking.location_id.id
    // pickingCloned['picking_type_id'] = this.picking.picking_type_id.id
    // var backorder_picking = await firstValueFrom(this.odooEM.create<StockPicking>(new StockPicking(), pickingCloned))
    
    // if(backorder_picking)
    // this.backOrderId = backorder_picking.id


    // if(this.picking.move_line_ids_without_package.values)
    // for await (const line of this.picking.move_line_ids_without_package.values) {

    //   await firstValueFrom(this.odooEM.resolveSingle<ProductProduct>(new ProductProduct(), line.product_id ))
    //   var lineCloned = Object.assign({}, line ) as {}
    //   delete lineCloned['ODOO_MODEL']
    //   lineCloned['qty_done'] = 0
    //   lineCloned['picking_id'] = backorder_picking?.id
    //   lineCloned['product_uom_qty'] = Number( line.product_uom_qty  ) - Number( line.qty_done )
    //   lineCloned['location_id'] = line.location_id.id
    //   lineCloned['lot_id'] = line.lot_id.id
    //   lineCloned['move_id'] = line.move_id.id
    //   lineCloned['package_id'] = line.package_id.id
    //   lineCloned['product_id'] = line.product_id.id
    //   lineCloned['result_package_id'] = line.result_package_id.id
    //   lineCloned['product_uom_id'] = line.product_id.value.uom_po_id.id
    //   lineCloned['location_dest_id'] = line.location_dest_id.id

    //   var l =  await firstValueFrom(this.odooEM.create<StockMoveLine>(new StockMoveLine(), lineCloned))
  
    //   await this.odooEM.update(line, {
    //     product_uom_qty: line.qty_done
    //   }).toPromise()
    // }  

  // }
}
