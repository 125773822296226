


<div id="div-video-container" >
  <video class="dbrScanner-video" playsinline="true"></video>
  <div id="result"></div>
</div>

<div class="d-flex mw-100 m-0 p-3">
  <button *ngIf="getProgress() > 0" class="btn bg-transparent undo" data-test-id="undo" (click)="undo()"><i class="fa fa-fw fa-lg fa-undo"></i></button>
  <button *ngIf="getProgress() > 0" class="btn bg-transparent check text-white" data-test-id="done" (click)="done()"><i class="fa fa-fw fa-lg fa-check"></i></button>
  <div class="w-100"></div>
  <button class="btn bg-transparent close" (click)="emitEvent(null)">
    <i class="fas fa-lg fa-fw fa-xmark text-white"></i>
  </button>
</div>
<div class="h-100"></div>
<div class="text-white m-3 text-center mx-auto">[{{orderLine.default_code}}] {{orderLine.name}}</div>
<button class="scan" [ngClass]="{'active': isScanning}" (pointerdown)="result=null;isKeyboard=false;isScanning=true;$event.stopPropagation()" (pointerup)="isScanning=false"></button>


<button *ngIf="!isKeyboard" class="btn bg-transparent keyboard" data-test-id="keyboardButton" (click)="keyboard()">
  <i class="fas fa-fw fa-lg text-white fa-keyboard"></i>
</button>


<div class="progress mx-auto bg-dark" >
  <div class="progress-bar  bg-primary text-dark" [style.width.%]="(alreadyDone + (getProgress() * 100)/ this.total )" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
    <div class="text-white " style="min-width:100px">
      {{getProgress() + alreadyDone}} / {{getTotal()}}
    </div>
  </div>
</div>
  



<!-- when inserting with keyboard-->  
<div class="input-group  mx-auto mt-3" *ngIf="isKeyboard || result">
  <input type="text" class="form-control"  data-test-id="keyboardInput"  placeholder="" [(ngModel)]="result" >
  <div class="input-group-append">
    <button class="btn btn-primary" type="button" data-test-id="keyboardConfirm" (click)="emitEvent(result)">
      <i class="fas fa-check text-white fa-fw" ></i>
    </button>
  </div>
</div>
<div class="text-danger">{{error}}</div>

<div class="h-100"></div>
<!-- </div> -->