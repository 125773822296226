import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestapiService, QuerySearchOptions, QueryCriteriaOR } from 'src/app/shared/services/rest-api.service';
import * as moment from 'moment';
import { AstMemoryEfficientTransformer } from '@angular/compiler';


@Component({
  selector: 'app-search-detail',
  templateUrl: './search-detail.component.html'
})
export class SearchDetailComponent implements OnInit {

  id: string
  package: any = {}
  loading: boolean = false
  
  _from: Array<any>
  _to: Array<any>
  _moves: Array<any>
  _rels: any[];
  moves: any[];

  constructor(
    private route: ActivatedRoute,
    public restapi : RestapiService,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      
      console.log("ID ", params['id'])
      this.id = params['id'];
      this.loading = true;
      this.restapi.get({
        table : "na.pacchi",
        id : this.id,
        fields :[ "barcode",
                  "display_name",
                  "pacchi_product",
                  "tipo",
                ],
        resolve : [{key: "pacchi_product", table: "na.pacchi.product"}]
      }).then(async (res) =>  {
      
        console.log("PACCO ", res)

        this.loading = false;
        this.package = res;

        // search incoming picking related to this pack
        // TODO just one call
        var qPacchi : QuerySearchOptions = {
          table : "stock.move",
          criteria : [
            new QueryCriteriaOR(),
            {column: "pacco_from", operator: "ilike",  value: this.id},
            {column: "pacco_to", operator: "ilike",  value: this.id},
          ],
          limit: 100
        }

        var r = await this.restapi.search(qPacchi)
        this.moves = await this.restapi.getList({table: "stock.move", ids: r['stock.move']})

        console.log("moves ", this.moves)
      })


        

      
    })
  }

  getDate(move) {
    return moment(move.date).format('DD/MM/YYYY')
  }
  

  scanBarcodeAgain(){
    this.router.navigateByUrl('/RefrshComponent', {skipLocationChange: true}).then(()=>
    this.router.navigate(['search']));
  }

}
