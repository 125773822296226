<nav class="navbar text-white navbar-dark fixed-top px-1">
  <div *ngIf="mode != 'embedded'" class="d-flex w-100">
    <button class="btn btn-link text-white mr-auto" [routerLink]="['/home']">
      <i class="fas fa-chevron-left"></i>
    </button>

    <h1 class="ms-auto me-auto ">Contatti</h1>

  </div>
  <div class="w-100">
    <form autocomplete="off" class="d-flex flex-fill">

      <div class="input-group mx-2 my-1">
        <input type="text" class="form-control" placeholder="Cerca per nome o partita iva" [(ngModel)]="inputSearch"
          (input)="onSearchChange()" (focus)="onFocusInput(true)" (focusout)="onFocusInput(false)" name="cx">
        <div class="input-group-append mx-2">
          <button *ngIf="mode != 'embedded'" type="button" [ngClass]="{'active': companyQuery==false}"
            (click)="companyQuery = false;onSearchChange()" class="btn btn-dark rounded-0">
            <i class="fa fa-user"></i>
          </button>

          <button *ngIf="mode != 'embedded'" type="button" [ngClass]="{'active': companyQuery==true}"
            (click)="companyQuery = true;onSearchChange()" class="btn btn-dark rounded-0">
            <i class="fa fa-building"></i>
          </button>


          <!-- NUOVO CONTATTO -->
          <!-- <button *ngIf="mode == 'embedded'" class="ms-2 btn btn-primary text-white" (click)="newInEmbedded()">
        +
      </button> -->


          <!-- NUOVO CONTATTO -->
          <div class="dropdown ms-2 dropend d-inline" *ngIf="mode != 'embedded'">
            <button class="btn btn-primary text-white dropdown-toggle" type="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              Nuovo
            </button>
            <ul class="dropdown-menu">
              <form class="px-4 py-3">
                <div class="form-group d-flex flex-column">
                  <input class="form-control" placeholder="Nome o partiva iva" (input)="onSearchChange()"
                    [(ngModel)]="inputSearch" name="sn">
                  <br>
                  <div class="d-flex align-items-center ">
                    <p class="ms-1 text-muted mb-0">Ricordati di non inserire contatti doppi !</p>
                    <button class="ms-auto btn btn-primary text-white" (click)="new(inputSearch)">Crea</button>
                  </div>
                </div>
              </form>
            </ul>
          </div>

          <!-- new contatto -->


        </div>
        <!-- i group -->
      </div>

    </form>




  </div>
  <bar-loader [loading]="loading"></bar-loader>

</nav>

<router-outlet></router-outlet>

<div *ngIf="isNewEmbedded">
  <button class="w-25">Annulla</button><button class="w-75">Crea</button>
</div>

<div *ngIf="showResults" data-test-id="searchResult" class="list-group" style="margin-top:108px;">

  <ng-container *ngIf="contacts && contacts.length">
    <a [hidden]="loading" (click)="redirectContact(contact)"
      class="list-group-item list-group-item-action rounded-0 d-flex align-items-center"
      *ngFor="let contact of contacts">
      <span>
        <i class="fa fa-user me-3 text-muted " *ngIf="contact.parent_id.id"></i>
        <i class="fa fa-building me-3 text-muted" *ngIf="!contact.parent_id.id"></i>
      </span>

      <div class="lead">
        {{contact?.name?.toUpperCase()}}
        <span *ngIf="contact?.comment" class="text-muted"> ({{contact.comment}})</span><br>
        <small class="text-muted"
          *ngIf="contact && contact.parent_id && contact.parent_id.name">{{contact.parent_id.name}} - </small>
        <small class="text-muted" *ngIf="contact.city && contact.city != 'undefined'">{{contact.city}}</small>
      </div>
    </a>


  </ng-container>

  <!-- <ng-container *ngIf="contacts?.match1?.length">
    <a (click)="redirectContact(contact)" class="list-group-item list-group-item-action rounded-0"
       *ngFor="let contact of contacts.match1">
      <div class="lead">
        {{contact?.name}}
        <span *ngIf="contact?.comment" class="text-muted"> - {{contact?.city}}</span><br>
        <small><small class="text-muted"
                      *ngIf="contact && contact.parent_id">{{contact.partner_id.name}}</small></small>
      </div>
    </a>
    <div class="dropdown-divider"></div>
  </ng-container> -->

  <!--   
  <ng-container *ngIf="contacts?.match2?.length">
    <a (click)="redirectContact(contact)" class="list-group-item list-group-item-action rounded-0"
       *ngFor="let contact of contacts.match2">
      <div class="lead">
        {{contact?.name}}
        <span *ngIf="contact?.comment" class="text-muted"> - {{contact?.city}}</span><br>
        <small><small class="text-muted"
                      *ngIf="contact && contact.parent_id">{{contact.partner_id.name}}</small></small>
      </div>
    </a>
    <div class="dropdown-divider"></div>
  </ng-container>
  
  
  <ng-container *ngIf="contacts?.match3?.length">
    <a (click)="redirectContact(contact)" class="list-group-item list-group-item-action rounded-0"
       *ngFor="let contact of contacts.match3">
      <div class="lead">
        {{contact?.name}}
        <span *ngIf="contact?.comment" class="text-muted"> - {{contact?.city}}</span><br>
        <small><small class="text-muted"
                      *ngIf="contact && contact.parent_id">{{contact.partner_id.name}}</small></small>
      </div>
    </a>
    <div class="dropdown-divider"></div>
  </ng-container>

</div> -->
  <!-- 
<ng-container *ngIf="!loading && (contacts?.lenght == 0 || !contacts)">
  <p class="ms-4 mt-2 text-muted">Recenti</p>
  <a (click)="redirectContact(contact)" class="list-group-item list-group-item-action rounded-0 d-flex align-items-center"
    *ngFor="let contact of recentContacts">
    <span>
      <i class="fa fa-user me-3 text-muted " *ngIf="!contact.parent_name"></i>
      <i class="fa fa-building me-3 text-muted" *ngIf="contact.parent_name"></i>
    </span>
    
    <div class="lead">
      <span *ngIf="contact.parent_name" class="text-muted">{{contact.parent_name}} - </span>
      {{contact?.name}}
      <span *ngIf="contact?.comment" class="text-muted"> - {{contact?.city}}</span><br>
      <small><small class="text-muted"
                    *ngIf="contact && contact.parent_id">{{contact.partner_id.name}}</small></small>
    </div>
  </a>
</ng-container> -->


  <ng-container *ngIf="contacts?.length == 0">
    <h5 class="text-center mt-4">Nessun risultato</h5>
  </ng-container>