import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

@Injectable()
export class TokenStorage {

  kACCESS_TOKEN = 'access_token';
  kREFRESH_TOKEN = 'refresh_token';
  kEXPIRATION = 'expiresAt';

  /**
   * Get access token
   * @returns {string}
   */
  public getAccessToken(): string {
    return <string> localStorage.getItem(this.kACCESS_TOKEN);
  }

  /**
   * Get refresh token
   * @returns {Observable<string>}
   */
  public getRefreshToken(): string {
    return <string> localStorage.getItem(this.kREFRESH_TOKEN);
  }

  /**
   * Set access token
   * @returns {TokenStorage}
   */
  public setAccessToken(token: string): TokenStorage {
    localStorage.setItem(this.kACCESS_TOKEN, token)
    return this
  }

  /**
   * Set refresh token
   * @returns {TokenStorage}
   */
  public setRefreshToken(token: string): TokenStorage {
    localStorage.setItem(this.kREFRESH_TOKEN, token)
    return this
  }

  /**
   * Remove tokens
   */
  public clear() {
    localStorage.removeItem(this.kACCESS_TOKEN)
    localStorage.removeItem(this.kREFRESH_TOKEN)
    localStorage.removeItem(this.kEXPIRATION)
  }
}
