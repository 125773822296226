import { Component, OnInit } from '@angular/core';
import { GapiService } from './shared/services/g-api.service';
import { OdoorpcService } from './shared/services/odoorpc.service';
import { QuerySearchOptions } from './shared/services/rest-api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit {
  
  title = 'gali-erp3';
  busyMessage: unknown;
  constructor(
    private gs:GapiService,
    private rpc:OdoorpcService
    ) {

  }

  async ngOnInit(): Promise<void> {

    var r = await this.rpc.searchRead({
      table : "res.partner",
      criteria : [],
      limit:1
    })

    console.log("XX ", r)

    if (window.origin != "https://m.galimberti.eu")
      document.body.classList.add("dev")

      // attach to drive service for logging during copy (loooong process -  batch?)
    this.gs.driveBusyMessage.subscribe(x => {
      this.busyMessage = x
    })

  }


  
}
