import { Contact } from "../models/contact.model"

export class RecentCall {
    label:string
    href:string
}

export class RecentCalls {

    STORAGE_KEY = "contact.recents"
 
    getRecents() {
        var j = window.localStorage.getItem(this.STORAGE_KEY)
        var r:Contact[] = []
        try {
             r = JSON.parse(j)
        } catch(e) { 
        }
        r = r ? r : []
        console.log("recent ", r)
        return r
    }

    addToRecents(c:Contact) {
        var rs = this.getRecents()
        rs = rs.filter(r => (r.id && r.id != c.id))
        rs.unshift(c)
        rs = rs.slice(0,10)
        window.localStorage.setItem(this.STORAGE_KEY, JSON.stringify(rs))
    }

}