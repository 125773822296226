import { Component, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { ProcurementGroup } from 'src/app/models/procurement.group.model';
import { PurchaseOrder } from 'src/app/models/purchase-order.model';
import { SaleOrder } from 'src/app/models/sale-order.model';
import { StockPicking } from 'src/app/models/stock-picking';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';

@Component({
  selector: 'app-production-search',
  templateUrl: 'picking-search.component.html',
})
export class PickingSearchComponent implements OnInit {

  loading:boolean
  pickings: StockPicking[];
  title = ""

  filters = {
    purchase : false,
    sale : false
  }

  searchInput:BehaviorSubject<string> = new BehaviorSubject("")
  params: any;
  keybuffer: string = "";

  constructor(
    public odooEM : OdooEntityManager,
    public router: Router,
    public elementRef : ElementRef,
    private activatedRoute:ActivatedRoute
  ) { 
  }


  async ngOnInit(): Promise<void> {
    this.params = this.activatedRoute.snapshot.params
    this.title = this.params['type']
    this.searchInput.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(s => {
        this.load()
      });

      // window.addEventListener("keydown", e => {
      //   console.log("e", e)
      //   if (e.key == "Enter") {
      //     this.onScan(this.keybuffer)
      //     this.keybuffer = ""
      //   } else 
      //     this.keybuffer = this.keybuffer + e.key
      // })
  }

  toggleFilter(f:string) {
    this.filters[f] = !this.filters[f]
    console.log(f)
    this.load()
  }

  async onScan(s:string) {
    // look for candidates
    // var sales = await this.odooEM.search<SaleOrder>(new SaleOrder(), [['partner_ref', '=', s]]).toPromise()
    // if (sales.length > 0) {
      
    // }

    var purchases = await this.odooEM.search<PurchaseOrder>(new PurchaseOrder(), [['partner_ref', '=', s]]).toPromise()
    if (purchases.length > 0) {
      this.searchInput.next(purchases[0].name)
    }
  }
    
    
    async load() {
      var c = [['state','in',['assigned']]]
      if (this.filters.sale) 
        c.push(['origin', 'ilike', 'V%'])
      if (this.filters.purchase) 
        c.push(['origin', 'ilike', 'A%'])
      var f = this.searchInput.value
      if (f)
        c.push(['origin','ilike',f])
      var ps = await this.odooEM.search<StockPicking>(new StockPicking(),c,200,"","state").toPromise()
      
      await this.odooEM.resolveArrayOfSingle(new ProcurementGroup(),ps,"group_id").toPromise()

      var groups = ps.map(p => p.group_id.value).filter(x => x)
      console.log("groups ", groups)
      
      await this.odooEM.resolveArrayOfSingle(new SaleOrder(),groups,"sale_id").toPromise()
      console.log("PPS", ps)
      this.pickings = ps
    }

    getPickingClass(p:StockPicking) {
      if (p.state == 'waiting')
        return 'text-muted'
      if (p.state == 'assigned'  || p.state == 'done')
        return 'text-success'
      if (p.state == 'confirmed' )
        return 'text-warning'
    }
    
  
}
