<div id="info" class="card side-card w-25 h-100 rounded-0" *ngIf="order" [hidden]="infoSideClosed"
     >

  <div class="card-body" data-print-row-header>

    <div class="form-group d-none">
      <input data-print-col value="{{order.id}}">
    </div>

    <div class="form-group d-none">
      <input data-print-col value="{{order.amount_total}}">
    </div>

    <div class="form-group d-none">
      <input data-print-col value="{{order.amount_total_agreed}}">
    </div>

    <div class="form-group d-none">
      <input data-print-col value="{{order.state}}">
    </div>

    <div class="form-group d-none">
      <input data-print-col value="{{order.date_order}}">
    </div>

    <div class="form-group">
      <label><b>{{isPurchaseOrder ? 'Fornitore' : 'Cliente'}}</b></label>
      <input data-print-col class="form-control" readonly value="{{order.partner_id.name}}">
    </div>

    <!-- <div class="form-group">
      <label><b>Referente</b></label>
      <select data-print-col [disabled]="order.state != 'draft'" class="form-control"
              [(ngModel)]="order.referente_id.id" (change)="updateOrder(order,'referente_id')">
        <option *ngFor="let t of referentContacts" [ngValue]="t.id">{{t.name}}</option>
      </select>
    </div> -->

    <div class="form-group">
      <label><b>Responsabile</b></label>
      <select data-print-col [disabled]="order.state != 'draft'" class="form-control" [(ngModel)]="order.user_id.id"
              (change)="updateOrder(order,'user_id')">
        <option *ngFor="let t of users" [ngValue]="t.id">{{t.name}}</option>
      </select>
    </div>

    <div class="form-group">
      <label><b>Termini di pagamento</b></label>
      <select data-print-col [disabled]="order.state != 'draft'" class="form-control"
              [(ngModel)]="order.payment_term_id.id" (change)="updateOrder(order,'payment_term_id')">
        <option *ngFor="let t of paymentTerms" [ngValue]="t.id">{{t.display_name}}</option>
      </select>
    </div>

    <div class="form-group">
      <label><b>Termini di consegna</b></label>
      <select data-print-col [disabled]="order.state != 'draft'" class="form-control" *ngIf="order.incoterm"
              [(ngModel)]="order.incoterm.id"
              (change)="updateOrder(order,'incoterm')">
        <option *ngFor="let t of incoTerms" [ngValue]="t.id">{{t.display_name}}</option>
      </select>
      <select data-print-col [disabled]="order.state != 'draft'" class="form-control" *ngIf="order.incoterm_id"
              [(ngModel)]="order.incoterm_id.id"
              (change)="updateOrder(order,'incoterm_id')">
        <option *ngFor="let t of incoTerms" [ngValue]="t.id">{{t.display_name}}</option>
      </select>
    </div>
    <div class="form-group">
      <label><b>{{isPurchaseOrder ? 'Data pianificata' : 'Data di consegna'}}</b></label>
      <div class="form-group">
        <div class="input-group">
          <input data-print-col class="form-control" placeholder="yyyy-mm-dd"
                 name="dp" (dateSelect)="onDateSelected($event)" [(ngModel)]="datepickerOrder" ngbDatepicker
                 #d="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary " (click)="d.toggle()" type="button">+</button>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label><b>Destinazione</b></label>
      <textarea data-print-col [disabled]="order.state != 'draft'" class="form-control" rows="3"
                [(ngModel)]="order.destinazione" (change)="updateOrder(order,'destinazione')"></textarea>
    </div>

    <div class="form-group" *ngIf="!isPurchaseOrder">
      <label><b>Note di pagamento</b></label>
      <textarea data-print-col [disabled]="order.state != 'draft'" class="form-control" rows="3"
                [(ngModel)]="order.payment_note" (change)="updateOrder(order,'payment_note')"></textarea>
    </div>

    <div class="form-group" *ngIf="isPurchaseOrder">
      <label><b>Note di consegna</b></label>
      <textarea data-print-col class="form-control" rows="3" [(ngModel)]="order.delivery_note"
                (change)="updateOrder(order,'delivery_note')"></textarea>
    </div>


    <div class="form-group">
      <label><b>Note</b></label>
      <textarea data-print-col [disabled]="order.state != 'draft'" class="form-control" rows="3"
                [(ngModel)]="order.note" (change)="updateOrder(order,'note')"></textarea>
    </div>
  </div>
</div>
