import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { HrAttendanceOvertime } from '../models/hr-attendance-overtime.model';
import { HrAttendance } from '../models/hr-attendance.model';
import { HrEmployee } from '../models/hr-employee.model';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import * as moment from 'moment';

enum Actions {
  Enter = "login",
  Exit = "logout"
}

@Component({
  selector: 'app-badge-kiosk',
  templateUrl: './badge-kiosk.component.html',
})
export class BadgeKioskComponent implements OnInit {
  employee: HrEmployee

  nextAction : Actions
  actions = Actions;
  secTimeout : number = 5
  blockNextAction = false
  interval
  timeout
  
  constructor(private odooEM: OdooEntityManager) { }

  async ngOnInit() {
    // var array = (await this.odooEM.search<HrAttendanceOvertime>(new HrAttendanceOvertime(), [] ).toPromise())
    // var array2 = (await this.odooEM.search<HrAttendance>(new HrAttendance(), [] ).toPromise())

    // console.log("ore ", array )
    await this.onCode("041520459148")
  }

  async onCode(badgeId) {

    this.employee = (await this.odooEM.search<HrEmployee>(new HrEmployee(), [["barcode", "=", badgeId]]).toPromise())[0]
    if (this.employee.attendance_state == "checked_out") 
      this.nextAction = Actions.Enter;
    else 
      this.nextAction = Actions.Exit

    this.interval = setInterval( () => {
      this.secTimeout = this.secTimeout - 1 
    }, 1000)

    this.timeout = setTimeout(async () => {
        this.nextAction == Actions.Enter? await this.enter() : await this.exit()
    }, 5 * 1000);

    // odoo table 'utete','data_entrata','data_uscita'
    // aggiorno o creo righa.


    // if(this.employee.attendance_state == "checked_out"){
    //   this.enter()
    // }else{
    //   this.exit()
    // }
  }

  async enter() {
    const a = {
      employee_id: this.employee.id,
    }
    await this.odooEM.create<HrAttendance>(new HrAttendance(), a).pipe(first()).toPromise()
    this.employee = undefined

    //TODO CHECK ERROR ?
    // this.nextAction = Actions.Exit
    
  } 

  async stopNextAction(){
    clearTimeout(this.timeout);
    clearInterval(this.timeout);
    this.timeout = null
    this.interval = null
    document.getElementById("timeout").remove()
  } 

  async exit(){

    var hrAttendance = (await this.odooEM.search<HrAttendance>(new HrAttendance(), []).toPromise())[0]
    var format = "YYYY-MM-DD HH:mm:ss";
    var dateExit = moment( new Date() ).utc( false ).format(format);
    await this.odooEM.update<HrAttendance>(hrAttendance, {["check_out"]: dateExit });

    //TODO CHECK ERROR ?
    // this.nextAction = Actions.Enter
    this.employee = undefined
  }

}
