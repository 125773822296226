import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { GapiService } from 'src/app/shared/services/g-api.service';

@Component({
  selector: 'app-gmail-import',
  templateUrl: './gmail-import.component.html',
  styleUrls: ['./gmail-import.component.scss']
})
export class GmailImportComponent implements OnInit {
  message:string
  loading: boolean = true;

  constructor(
    private gapi:GapiService,
    private route:ActivatedRoute
  ) { }

  async ngOnInit() {
    console.log("import pred")
    await this.gapi.$ready.pipe(first()).toPromise()
  }

  async onLeadSelection(id) {
    this.message = "Sto importando la mail..."
    this.loading = true
    console.log("load")
    var params = await this.route.params.pipe(first()).toPromise()
    console.log("import",  params.mailid)
    await this.gapi.signIn()
    await this.gapi.insertEmail(params.mailid, " #F" + id)
    // console.log("NEWMAIL ID", newMailid)
    // await this.gapi.addLabelToMessage(params.mailid)
    this.loading = false
    this.message = "Mail importata! Puoi chiudere questa finestra."
  }
}
