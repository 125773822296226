<app-order-header
  showInventory="true"
  (actionFromHeaderEmitter)="handleHeaderEvent($event)"
  [isSomethingChecked]="true"
  [order]="production"
  [canConfirm]="production?.state == 'draft'"
  [loading]="loading"
  ></app-order-header>


  
  <!-- <app-order-inventory
        [inventoryClosed]="ancillartInventoryClosed"
        (toggleInventory)="toggleAncillaryInventory()"
        (addOrderLine)="addAncillaryOrderLine($event)">
      </app-order-inventory>
  -->


  <app-order-inventory 
    [inventoryClosed]="inventoryClosed"
    (toggleInventory)="toggleInventory()"
    (addOrderLine)="addOrderLine($event)"
  >
  </app-order-inventory>
  
  
<app-production-table
  *ngIf="production" 
  [stockMoves]="production?.move_raw_ids.values"
  [productsSupplierinfo]="supplierinfos"
  [production]="production"
  (updateOrderLineEmitter)="updateStockMove($event)"
  style="margin-top: 60px"
></app-production-table>

<!-- <nav class="navbar navbar-expand-md  fixed-top border-bottom bg-dark navbar-dark">
  <div class="container-fluid">
      <button class="btn btn-link text-white mr2-auto"  routerLink="../../" >
<i class="fas fa-chevron-left"></i>      
       </button>
    <a class="navbar-brand" href="#">
      &nbsp;<b>Pacchi ordine {{orderId}}</b>
    </a>
    <button class="navbar-toggler navbar-toggler-right border-0" type="button" data-toggle="collapse" data-target="#navbar4" style="">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse " id="navbar4">
      <button class="ms-auto btn btn-primary text-white" (click)="validatePicking()">Conferma </button>
    </div>
  </div>
  <bar-loader [loading]="loading"></bar-loader>
</nav>

<div class="w-100 trello-like p-4 margin-top-as-navbar" style="min-width: 330px;max-width: auto;"  >
  <ul class="large" class="w-50" style="min-width: 700px;">
      
    <li class="d-flex align-items-center">
        <h3 class="d-flex">
          Prelievi
        </h3>
      </li>
      
      <li  *ngFor="let l of orderLines ; trackBy:trackByFn;" class="d-flex flex-column justify-content-center" >
        <app-picking-move-editor
          [picking]="picking" 
          [saleOrderLine]="l"
          [purchaseOrderLine]="getPurchaseLine(l)"
          [packages]="getPackages(picking)"
          (onPackageChange)="load()"
        ></app-picking-move-editor>

        
      </li>
    </ul>

    <app-picking-leftovers class="w-25" *ngIf="picking" [picking]="picking"
    (onPackageChange)="load()"></app-picking-leftovers>

    <ul >
      <li>
        <h3 class="d-flex">
          <span>Colli</span>
        </h3>
      </li>
      <li  class="d-flex flex-row align-items-center" *ngFor="let p of stockPackages">
        <p class="lead text-muted mb-1">#{{p.name}}
         
        </p>
        <package-move-request 
          class="ms-auto"
          [package]="p" 
          (change)="onMoveRequest($event)"
          
          [location]="p.location"
      ></package-move-request>
      </li>
    </ul>

    
  </div> -->