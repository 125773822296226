import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Lead } from 'src/app/models/crm.lead.model';
import { PRINTS_CFG } from 'src/app/models/deal';
import { ProductAttributeValue } from 'src/app/models/product.attribute.value';
import { Product } from 'src/app/models/product.model';
import { ProductTemplateAttributeValue } from 'src/app/models/product.template.attribute.value.model';
import { ProductTemplate } from 'src/app/models/product.template.model';
import { SaleOrderLine } from 'src/app/models/sale-order-line.model';
import { SaleOrder } from 'src/app/models/sale-order.model';
import { StockLocationRoute } from 'src/app/models/stock-location-route.model';
import { StockMove } from 'src/app/models/stock-move';
import { StockMoveLine } from 'src/app/models/stock-move-line';
import { StockPicking } from 'src/app/models/stock-picking';
import { StockPickingType } from 'src/app/models/stock-picking-type.model';
import { StockRule } from 'src/app/models/stock-rule';
import { GapiService } from 'src/app/shared/services/g-api.service';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
import * as bootstrap from 'bootstrap';

@Component({
  selector: 'app-sale-order-editor',
  templateUrl: './sale-order-editor.component.html'
})
export class SaleOrderEditorComponent implements OnInit {
  id: any;
  loading: boolean = true;
  order: SaleOrder;
  selectableRoutes: StockLocationRoute[] = [];
  inventoryClosed:boolean = true
  pickings: StockPicking[];
  moves: StockMove[];
  

  constructor(
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private odooEm: OdooEntityManager,
    private gapiService: GapiService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(async params => {
      this.id = params['order_id']
      // available routes in sale
      this.selectableRoutes = await this.odooEm.search<StockLocationRoute>(new StockLocationRoute(),[["sale_selectable","=",true]]).toPromise()
      await this.load()
    })    

    // we want to close inventory when clicking outside
    this.elementRef.nativeElement.querySelector(".table-container").addEventListener("mousedown", () => {
      this.inventoryClosed = true
    })

    console.log("---- serve", bootstrap.Modal)

  }

  async load() {

    this.loading = true
    
    var order:SaleOrder = (await this.odooEm.search<SaleOrder>(new SaleOrder(), [["id","=",this.id]]).toPromise())[0]
    await this.odooEm.resolve(order.order_line).toPromise()
    await this.odooEm.resolveArray(new StockMove(),order.order_line.values,"move_ids").toPromise()

    var moveids = order.order_line.values.map(l => l.move_ids.values).reduce((acc, val) => acc.concat(val), []);

    console.log("moveids", moveids)
    await this.odooEm.resolveArrayOfSingle(new StockPicking(), moveids, "picking_id").toPromise()

    this.pickings = await this.odooEm.search<StockPicking>(new StockPicking(), [["origin","=", order.name]]).toPromise()
    console.log("PICKK ", this.pickings)
    
    this.moves = await this.odooEm.search<StockMove>(new StockMove(), [["picking_id","in", this.pickings.map(p => p.id)]]).toPromise()
    // await this.odooEm.resolveArrayOfSingle(new StockPicking(), this.moves, "picking_id").toPromise()

    await this.odooEm.resolveArrayOfSingle(new Product(),order.order_line.values,"product_id").toPromise()
    
    console.log("ROUTE  ", order.order_line.values)

    
    var products = []
    order.order_line.values.forEach(s => {
      if (s.product_id && s.product_id.value)
      products.push(s.product_id.value)
    })
    
    await this.odooEm.resolveArray(new ProductTemplateAttributeValue(), products, "product_template_attribute_value_ids").toPromise()
    
    this.order = order
    
    this.loading = false
     await this.odooEm.resolveSingle(new Lead(), order.opportunity_id).toPromise()
  }


  async addOrderLine(product: Product) {
    this.loading = true
    var init = {
      order_id : this.order.id,
      product_id: product.id,
      product_uom_qty:0
    }
    await this.odooEm.create(new SaleOrderLine(),init).toPromise()
    this.load()
  }


  async handleHeaderEvent(e) {
    console.log("HANDLE ", e)
    if (e.functionName == 'confirm') {
      await this.odooEm.call(new SaleOrder(), "action_confirm",this.order.id).toPromise()
      this.load()
    } else if (e.functionName == 'toggleInventory')
      this.inventoryClosed = !this.inventoryClosed
    else if (e.functionName == 'print') 
      this.print()
    else
      this.load()
  }


  getMoveClass(m) {

    console.log("MM", m)
      if (m.state == 'waiting')
        return 'bg-muted'
      if (m.state == 'assigned' )
        return 'bg-success'
      if (m.state == 'done')
        return 'bg-success text-stroke'
      if (m.state == 'confirmed' )
        return 'bg-warning'
  }

  getVariantAttribute(line:SaleOrderLine, name:string) {
    // console.log("line ", line)
    
    if (!line.product_id)
      return

    if (!line.product_id.value || !line.product_id.value.product_template_attribute_value_ids)
    return ""
    
    
    if (line.product_id.value.product_template_attribute_value_ids.values) {
      var f = line.product_id.value.product_template_attribute_value_ids.values.filter(value => {
        return value.attribute_id.name == name
      })
    }

    return (f && f.length > 0) ? f[0] : null
  }

  async updateLineRoute(line:SaleOrderLine) {
    await this.odooEm.update(line, {'route_id': line.route_id.id}).toPromise()
    console.log("update")
  }


  async updateLine(fieldName:string,line:SaleOrderLine) {
    this.loading = true
    var n = {}
    n[fieldName] = line[fieldName]
    var lll = await this.odooEm.update(line, n).toPromise()

    console.log("out", lll)
    // refresh the needed computed on line too 
    var fresh =(await this.odooEm.search<SaleOrderLine>(new SaleOrderLine(),[['id','=',line.id]]).toPromise())[0]
    line.forecast_expected_date = fresh.forecast_expected_date
    line.free_qty_today = fresh.free_qty_today
    line.qty_available_today = fresh.qty_available_today
    this.loading = false

  }

  isLineDoubleUM(line) {
    return true
  }


  isLineEditable(line:SaleOrderLine) {
    if (!line.product_id || !line.product_id.value)
      return false
    var a = line.product_id.value.product_template_attribute_value_ids.values.filter(x=> x.name == "su misura")
    return a.length == 1
  }


  // TODO da portare su backend
  async updateVariant(line:SaleOrderLine,e) {
    console.log("updatevariant", line)
    this.loading = true

    var tmpl = line.product_id.value.product_tmpl_id.value
    if (!tmpl) {
      await this.odooEm.resolveSingle(new ProductTemplate(), line.product_id.value.product_tmpl_id).toPromise()
      tmpl = line.product_id.value.product_tmpl_id.value
    }
    
    if (!tmpl.attribute_line_ids.values)
      await this.odooEm.resolve(tmpl.attribute_line_ids).toPromise()

    var attrline 
    line.product_id.value.product_tmpl_id.value.attribute_line_ids.values.forEach(l => {
      if (l.display_name == "length")
        attrline = l 
    })

    if (!line || !attrline)
      return 
    // check if exist attribute value
    let oldv:ProductAttributeValue[] = await this.odooEm.search<ProductAttributeValue>(new ProductAttributeValue(), 
      [["name","=", e.target.value],
       ["attribute_id","=", attrline.attribute_id.id]]
    ).toPromise()

    let v:ProductAttributeValue 
    
    if (oldv.length > 0) 
      v = oldv[0]
    else {
      // new attribute value
      v = await this.odooEm.create<ProductAttributeValue>(new ProductAttributeValue(), {
        "name": e.target.value,
        "attribute_id": attrline.attribute_id.id
      }).toPromise()
    }

    var ids = attrline.value_ids.ids
    ids.splice(ids.length,0,v.id)
    
    await this.odooEm.update(tmpl, {
         attribute_line_ids:[[1, attrline.id, {value_ids: [[6, false, ids]]}]]
    }).toPromise()
    
    // // fresh template attribute value only after adding value to attriute
    var freshv = (await this.odooEm.search<ProductTemplateAttributeValue>(
      new ProductTemplateAttributeValue(),
      [['product_attribute_value_id', '=', v.id],['product_tmpl_id', '=', tmpl.id]]).toPromise())[0]

    // // search the just created product variant
    var p:Product[] = await this.odooEm.search<Product>(new Product(), [['active','=', true],['product_template_variant_value_ids','in',freshv.id]]).toPromise()
    // await this.odooEm.update(e.move, {'product_id':p[0].id}).toPromise()
    await this.odooEm.update(line, {'product_id':p[0].id}).toPromise()

    // // dirty selective update
    await this.odooEm.resolve(p[0].product_template_attribute_value_ids).toPromise()
    line.product_id.value = p[0]
    line.product_id.id = p[0].id
    line.product_id.name = p[0].display_name
    
    this.loading = false
  }




  async print(): Promise<void> {
    this.loading = true;

    let headerRow; // just one
    this.elementRef.nativeElement.querySelectorAll('[data-print-row-header]').forEach(element => {
      headerRow = this.row2printable(element);
    });

    const headerDatas = []; // more than one
    this.elementRef.nativeElement.querySelectorAll('[data-print-row]').forEach(element => {
      headerDatas.push(this.row2printable(element));
    });

    // var sheetid = await this.gapiService.printOrder(
    //   PRINTS_CFG.template_sheet_id,
    //   PRINTS_CFG.spool_folder_id, 
    //   headerRow, 
    //   headerDatas)


    console.log( " headerRow ", headerRow)
    // window.open('https://docs.google.com/spreadsheets/d/' + sheetid, '_blank');
    this.loading = false;

  }

  row2printable(r:HTMLElement): any {
    const row = [];
    r.querySelectorAll('[data-print-col]').forEach(element => {
      
      console.log('row 2 printable', element, element.tagName === 'A');

      // if (element.tagName === 'B' || element.tagName === 'A' || element.tagName === 'SPAN') {
      //   row.push(element.innerText);
      // } else 
      if (element.tagName === 'SELECT') {
        var s = element as HTMLSelectElement
        if (s.selectedIndex > -1) {
          row.push(s.options[s.selectedIndex].innerHTML);
        }
      } else if (['INPUT', 'TEXTAREA' ].includes(element.tagName)) {
        var i = element as HTMLInputElement
        row.push(i.value);
      } else if (element.childElementCount> 0) {
        let t = '';
        element.querySelectorAll(':scope > *').forEach(e => {
          t += (((e as any).value ? (e as any).value : (e as any).innerText) + ' ');
        });
        row.push(t);
      } else {
        row.push((element as any).innerText);
      }
    });
    return row;
  }




  
  getMoves(original:StockMove) {
    var moves = []
    var next = original
    
    while(next != null) {
      moves.push(next)
      if (next.move_orig_ids.ids.length > 0) {
        var x = this.moves.find(m => next.move_orig_ids.ids.includes(m.id)) 
        next = x
      } else {
        next = null
      }
    }

    return moves.reverse()

  }



}
