import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { RestapiService, QuerySearchOptions, ApiEvent, EventType, QueryPostOptions, QueryCriteria } from 'src/app/shared/services/rest-api.service';
import { Router } from '@angular/router';
import { AREAS_CFG, Deal } from '../../models/deal'
import { ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
import { Lead } from 'src/app/models/crm.lead.model';
import { CrmStage } from 'src/app/models/crm.lead.stage.model';
import { JsonpClientBackend } from '@angular/common/http';

@Component({
  selector: 'app-deals-dashboard',
  templateUrl: './deals-dashboard.component.html',
})
export class DealsDashboardComponent implements OnInit, AfterViewInit {
  stages: CrmStage[] = []
  cards: Lead[] = []
  filteredCards: Deal[] = []
  @Input() loading: boolean = true
  searchDealsInput: string = ''
  areas: { name: string; src: string; dst: string; }[]
  newDeal:Deal
  filter: BehaviorSubject<string> 
  justRecents = true
  @Input() title = "Fascicoli"
  @ViewChild('searchInput', {static:true}) searchInput: ElementRef
  @Input() embedded:boolean = false
  @Output() onSelect:EventEmitter<boolean> = new EventEmitter()

  constructor(
    public restapi : RestapiService,
    private router: Router,
    private _cdr: ChangeDetectorRef,
    private odooEM: OdooEntityManager
  ) {
    // cache filter val
    var i = localStorage.getItem('deals-dashboard-filter')
    i = i ? i : ''

    this.filter = new BehaviorSubject(i)
    this.filter.subscribe((f) => {
      // sync new deal area default with active filter
      console.log("FILTER2", f)
      localStorage.setItem('deals-dashboard-filter', f)
      if (this.newDeal)
        this.newDeal.area = f
    })
  }

  async ngOnInit() {
    this.areas = AREAS_CFG
    this.newDeal = new Deal()
    this.newDeal.area = this.filter.value
    console.log("xxxxmmmstage" )
    this.stages = await this.odooEM.search<CrmStage>(new CrmStage(),[]).toPromise()
    this.refresh()
    
    // .then(async data => {
    //   this.stages = data['crm.stage']
    // })
  }


  async refresh() {
    this.loading = true
    var d = new Date();
    d.setMonth(d.getMonth() - 3);

    this.cards = await this.odooEM.search<Lead>(
      new Lead(),
      this.justRecents ? [['write_date','>=', d.toDateString()]] : [],100,null,'tracking_code asc'
      ).toPromise()

      console.log("CARDDD", this.cards)
    this.loading = false
  }

  ngAfterViewInit(): void {
    // debounce search input changes
    fromEvent(this.searchInput.nativeElement, 'input')
    .pipe(debounceTime(200))
    .subscribe(e => {
      this.searchDealsInput = (e as any).target.value
      this._cdr.detectChanges()
      this.router.navigate([], { queryParams: { search: this.searchDealsInput } })
    });
  }

  onContact(c) {
    this.newDeal.partner_id = [c.id,''];
    this.createDeal()
  }

  
  pickContact(){
    var w = window.open('/contact?mode=embedded','_blank','height=700,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes' );
    
    var h = event => {
      var d = JSON.parse(event.data)
      this.newDeal.partner_id = [d.id,d.name]
      w.close()
    }
    window.addEventListener("message", h, false);
    w.onbeforeunload = () => {
      window.removeEventListener("message", h)
    }
  }

  createDeal() {
    this.loading = true
    // todo move in abstarct model
    var x = {"area": this.newDeal.area, "partner_id": this.newDeal.partner_id[0],"name":"",description:""}

    var postOptions : QueryPostOptions = {
      table : "crm.lead",
      json_fields : JSON.stringify(x)
    }
    
    
    this.odooEM.create<Lead>(new Lead(),x).subscribe((res) => {
      this.loading = false
      // Add the new deal to the cards array
      this.cards.unshift(JSON.parse(JSON.stringify(res)))
      this.redirectDeal(res.id)
    })

  }

  redirectDeal(id) {
    if (!this.embedded)
      this.router.navigate(['leads/'+id]);
    else {
      this.onSelect.emit(id)
      // this.
    }
  }

  // filter cards by id and by searchbar substrings match (name and description)
  filterCards(stageid) {
    
    return this.cards.filter(c => {

      if (this.searchDealsInput  && 
        (!c.partner_id  || !c.partner_id.name|| !c.partner_id.name.toUpperCase().includes(this.searchDealsInput.toUpperCase())) &&
        (!c.tracking_code  || !c.tracking_code.toString().startsWith(this.searchDealsInput)) 
        )
        return false
      if (this.filter && this.filter.value != '')
        return  c.stage_id.id == stageid && c.area == this.filter.getValue() 
      return c.stage_id.id == stageid
    }).sort((x,y) => {
      return Number(y.tracking_code) - Number(x.tracking_code)
    })
  }

  // Calculate the total estimated value for stage type 
  calculateTotalRevenueValues(filteredCards) {
    for(let stage of this.stages) {
      stage.totalRevenueValue = 0;
      for(let card of filteredCards) {
        stage.totalRevenueValue = stage.totalRevenueValue + card.planned_revenue;    
      }
    }
  }

  toggleRecents() {
    this.justRecents = !this.justRecents
    this.refresh()
  }

}
