import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SaleOrderLine } from '../../models/sale-order-line.model';
import { SaleOrder } from '../../models/sale-order.model';
import { GenericOrderLine } from '../../models/generic-order-line.model';

@Component({
  selector: 'app-sale-order-table',
  templateUrl: './sale-order-table.component.html',
  styleUrls: ['./sale-order-table.component.scss']
})
export class SaleOrderTableComponent implements OnInit {
  @Input() saleOrderLines: SaleOrderLine[];
  @Input() saleOrder: SaleOrder;
  @Output() updateOrderLineEmitter: EventEmitter<TableEmitterFormat> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  updateOrderLine(field?: string, orderLine?: SaleOrderLine) {
    this.updateOrderLineEmitter.emit({field, orderLine});
  }

  onEnter($event): void {
  }

  addNote(saleOrderLine: SaleOrderLine): void {
  }
}

export interface TableEmitterFormat {
  field: string;
  orderLine: GenericOrderLine;
}
