import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';




@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  socket: any;

  constructor(private router: Router) { }

  ngOnInit() {


    // (window as any).global = {}
    // var s = this.odoo.searchRead("res.partner")
    // console.log("SS", s)
    // check redirect uri , setted by auth.guard
    if (localStorage.getItem("redirect_uri") !== null) {
      var path = localStorage.getItem("redirect_uri")
      localStorage.removeItem("redirect_uri")
      this.router.navigate([path]);
    }

  }
}
