<app-order-header
  *ngIf="order"
  [order]="order"
  [loading]="loading"
  [isSomethingChecked]="isSomethingChecked"
  [orderLinesChecked]="orderLinesChecked"
  (actionFromHeaderEmitter)="handleEventFromHeader($event)">
</app-order-header>

<div class="flex-grow-1 d-flex h-100 canvas orders-content" data-test-id="tableProducts" style="margin-top: 60px"
     *ngIf="order">
  <app-sale-order-table *ngIf="order.ORDER_TYPE_NAME==='SaleOrder'"
                        [saleOrderLines]="order.order_line.values"
                        [saleOrder]="order"
                        (updateOrderLineEmitter)="updateOrderLineToOdoo($event)"
                        class="w-100">
  </app-sale-order-table>

  <div *ngIf="order.ORDER_TYPE_NAME==='PurchaseOrder'" class="d-flex flex-column w-100">
    <!-- Main purchase table -->
    <app-purchase-order-table
      [purchaseOrderLines]="order.order_line.values"
      [purchaseOrder]="order"
      (updateOrderLineEmitter)="updateOrderLineToOdoo($event)"
      class="w-100">
    </app-purchase-order-table>

    <!-- Ordini ausiliari -->
    <div *ngIf="selectedAncillaryOrder " class="ancillary-orders ">
      <!-- <h2 class="mb-4 ms-3">Ordine ausiliario {{selectedAncillaryOrder.name}} -->
      <!-- {{selectedAncillaryOrder.partner_id.name}}</h2> -->
      <div class="d-flex pt-3 pb-3 mb-0 bg-light">
        <a class="btn btn-default navbar-btn text-black ms-auto" title="Modifica ordine ausiliario"
           (click)="toggleAncillaryInventory()">
          <i class="fa fa-plus px-2"></i>
        </a>

        <a class="btn btn-default navbar-btn text-black" [ngClass]="{'disabled': !isSomethingChecked}"
           title="Elimina riga ordine ausiliario"
           (click)="deleteAncillaryOrderLines()">
          <i class="fa fa-trash px-2"></i>
        </a>

        <div class="form-group mb-0  me-4">
          <div class="input-group mb-0">
            <input disabled class="form-control" readonly type="text"
                   [value]="pickedPartner ? pickedPartner.name : ''">
            <div class="input-group-append">
              <button class="btn btn-info" (click)="pickPartners()" type="button">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
        <a class="btn btn-default navbar-btn text-red" title="Elimina ordine ausiliario"
           (click)="deleteAncillaryOrder(selectedAncillaryOrder)">
          <i class="fas fa-trash ps-2 fa-fw fa-lg"></i>
        </a>
        
      </div>
      <app-purchase-order-table
        [purchaseOrderLines]="selectedAncillaryOrder.order_line.values"
        [purchaseOrder]="selectedAncillaryOrder"
        (updateOrderLineEmitter)="updateOrderLineToOdoo($event)"
        class="w-100">
      </app-purchase-order-table>
      <app-order-inventory
        [inventoryClosed]="ancillartInventoryClosed"
        (toggleInventory)="toggleAncillaryInventory()"
        (addOrderLine)="addAncillaryOrderLine($event)">
      </app-order-inventory>
    </div>
  </div>
</div>

<app-order-inventory
  [inventoryClosed]="inventoryClosed"
  (toggleInventory)="toggleInventory()"
  (addOrderLine)="addOrderLine($event)">
</app-order-inventory>
<app-info-side-order
  [infoSideClosed]="infoSideClosed"
  [paymentTerms]="paymentTerms"
  [incoTerms]="incoTerms"
  [users]="users"
  [order]="order"
  [referentContacts]="referentContacts"
  (toggleInfoSide)="toggleInfo()"
  (updateOrderToOdoo)="updateOrderToOdoo($event)"
>
</app-info-side-order>

<div class="footer d-flex" *ngIf="order">
  <ng-container *ngIf="order.ORDER_TYPE_NAME==='PurchaseOrder'">
    <button class="btn btn-light me-5" [disabled]="order.state!=='draft' && order.state!=='sent'" (click)="createNewAncillaryOrder()">
      <i class="fa fa-plus"></i>&nbsp;<b>Costi ausiliari</b>
    </button>
    <button class="btn btn-light me-2" *ngFor="let ancillaryOrder of order.ancillary_purchase_order_ids.values"
            (click)="loadAndToggleAcillaryOrderLines(ancillaryOrder)"
            [ngClass]="{'active': selectedAncillaryOrder===ancillaryOrder}">
      <strong>{{ ancillaryOrder.name}} - {{ancillaryOrder.partner_id.name }}</strong>
    </button>
  </ng-container>
  <span class="ms-auto">Concordato <b>€</b> </span>
  <!-- <input-number
        [disabled]="saleOrder.state != 'draft'"
        (change)="updateOrderLine('discount', saleOrderLine)"
        [(ngModel)]="saleOrderLine.discount"></input-number> -->
  <input-number class="border-0"
                [(ngModel)]="order.amount_total_agreed"
                (change)="updateOrderToOdoo({order:order,field:'amount_total_agreed'})"></input-number>
  <span class="ms-4">Totale <b>€ {{getTotal() | number : '1.0-3':'it-IT'}}</b> </span>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
