<nav class="navbar navbar-expand-md  fixed-top border-bottom bg-dark navbar-dark">

    <div class="container-fluid">
        <button class="btn btn-link text-white mr2-auto" routerLink="..">
            <i class="fas fa-chevron-left"></i>
        </button>
        <a class="navbar-brand" href="#">
            &nbsp;<b>Fascicolo {{lead?.tracking_code}}</b>
        </a>
        <button class="navbar-toggler navbar-toggler-right border-0" type="button" data-bs-toggle="collapse"
            data-target="#navbar4" style="">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse " id="navbar4">
            <button class="btn btn-link text-white ms-auto" (click)="delete()"><i class="fa fa-trash"></i></button>
        </div>
    </div>
    <bar-loader [loading]="loading"></bar-loader>
</nav>


<div class="container-fluid p-5" style="margin-top: 65px" *ngIf="lead">
    <div class="row">
        <div class="col-md-4">

            <div class="card">
                <!-- <div class="card-header d-flex align-items-center justify-content-between">
                            Informazioni 
                        </div> -->

                <div class="card-body">

                    <h2 class="mb-3" *ngIf="getStage().sequence > 1">{{lead.name}}</h2>
                    <input class="form-control form-control-lg mb-3" placeholder="Un titolo*" required
                        (change)="updateDealToOdoo('name')" *ngIf="getStage().sequence == 1" [(ngModel)]="lead.name">

                    <!-- <h2 class="mb-3">{{lead.name}}</h2> -->

                    <div class="form-group row mb-3">
                        <label class="col-sm-3 col-form-label">Settore</label>
                        <div class="col-sm-9">
                            <input disabled class="form-control" [value]="lead.area">
                        </div>
                    </div>

                    <div class="form-group row mb-3">
                        <label class="col-sm-3 col-form-label">Cliente</label>
                        <div class="col-sm-9">
                            <div class="flex-nowrap d-flex">
                                <a class="form-control disabled h-100" href="/contact/{{lead.partner_id.id}}"
                                    target="_blank">{{lead.partner_id.name}}</a>
                                <!-- NUOVO DEAL -->
                                <div class="dropdown dropright ms-auto">
                                    <button class="btn btn-link px-1" type="button" id="dropdownMenu"
                                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fal fa-bullseye"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right"
                                        style="min-width: 300px;z-index:9999999">
                                        <form class="px-4 py-3" style="min-width: 440px;">
                                            <app-contact [mode]="'embedded'" class="embedded"
                                                (onSelect)="onContact($event)"></app-contact>
                                        </form>
                                    </div>
                                </div>
                                <!-- new deal -->
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Indirizzo*</label>
                        <div class="col-sm-9">
                            <input ngx-google-places-autocomplete required class="form-control"
                                (onAddressChange)="onAddressChange($event)" [(ngModel)]="lead.street">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Città</label>
                        <div class="col-sm-9">
                            <input class="form-control" disabled [(ngModel)]="lead.city">
                        </div>
                    </div>

                    <!-- <div class="form-group mb-3">
                                <textarea class="form-control" placeholder="Note" (change)="updateDealToOdoo('description')" rows="4" [(ngModel)]="lead.description"></textarea>
                            </div> -->

                    <!-- <app-connect-to 
                            [deal]="lead"
                            [gmailTC]="'F' + lead.id"
                            >
                            </app-connect-to> -->

                </div>
            </div>
            <br>
            <!-- <div class="card">
                        <div class="card-header d-flex align-items-center justify-content-between">
                            Messaggi
                        </div>
                   
                        <div class="card-body">
                            <a href="https://mail.google.com/mail/u/0/#search/L{{lead?.id}}" class="btn me-3 text-center" target="_blank">
                                <i class="fad fa-envelope text-primary fa-3x"></i>
                                <br>GMail
                            </a>
                            <a href="https://groups.google.com/a/galimberti.eu/g/odoo/search?q=L{{lead?.id}}" class="btn me-3 text-center" target="_blank">
                                <i class="fad fa-users text-primary fa-3x"></i>
                                <br>Groups
                            </a>
                        </div>
                       
                   
                        
                    </div> -->
        </div>


        <div class="col-md-4">
            <!-- PREVENTIVO -->
            <div *ngIf="!lead.name || !lead.city">
                <i class="fa fa-arrow-left"></i>&nbsp;Compila
            </div>
            <div *ngIf="lead.name && lead.city">

                <div class="card card-stage card-stage-sibling mb-4">
                    <div class="card-header d-flex align-items-center">
                        &nbsp;Preventivo
                    </div>
                    <div class="card-body text-center d-flex" *ngIf="getStage().sequence == 1">
                        <button class="btn btn-link w-100" [disabled]="!lead.name || !lead.city "
                            (click)="moveToStatus(getNextStage())">
                            Crea
                        </button>
                    </div>

                    <div class="card-body text-center d-flex" *ngIf="getStage().sequence > 1">
                        <app-connect-to type="Preventivo" [deal]="lead" [driveSource]="cfg?.src"
                            [driveTarget]="cfg?.dst" [driveFolders]="filterDriveFolders('Preventivo')"
                            [trelloCards]="filterTrelloCards('Preventivo')" [trelloListId]="cfg?.preventivo_trello_list"
                            [trelloBoardId]="cfg?.preventivo_trello_board" [connectable]="lead"
                            (onConnect)="loadDeal()"></app-connect-to>
                    </div>

                </div>


                <!-- Progetto E POS -->
                <div class="d-flex align-items-start">

                    <!-- Progetto -->
                    <div class="card card-stage card-stage-sibling mb-4">
                        <div class="card-header d-flex align-items-center">
                            &nbsp;Progetto
                        </div>
                        <div class="card-body text-center d-flex"
                            *ngIf="getStage().sequence == 1 && insertingTrello != 'progetto'">
                            <button class="btn btn-link w-100" (click)="moveToStatus(getNextStage())">
                                Crea
                            </button>
                        </div>

                        <div class="card-body text-center d-flex flex-wrap"
                            *ngIf="getStage().sequence > 1 && insertingTrello != 'progetto'">
                            <app-connect-to type="Progetto" [deal]="lead" [connectable]="lead"
                                [driveSource]="cfg?.project_src" driveIsMerge="true"
                                [driveTarget]="filterDriveFolders('Preventivo')[0]?.value"
                                [trelloTemplateCard]="filterTrelloCards('Preventivo')[0]?.card_id"
                                [driveFolders]="filterDriveFolders('Progetto')"
                                [trelloCards]="filterTrelloCards('Progetto')" [trelloListId]="cfg?.project_trello"
                                [trelloBoardId]="cfg?.project_trello_board" [skipAttachments]="true"
                                (onConnect)="loadDeal()"></app-connect-to>
                        </div>
                    </div>



                </div> <!--progetto e pos-->

                <div class="d-flex align-items-center">

                    <!-- Produzione -->
                    <div class="card card-stage card-stage-sibling card-stage-child mb-4">
                        <div class="card-header px-4 d-flex align-items-center justify-content-between">
                            <span class="">Vendite</span>

                            <!-- nuova produzione -->
                            <div class="dropdown ms-auto">

                                <button class="btn btn-link text-white dropdown-toggle" type="button" id="dropdownMenu2"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fa fa-plus text-primary"></i>
                                </button>

                                <div class="dropdown-menu" style="min-width: 300px;" aria-labelledby="dropdownMenu2">
                                    <form class="px-4 py-3" style="min-width: 440px;">
                                        <div class="form-group">
                                            <label>Nome</label>
                                            <input class="form-control" [(ngModel)]="newSaleName" name="newSaleName">
                                            <br>
                                            <button class="btn btn-primary text-white" (click)="attachNewSale()">
                                                Crea
                                            </button>
                                        </div>
                                    </form>
                                </div>


                            </div>
                            <!-- nuova produzione -->
                        </div>


                        <table class="table table-flush">
                            <tr *ngFor="let s of sales">
                                <td class="px-4">
                                    <div class="d-flex align-items-center justify-content-between">

                                        <a class="lead" href="leads/{{lead.id}}/sale/{{s.id}}">{{s.name}}
                                            <span *ngIf="s.state == 'draft'"
                                                class="badge badge-muted bg-warning">{{s.state}}</span>

                                        </a>
                                        <app-connect-to [connectable]="s" type="Produzione" [deal]="lead"
                                            [driveFolders]="filterProductionDriveFolders(s)"
                                            [driveSource]="cfg?.produzione_src" [driveTarget]="cfg?.produzione_dst"
                                            [driveLinkIn]="filterDriveFolders('Preventivo')[0]?.value"
                                            [driveLinkTitle]="cfg.produzione_link_name + ' > ' + s.name + '  + p.title'"
                                            [driveAdditionalTitle]="' > ' + s.name + ' + p.title' "
                                            [trelloCards]="filterProductionTrelloFolders(s)"
                                            [trelloListId]="cfg?.produzione_trello"
                                            [trelloBoardId]="cfg?.produzione_trello_board"
                                            [trelloAtachments]="[['Prelievo', 'https://m.galimberti.eu/leads/' + lead.id + '/production/' + s.id ]]"
                                            (onConnect)="loadDeal()"></app-connect-to>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-between my-4">
                                        <label class=" d-block-inline w-100 ">Deadline</label>
                                        <div class=" "><input type="datetime-local" class="form-control border"></div>
                                    </div>

                                </td>


                            </tr>



                        </table>

                        <!-- <table class="table table-flush" >
                                <tr *ngFor="let p of productions ">
                                    <td class="align-middle w-100 ps-4 lead">
                                        <div class="d-flex flex-column">

                                        <a class="mb-4" href="leads/{{lead.id}}/production/{{p.id}}">
                                        {{p.name}} - {{p.title}}
                                        </a>

                                        <div class="d-flex flex-row">
                                            <a 
                                                class="btn " 
                                            >
                                                <i class="fa fa-memo-pad text-primary" [ngClass]="customSize ? customSize : 'fa-3x'"></i><br> Nota
                                            </a>
    
                                            <app-connect-to 
                                                [connectable]="p"
                                                type="Produzione"
                                                [deal]="lead"
                                                [driveFolders]="filterProductionDriveFolders(p)"
                                                [driveSource]="cfg?.produzione_src"
                                                [driveTarget]="cfg?.produzione_dst"
                                                [driveLinkIn]="filterDriveFolders('Preventivo')[0]?.value"
                                                [driveLinkTitle]="cfg.produzione_link_name + ' > ' + p.name + ' ' + p.title"
                                                [driveAdditionalTitle]="' > ' + p.name + ' ' + p.title"
                                                [trelloCards]="filterProductionTrelloFolders(p)"
                                                [trelloListId]="cfg?.produzione_trello" 
                                                [trelloBoardId]="cfg?.produzione_trello_board" 
                                                [trelloAtachments]="[['Prelievo', 'https://m.galimberti.eu/leads/' + lead.id + '/production/' + p.id ]]"
                                                (onConnect)="loadDeal()"
    
                                            ></app-connect-to>
    
                                           </div> 
                                        </div>
                                    </td>
                                    
                                </tr>
                            </table> -->
                    </div>
                    <!-- fine produzione -->


                    <!-- Acquisti -->
                    <!-- <div class="card card-stage card-stage-last mb-4 " style="width:100px">
                            <div class="card-header d-flex align-items-center justify-content-between">
                                <span clas="">Acquisti</span>
                                
                                <div class="dropdown ms-auto">
                                    <button class="btn btn-link text-white" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fa fa-plus text-primary"></i>
                                    </button>
                                    <div class="dropdown-menu" style="min-width: 300px;">
                                    <form class="px-4 py-3" style="min-width: 440px;">
                                        <div class="form-group">
                                            <label>Nome</label>
                                            <input class="form-control" [(ngModel)]="newCalendarEventName" name="newpurchase">
                                            <br>
                                            <label>Fornitore</label>
                                            <app-contact mode="embedded" class="embedded" (onSelect)="contactForPurchase = $event"></app-contact>
                                            <br>
                                            <button 
                                                class="btn btn-primary text-white"
                                                [disabled]="!contactForPurchase || !newCalendarEventName"
                                                (click)="attachPurchase()" 
                                            >
                                                Crea
                                            </button>
                                        </div>
                                    </form>
                                    </div>
                                </div>


                            </div>
                            
                            <div class="card-body text-center d-flex p-0 "   >

                                <table class="table table-flush text-left" >
                                    <tr *ngFor="let p of purchaseOrders| sortBy: name : asc">
                                        
                                        <td class="align-middle w-100 ps-4 lead">
                                            A{{p.id}} - {{p.name}}
                                            <br>
                                            <small class="text-muted">{{p.partner_id.name}}</small>
                                        </td>
                                        <td>
                                            <app-connect-to 
                                                type="Acquisto"
                                                [deal]="lead"
                                                [driveSource]="cfg?.purchase_drive_src"
                                                [driveTarget]="cfg?.purchase_drive_dst"
                                                [driveTargetTitle]='p.partner_id.name +  " - " + p.name + " A" + p.id  + " > " + lead.partner_id.name + " - " + lead.city + " - " + lead.name +  " F" + lead.id' 
                                                [driveLinkIn]="lead.drive_link_docs"
                                                [driveLinkTitle]="cfg.purchase_link_name + ' > A' + p.id + ' ' +  p.name"
                                                [trelloCards]="null"
                                                [trelloListId]="null" 
                                                [trelloBoardId]="null" 
                                                [trelloTemplateCard]="null"
                                                [gmailTC]="'F' + lead.id + '-A' + p.id"
                                                [connectable]="p"
                                                (onConnect)="loadDeal()"
                                            ></app-connect-to>

                                            
                                            <a href="leads/{{lead.id}}/purchases/{{p.id}}" class="btn me-3 text-center">
                                                <i class="fad fa-clipboard-list text-primary fa-3x"></i>
                                                <br>Nota
                                            </a>
                                            
                                            
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div> -->
                    <!-- fine acquisti -->



                    <div class="card card-stage card-stage-last mb-4 " style="width:100px">
                        <div class="card-header d-flex align-items-center justify-content-between">
                            <span>Budget</span>
                        </div>

                        <div class="card-body text-center d-flex p-0 ">

                            <app-deal-budget style="min-width: 100%" *ngIf="cfg?.pos_src"
                                [productions]="productions"></app-deal-budget>
                        </div>

                    </div>
                </div>



                <!-- Calendario costi -->
                <!-- <div class="card card-stage card-stage-sibling mb-4">
                    <div class="card-header d-flex align-items-center justify-content-between">
                        <span clas="">Cantiere</span>

                        <div class="dropdown ms-auto" >
                        <button class="btn btn-link text-white" (click)="calendarEventModal = !calendarEventModal" type="button" id="dropdownMenu3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-plus text-primary"></i>
                        </button>
                        <div id="dm3" class="dropdown-menu" style="min-width: 300px;"  >
                        <form class="px-4 py-3" style="min-width: 440px;">
                            <div class="form-group">
                                <label>Nome</label>
                                <input class="form-control" [(ngModel)]="newCalendarEventName" name="newCalendarEventName">
                            
                            </div>


                            <div class="form-group">
                                <label>Data</label>
                                <input class="form-control" type="date" [(ngModel)]="newCalendarEventDate" name="newCalendarEventDate">
                            
                            </div>

                            <div class="form-group">
                                <label>Note</label>
                                <textarea name="newCalendarEventNote" [(ngModel)]="newCalendarEventNote" class="form-control">
                                </textarea>
                            </div>
                            

                            <button 
                            class="btn btn-primary text-white"
                            [disabled]="!newCalendarEventName"
                            (click)="attachCalendarEvent()" 
                        >
                            Crea 
                        </button>

                        </form>
                        </div>
                    </div>
                </div>

                   

                    <div class="card-body text-center d-flex flex-wrap" *ngIf="getStage().sequence > 1" >
                        <app-connect-to 
                                type="POS"
                                [deal]="lead"
                                [driveSource]="cfg?.pos_src"
                                [driveTarget]="cfg?.pos_dst"
                                [driveLinkIn]="lead.drive_link_docs"
                                [driveLinkTitle]="cfg.pos_link_name"
                                [driveFolders]="filterDriveFolders('POS')"
                                [trelloCards]="filterTrelloCards('POS')"
                                [trelloListId]="cfg?.pos_trello_list" 
                                [trelloBoardId]="cfg?.pos_trello_board" 
                                [trelloTemplateCard]="cfg?.pos_template_card"
                                [connectable]="lead"
                                (onConnect)="loadDeal()"
                                (loading)="loading = $event"
                        ></app-connect-to>
                    </div>

                    <app-deal-calendar-list [update]="updateCalendarList" [deal]="lead"></app-deal-calendar-list>

                </div> -->
                <!-- fine Calendario -->


                <!-- <div class="card card-stage mb-4">
                    <div class="card-header d-flex align-items-center justify-content-between">
                        <span clas="">Fatturazione</span>
                        
                    </div>

                    <div class="card-body text-center d-flex p-0 "   *ngIf="existingsale">
                        <table class="table table-flush text-left" >
                            <tr >
                                <td class="align-middle w-100 ps-4 lead">
                                    V{{existingsale.tracking_code}}
                                    <br>
                                </td>
                                <td></td>
                            </tr>
                        </table>
                    </div>
                </div> -->

                <!-- Note per bolle -->
                <!-- <div class="card card-stage mb-4">
                    <div class="card-header d-flex align-items-center justify-content-between">
                        <span clas="">Spedizioni</span>
                        <button class="btn btn-link" (click)="attachProduzione()" *ngIf="getStage().sequence > 2 ">
                            <i class="fa fa-plus"></i>
                        </button>
                    </div>
                </div> -->



                <!-- Note per bolle -->
                <!-- <div class="card card-stage mb-4">
                        <div class="card-header d-flex align-items-center justify-content-between">
                            <span clas="">Cantieri</span>
                            <button class="btn btn-link" (click)="attachProduzione()" *ngIf="getStage().sequence > 2 ">
                                <i class="fa fa-plus"></i>
                            </button>
                        </div>
                    </div> -->
                <!-- fine produzione -->
            </div>
        </div>