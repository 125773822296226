import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SaleOrderLine } from '../../models/sale-order-line.model';
import { TableEmitterFormat } from '../sale-order-table/sale-order-table.component';
import { PurchaseOrderLine } from '../../models/purchase-order-line.model';
import { PurchaseOrder } from '../../models/purchase-order.model';

@Component({
  selector: 'app-purchase-order-table',
  templateUrl: './purchase-order-table.component.html',
})
export class PurchaseOrderTableComponent implements OnInit {

  @Input() purchaseOrderLines: PurchaseOrderLine[];
  @Input() purchaseOrder: PurchaseOrder;
  @Output() updateOrderLineEmitter: EventEmitter<TableEmitterFormat> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  updateOrderLine(field?: string, orderLine?: PurchaseOrderLine) {
    console.log("tab 2 update line")
    this.updateOrderLineEmitter.emit({field, orderLine});
  }

  onEnter($event): void {
  }

  addNote(saleOrderLine: SaleOrderLine): void {
  }

}
