<table class="table table-bordered tableFixHead  table-hover " id="order">
  <thead>
  <tr>
    <th colspan="3" scope="colgroup" class="table-light">Prodotto</th>
    <th colspan="3" scope="colgroup" class="table-info">Dimensioni</th>
    <th colspan="2" scope="colgroup" class="table-light">Qt richieste</th>
    <th colspan="3" scope="colgroup" class="table-info">Qt lorde</th>
    <th colspan="4" scope="colgroup" class="table-light">Prezzo</th>
    <th colspan="2" scope="colgroup" class="table-info">Acquisto</th>
  </tr>
  <tr>
    <th class="table-light">ID</th>
    <th class="table-light">Descrizione</th>
    <th class="table-light">M/H/F</th>
    <th class="table-info">Larg</th>
    <th class="table-info">Spes</th>
    <th class="table-info">Lung</th>
    <th class="table-light">Unità</th>
    <th class="table-light">UM</th>
    <th class="table-info">Unità</th>
    <th class="table-info">UM</th>
    <th class="table-info">Desc</th>
    <th class="table-light">Prezzo</th>
    <th class="table-light">Sconto<br></th>
    <th class="table-light">Finale</th>
    <th class="table-light">Totale</th>
    <th class="table-info">Fornitore</th>
    <th class="table-info">Ordine</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let saleOrderLine of saleOrderLines ; index as i" data-print-row>

    <th>
      <input type="checkbox" [checked]="saleOrderLine.checked"
             (change)="saleOrderLine.checked = !saleOrderLine.checked; updateOrderLine()">
      <a data-print-col class="text-dark ms-3" target="_blank"
         href="/inventory/search/{{saleOrderLine.default_code}}">{{saleOrderLine.default_code}}</a>
    </th>
    <td data-print-col>
      <a class="text-dark" target="_blank" (click)="addNote(saleOrderLine)">{{ saleOrderLine.name}}</a>
    </td>

    <td class="editable text-right" data-print-col>
      <input [disabled]="saleOrder.state !== 'draft' && saleOrder.state !== 'sent'" type="text" pattern="[MHFmhf]+" (keyup.enter)="onEnter($event)"
             (change)="updateOrderLine('working_code',saleOrderLine)" [size]="1"
             [(ngModel)]="saleOrderLine.working_code">
    </td>

    <!-- DIMENSIONI-->
   

    <td *ngIf="saleOrderLine.larghezza_editable" class="editable text-right">
      <input-number
        data-print-col
        [disabled]="saleOrder.state !== 'draft' && saleOrder.state !== 'sent'"
        (change)="updateOrderLine('larghezza',saleOrderLine)"
        [(ngModel)]="saleOrderLine.larghezza"></input-number>
      <span class="badge badge-muted">{{saleOrderLine.larghezza_uom.name}}</span>
    </td>

    <td *ngIf="!saleOrderLine.larghezza_editable" class="text-right" data-print-col>
    </td>

    <!-- <td *ngIf="saleOrderLine.spessore_editable" class="editable text-right">
      <input-number
        data-print-col
        [disabled]="saleOrder.state !== 'draft' && saleOrder.state !== 'sent'"
        (change)="updateOrderLine('spessore', saleOrderLine)"
        [(ngModel)]="saleOrderLine.spessore"></input-number>
      <span class="badge badge-muted">{{saleOrderLine.spessore_uom.name}}</span>
    </td>

    <td *ngIf="!saleOrderLine.spessore_editable" class="text-right" data-print-col>
    </td>

    <td *ngIf="saleOrderLine.lunghezza_editable" class="editable text-right">
      <input-number
        data-print-col
        [disabled]="saleOrder.state !== 'draft' && saleOrder.state !== 'sent'"
        (change)="updateOrderLine('lunghezza', saleOrderLine)"
        [(ngModel)]="saleOrderLine.lunghezza"></input-number>
      <span class="badge badge-muted">{{saleOrderLine.lunghezza_uom.name}}</span>
    </td> -->

    <td *ngIf="!saleOrderLine.lunghezza_editable" class="text-right" data-print-col>
    </td>


    <!-- QT RICHIESTE -->
    <!-- PEZZI -->
    <td class="editable text-right" >
      <input-number
        [disabled]="saleOrder.state !== 'draft' && saleOrder.state !== 'sent'"
        data-print-col
        class="text-right"
        [(ngModel)]="saleOrderLine.pezzi"
        (change)="updateOrderLine('pezzi', saleOrderLine)"
      ></input-number>
      <!--       <input data-print-col type="number" [disabled]="order.state != 'draft'" pattern="[\d\.]+" (keyup.enter)="onEnter($event)" (change)="updateOrderLineToOdoo('pezzi', c)" [size]="c.pezzi ? c.pezzi.length : 1" [(ngModel)]="c.pezzi"> -->
      <span class="badge badge-muted">Pz</span>
    </td>
    <!-- UM -->
    <td class="editable text-right"
        data-print-col>
      <input-number
        [disabled]="true"
        (change)="updateOrderLine('product_uom_qty', saleOrderLine)"
        [(ngModel)]="saleOrderLine.product_uom_qty"></input-number>
      <span class="badge badge-muted">{{saleOrderLine.product_uom.name}}</span>
    </td>

    <!-- QT LORDE -->

    <!-- PEZZI -->
    <td class="text-right" data-print-col>
      <span>{{saleOrderLine.qty_lorda_so_pz}}</span>
      <span class="badge badge-muted">Pz</span>
    </td>
    <!-- UM -->
    <td class="text-right" data-print-col>
      <span>        
        {{saleOrderLine.getQtyLordaUOM() | number : '1.0-3':'it-IT' }}</span>
      <span class="badge badge-muted">{{saleOrderLine.product_uom.name}}</span>
    </td>

    <!-- DESCRITTIVA -->
    <td class="text-right" data-print-col>
      <span>        
        {{saleOrderLine.getQtyLordaUOM() | number : '1.0-3':'it-IT' }}</span>
      <span class="badge badge-muted">{{saleOrderLine.product_uom.name}}</span>
    </td>
    <!-- <td class="text-right" data-print-col>
      <span >{{saleOrderLine.confezioni_qty_lorda | number : '1.0-3':'it-IT' }}</span>
      <span class="badge badge-muted">{{saleOrderLine.product_packaging.name}}</span>
    </td> -->

    <!-- PREZZO -->
    <td class="editable text-right" data-print-col>
      <input-number class="text-right"
                    [disabled]="saleOrder.state !== 'draft' && saleOrder.state !== 'sent'"
                    [(ngModel)]="saleOrderLine.price_unit"
                    (change)="updateOrderLine('price_unit', saleOrderLine)"></input-number>
      <span class="badge badge-muted">€</span></td>
    <td class="editable text-right" data-print-col>
      <input-number
        [disabled]="saleOrder.state !== 'draft' && saleOrder.state !== 'sent'"
        (change)="updateOrderLine('discount', saleOrderLine)"
        [(ngModel)]="saleOrderLine.discount"></input-number>
      <span class="badge badge-muted">%</span>
    </td>
    <td class="text-right" data-print-col>
      <span>{{saleOrderLine.price_reduce| number : '1.0-3':'it-IT'}}</span>
      <span class="badge badge-muted">€</span>
    </td>
    <td class="text-right" data-print-col>
      <span>{{saleOrderLine.price_subtotal | number : '1.0-3':'it-IT'}}</span>
      <span class="badge badge-muted">€</span>
    </td>
    <!-- acquisto -->
    <td data-print-col>
      <a href="/contact/{{saleOrderLine?.supplier_purchase_id?.id}}"
         target="_blank">{{saleOrderLine?.supplier_purchase_id?.name}}</a>
    </td>
    <td data-print-col>
      <a *ngIf="saleOrderLine.purchase_id?.id" routerLink="../../purchases/{{saleOrderLine.purchase_id?.id}}" target="_blank">
        PO{{saleOrderLine.purchase_id?.id}}
      </a>
    </td>
  </tr>
  </tbody>
</table>
