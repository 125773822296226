


<ul class="nav nav-pills bg-dark p-2" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <a class="nav-link  active px-3" id="home-tab" data-toggle="tab" data-target="#camera" type="button" role="tab" aria-controls="home" aria-selected="true">
        <i class="fa  fa-2x fa-camera"></i>
      </a>
    </li>

    <li class="nav-item" role="presentation">
        <a (click)="scan()"class="nav-link px-3" id="contact-tab" data-toggle="tab" data-target="#scanner">
          <i class="fa fa-2x fa-scanner-gun"></i>
        </a>
      </li>

    <li class="nav-item" >
      <a class="nav-link px-3" id="profile-tab" data-toggle="tab" data-target="#hand">
        <i class="fa fa-2x fa-search"></i>
      </a>
    </li>
    
    <li class="nav-item ms-auto me-3" >
      <a class="nav-link px-3" id="profile-tab" (click)="cancel()">
        <i class=" fa fa-2x text-white fa-close" ></i>
      </a>
    </li>
  </ul>

  <div class="tab-content" id="myTabContent">
    
    <div class="tab-pane fade show active" id="camera">
        <app-scanner [canCreate]="true" (onCode)="onCode($event)"></app-scanner>
    </div>

    <div class="tab-pane fade  h-100 justify-content-center align-items-center flex-column" id="scanner">
      <i class="fa fa-bounce fa-4x fa-scanner-gun text-primary mb-5"></i>
      <input type="text" class="form-control" (input)="textInput.next($event.target.value)" style="max-width: 200px;">
    </div>
    
    <div class="tab-pane fade" id="hand">
        <table class="table">
            <tr>
              <th>Qt disponibile</th>
              <th>Ubicazione</th>
              <th>Pacco</th>
            </tr>
        </table>
    </div>
  </div>


<div class="d-flex flex-column result justify-content-end" *ngIf="code">

    <div *ngIf="!needToCreate" class="code p-3 bg-success text-white d-flex flex-row" (click)="onCode(code)">
        <div class="" >Pacco {{code}}  </div>
        <!-- <i class="fa fa-close fa-3x"></i> -->
    </div>

    <!-- <div *ngIf="needToCreate" class="code p-3 bg-warning text-white d-flex flex-row">
      <div class="" >Il pacco {{code}} non esiste </div>
  </div> -->

    <div *ngIf="needToCreate" class="bg-warning p-3 d-flex justify-content-between mt-3" (click)="create()">
        Crea pacco {{code}}
        <!-- <button class="btn btn-outline-primary btn-lg text-white" (click)="create()">Crea</button> -->
    </div>

    <div  class="p-3 d-flex justify-content-between mt-3 bg-dark" (click)="code = null; needToCreate = null">
        Scansione un altro codice
    </div>

</div>

