<app-navbar  [loading]="loading">
    <a class="navbar-brand">
      Trasferimenti
    </a>
</app-navbar>
<div class="navbar bg-light px-3">
    <div class="form d-flex flex-row align-items-center w-100">
        <span>Cerca</span>
        <input class="form-control mx-3 w-100" >



   
        <!-- <span>Tipo</span><select class="form-control mx-3">
            <option>Tutti</option>
            <option>Ricezione</option>
            <option>Tipo</option>
            <option>Tipo</option>
        </select> -->

        <div class="btn-group ml-aa2uto" role="group" aria-label="Basic checkbox toggle button group">
            <input 
                (change)="toggleFilter('purchase')"
                type="checkbox" class="btn-check" id="btncheck1" autocomplete="off" [checked]="filters.purchase">
            <label class="btn btn-outline-primary text-dark" for="btncheck1">Acquisti</label>
    
            <input 
                (change)="toggleFilter('sale')"
                type="checkbox" class="btn-check" id="btncheck2" autocomplete="off">
            <label class="btn btn-outline-primary text-dark" for="btncheck2">Vendite</label>
    
        </div>
    </div>

     

</div>

  <div [hidden]="loading" class="list-group" >

    <table class="table table-bordered align-middle">
        <thead class="bg-light">
            <tr>
                <th>Nome</th>
                <th>Riferimento</th>
                <th>Tipo</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let p of pickings" [routerLink]="['p',p.id]">
                <td class="text-muted"  >
                    <span class="fa fa-circle {{getPickingClass(p)}}" >&nbsp;</span> &nbsp;
                    {{p.name}}
                </td>
                <td >
                    <span *ngIf="p.group_id.value && p.group_id.value.sale_id.id">
                    {{p.group_id.value?.sale_id.value?.name}} - 
                    {{p.group_id.value?.sale_id.value?.partner_id?.name}}
                    </span>
                    <span *ngIf="p.group_id.value && !p.group_id.value.sale_id.id">
                        {{p.origin}} -
                        {{p.partner_id?.name}}
                    </span>
                </td>
                
                <td class="text-muted">
                    <span>{{p.picking_type_id.name.split(":")[1]}}</span>
                </td>
            </tr>
        </tbody>
        
    </table>
  </div>
