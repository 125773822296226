import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Contact } from '../../models/contact.model';
import { AccountPaymentTerm } from '../../models/account-payment-term.model';
import { User } from '../../models/user.model';
import { AccountIncoTerm } from '../../models/account-inco-term.model';
import { SaleOrder } from '../../models/sale-order.model';
import { PurchaseOrder } from '../../models/purchase-order.model';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-info-side-order',
  templateUrl: './info-side-order.component.html',
})
export class InfoSideOrderComponent implements OnInit, OnChanges {
  @Input() referentContacts: Contact[];
  @Input() paymentTerms: AccountPaymentTerm[];
  @Input() incoTerms: AccountIncoTerm[];
  @Input() users: User[];
  @Input() infoSideClosed = true;
  @Output() toggleInfoSide = new EventEmitter();
  @Output() updateOrderToOdoo = new EventEmitter<{ order, field }>();
  @Input() order: SaleOrder | PurchaseOrder; // SaleOrder or PurchaseOrder

  datepickerOrder: NgbDateStruct;
  isPurchaseOrder = false;

  constructor() {
  }

  ngOnInit() {
    const utc = moment();
    this.datepickerOrder = {year: utc.year(), month: utc.month() + 1, day: Number(utc.format('DD'))};
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.order && changes.order.currentValue) {
      this.isPurchaseOrder = !(this.order instanceof SaleOrder);
    }
  }

  closeInfoSide(): void {
    console.log('XXX close');
    // if (this.infoSideClosed)
    //   return
    this.toggleInfoSide.emit();
  }

  updateOrder(order, field): void {
    this.updateOrderToOdoo.emit({order, field});
  }

  onDateSelected(event) {
    if (this.order instanceof SaleOrder) {
      return;
    }
    const result = {
      day: parseInt(event.day, 10),
      month: parseInt(event.month, 10),
      year: parseInt(event.year, 10)
    };
    this.order.date_planned = `${result.year}-${result.month}-${result.day}`;
    this.updateOrder(this.order, 'date_planned');
  }

}
