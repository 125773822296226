import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ContactSearchComponent } from './contact/contact-search.component';
import { ContactDetailComponent } from './contact/contact-detail/contact-detail.component';
import { EditContactDetailComponent} from './contact/contact-edit/edit-contact-detail.component';
import { PickingSearchComponent } from './picking-search/picking-search.component';
import { DealsDashboardComponent } from './deals/deals-dashboard/deals-dashboard.component';
import { DealDetailComponent } from './deals/deal-detail/deal-detail.component';
import { PackageDetailComponent } from './sale-picking/package-detail/package-detail.component';
import { SearchComponent } from './search/search.component';
import { SearchDetailComponent } from './search/search-detail/search-detail.component';
import {CallbackComponent} from './callback/callback.component';
import { SaleOrderDetailComponent } from './components/sale-order-detail/sale-order-detail.component';
import { PurchaseOrderDetailComponent } from './components/purchase-order-detail/purchase-order-detail.component';
import { ProductionDetailComponent } from './production/production-detail/production-detail.component';
import { GmailImportComponent } from './gmail/gmail-import/gmail-import.component';
import { OdoorpcService } from './shared/services/odoorpc.service';
import { PickingEditorPage } from './picking/picking-editor-page/picking-editor-page.component';
import { SaleOrderEditorComponent } from './sale-order/sale-order-editor/sale-order-editor.component';
import { BadgeKioskComponent } from './badge-kiosk/badge-kiosk.component';
import { TimeOffComponent } from './time-off/time-off.component';
import { ForkliftPageComponent } from './forklift/forklift-page/forklift-page.component';
import { StatusComponent } from './status/status.component';
import { PurchasePrintComponent } from './purchase-print/purchase-print.component';

export const routes: Routes = [
  {
    path: 'sid/:sid',
    component: LoginComponent,
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [OdoorpcService]
  },
  {
    path: 'badge',
    component: BadgeKioskComponent
  },
  {
    path: 'time-off/:employee_id',
    component: TimeOffComponent
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [OdoorpcService]
  },
  {
    path: 'callback',
    component: CallbackComponent
  },
  {
    path : "contact/new",
    component: EditContactDetailComponent,
    canActivate: [OdoorpcService]
  },
  {
    path: 'contact/:id/new', component: EditContactDetailComponent,
    canActivate: [OdoorpcService]
  },
  {
    path: 'forklift/:id', 
    component: ForkliftPageComponent
  },
  {
    path : 'status',
    component : StatusComponent
  },
  {
    path: 'contact', component: ContactSearchComponent,
    canActivate: [OdoorpcService],
    children: [
      {
        path: ':id',
        component: ContactDetailComponent,
        // canActivate: [OdoorpcService]
      },

      {
        path: ':id/edit', component: EditContactDetailComponent,
        canActivate: [OdoorpcService]
      },
      {
        path: ':pid/:id', component: ContactDetailComponent,
        canActivate: [OdoorpcService]
      },
      {
        path: ':pid/:id/edit', component: EditContactDetailComponent,
        canActivate: [OdoorpcService]
      },
    ]
  },
  {
    path: 'leads/gmail/:mailid', component: GmailImportComponent,
    canActivate: [OdoorpcService]
  },
  {
    path: 'leads/:id/sales/:pid', component: SaleOrderDetailComponent,
    canActivate: [OdoorpcService]
  },
  {
    path: 'leads/:id/purchases/:pid', component: PurchaseOrderDetailComponent,
    canActivate: [OdoorpcService]
  },
  {
    path: 'leads/:id/production/:pid',
    component: ProductionDetailComponent,
    canActivate: [OdoorpcService]
  },
  {
    path: 'leads/:id/sale/:order_id',
    component: SaleOrderEditorComponent,
    canActivate: [OdoorpcService]
  },
  {
    path: 'leads/:id',
    component: DealDetailComponent,
    canActivate: [OdoorpcService]
  },
  {
    path: 'leads',
    component: DealsDashboardComponent,
    canActivate: [OdoorpcService],
  },
  {
    path: 'pickings/:type',
    component: PickingSearchComponent,
    canActivate: [OdoorpcService]
  },
  {
    path: 'pickings/:type/p/:id',
    component: PickingEditorPage,
    canActivate: [OdoorpcService]
  },
  {
    path: 'picking', component: PickingSearchComponent,
    canActivate: [OdoorpcService]
  },
  {
    path: 'picking/:id', component: PickingEditorPage,
    canActivate: [OdoorpcService]
  },
  {
    path: 'picking/:id/package/:idpackage', component: PackageDetailComponent,
    canActivate: [OdoorpcService]
  },
  {
    path: 'search/search-result/:id', component: SearchDetailComponent,
    canActivate: [OdoorpcService]
  },
  {
    path: 'search', component: SearchComponent,
    canActivate: [OdoorpcService]
  },
  {
    //m2.galimberti.eu/purchaes/191/a/print
    path: 'purchases/:id/a/print',
    component: PurchasePrintComponent,
    // <table> <col acquisti >
  },
  {
      path: '',
      redirectTo: '/home',
      pathMatch: 'full'
  }
];


@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
