<nav class="navbar text-white navbar-dark fixed-top px-1">
  <div *ngIf="mode != 'embedded'" class="d-flex w-100">
    <button   class="btn btn-link text-white me-auto" [routerLink]="['/home']">
      <i class="fas fa-chevron-left"></i>      
    </button>

    <h1  class="me-auto mr-auto text-center">Ricerca</h1>
  </div>
</nav>


<div class="p-0" style="margin-top:70px">
  <app-picking-package-location-picker *ngIf="!package"  (onPackage)="onPackageOrig($event)"></app-picking-package-location-picker>

  <div class="card" *ngIf="package">

    <div class="card-header d-flex align-items-center">

      {{package.name}} - {{package.location_id.name}}
    </div>
    <div class="card-body" >
      <h3>Contenuto</h3>
      <table class="table">
        <thead>
            <td>Prodotto</td>
            <td>Numero di lotto</td>
            <td>Quantità</td>
            <td>Unità di misura</td>
            
        </thead>
        <tbody>
            <tr *ngFor="let q of package?.quant_ids.values">
                <td>{{q.product_id.name}}</td>
                <td>{{q.lot_id.name}}</td>
                <td>{{q.quantity}}</td>
                <td>{{q.product_uom_id.name}}</td>

            </tr>
        </tbody>
      </table>
      <h3>Trasferimenti</h3>
      <table class="table">
        <thead>
            <td>Rif.</td>
            <td>Tipo</td>
            <td>Data</td>
        </thead>
        <tbody>

            <tr [routerLink]="['/pickings/incoming/p/',ml.move_id.value?.picking_id.id]" style="cursor: pointer"  *ngFor="let ml of moveLines">
                <td>{{ml.move_id.value?.picking_id.name}}</td>
                <td>{{ml.picking_type_id?.name}}</td>
                <td>{{getDate(ml.move_id.value?.picking_id.value?.scheduled_date ) }} </td>
            </tr>
        </tbody>
      </table>
    </div>

  </div>

</div>