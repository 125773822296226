

<div class="container mt-5">


    <div class="card">

        <div class="card-header">
            Nuovo Congedo
        </div>
        <div class="card-body">
          <form>
            <!-- <div class="form-group row">
              <label for="staticEmail" class="col-sm-2 col-form-label">Tipologia</label>
              <div class="col-sm-10">
                <select class="form-select" name="type" [(ngModel)]="type" > 
                    <option value=1 selected>Ferie Giorni</option>
                    <option value=10 >Ferie Orarie</option>
                </select>

              </div>
            </div> -->
            <div class="form-group row mt-3" *ngIf="type == 1">
              <label for="inputPassword" name="date"  class="col-sm-2 col-form-label"
                >Date (dal-al)</label
              >
              <div class="col-sm-5">
                <input type="datetime-local" class="form-control" [(ngModel)]="from" name="from"
               >
              </div>
              <div class="col-sm-5">
                <input type="datetime-local" class="form-control" [(ngModel)]="to"  name="to"
                >
              </div>
            </div>

            <div class="form-group row mt-3" *ngIf="type == 10">
              <label for="inputPassword" name="date"  class="col-sm-2 col-form-label"
              >Date (dal-al)</label
            >
              <div class="col-sm-5">
                <input type="datetime-local" class="form-control"  [(ngModel)]="from" name="request_hour_from"
               >
              </div>
              <div class="col-sm-5">
                <input type="datetime-local" class="form-control"  [(ngModel)]="to"  name="request_hour_to"
                >
              </div>
            </div>

            <div class="form-group row mt-3">
                <label for="staticEmail" class="col-sm-2 col-form-label">Descrizione</label>
                <div class="col-sm-10">
                    <textarea class="form-control" name="name"  [(ngModel)]="name" rows="3"></textarea>
                </div>
              </div>
          </form>

          <button (click)="submit()" class="btn btn-primary">Submit</button>

        </div>
      
      
      </div>

</div>



