  <div class="dropdown text-light d-block">

    <button class="btn btn-link text-white dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      <i class="fa fa-fw fa-user-circle"></i>
    </button>
    <ul class="dropdown-menu px-2">
      <button class="btn btn-primary text-white w-100" (click)="logout()">Logout</button>
    </ul>
  </div>

  