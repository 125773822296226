import { Injectable, TemplateRef } from '@angular/core';

@Injectable({providedIn: 'root'})
export class ToastService {
  toasts: any[] = [];

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    const newToast = {textOrTpl, ...options};
    this.toasts.push(newToast);
    setTimeout(() => {
      this.remove(newToast);
    }, newToast.options && newToast.option.delay || 4000);
  }

  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}
