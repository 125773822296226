
<!-- (toggleInventory)="toggleAncillaryInventory()" 
(addOrderLine)="addAncillaryOrderLine($event)">
-->

<table class="table table-bordered tableFixHead table-hover" id="order">
    <thead>
    <!-- <tr>
      <th colspan="3" scope="colgroup" class="table-light">Prodotto</th>
      <th colspan="3" scope="colgroup" class="table-info">Dimensioni</th>
      <th colspan="1" scope="colgroup" class="table-light">Qt</th>
      <th colspan="4" scope="colgroup" class="table-light">Prezzo</th>
      <th colspan="2" scope="colgroup" class="table-info">Acquisto</th>
    </tr> -->
    <tr valign="middle">
      <th class="table-light">ID</th>
      <th class="table-light">Descrizione</th>
      <th class="table-light">M/H/F</th>
      <th class="table-info">Larg</th>
      <th class="table-info">Spes</th>
      <th class="table-info">Lung</th>
      <!-- <th class="table-light">Unità</th> -->
      <th class="table-light">Quantità</th>
      <!-- <th class="table-info">Unità</th>
      <th class="table-info">UM</th>
      <th class="table-info">Desc</th> -->
      <th class="table-light">Prezzo</th>
      <!-- <th class="table-light">Sconto<br></th>
      <th class="table-light">Finale</th>
      <th class="table-light">Totale</th> -->
      <!-- <th class="table-info">Fornitore</th>
      <th class="table-info">Ordine</th> -->
      <th class="table-info">Disp.</th>
    </tr>
    </thead>
    <tbody>
    <tr style="vertical-align: middle;" *ngFor="let move of stockMoves ; index as i" data-print-row>
      <td>
        <input type="checkbox" [checked]="move.checked"
               (change)="move.checked = !move.checked; updateOrderLine()">
        &nbsp;<span data-print-col>{{move.product_id.id}}</span>
      </td>
      <td data-print-col>

        <a class="text-dark" target="_blank" (click)="addNote(move)">{{move.product_id.name}}</a>
      </td>
  
      <td class="text-right editable" data-print-col>
        <input 
              [disabled]="production.state !== 'draft' && production.state !== 'sent'" 
              type="text" 
              pattern="[MHFmhf]+" 
              (keyup.enter)="onEnter($event)"
              (change)="updateMove('working_code',move, $event)" [size]="1"
              [(ngModel)]="move.working_code"
        >
      </td>
  
      <!-- DIMENSIONI-->
      <td class="text-right">
        <input-number
          data-print-col
          [disabled]="true"
          [ngModel]="getAttribute2(move, 'width')?.name"></input-number>
        <span class="badge badge-muted">mm</span>
      </td>

      <td class="text-right">
        <input-number
          data-print-col
          [disabled]="true"
          [ngModel]="getAttribute2(move, 'height')?.name"></input-number>
        <span class="badge badge-muted">mm</span>
      </td>

      <td class="text-right" [ngClass]="{'editable': isVariantEditable(move)}">
        <input-number
          data-print-col
          [disabled]="!isVariantEditable(move)"
          (change)="updateMove('length', move,$event)"
          [ngModel]="getAttribute2(move, 'length')?.name"></input-number>
        <span class="badge badge-muted">mm</span>
      </td>
    
   
  <!--
      <td *ngIf="!move.spessore_editable" class="text-right" data-print-col>
      </td>
    -->
     
  <!--
      <td *ngIf="!move.lunghezza_editable" class="text-right" data-print-col>
      </td>
   -->
  
      <!-- QT RICHIESTE -->
      <!-- PEZZI -->
      <!-- <td class="editable text-right" >
       
       
      </td>
       -->
      
      <!-- UM -->
      <!-- <td class="editable text-right" data-print-col> -->

        <!-- {{move.product_id.value.product_template_variant_value_ids.values}} -->
        <!-- {{move.product_id.value.product_template_attribute_value_ids.values}} -->
        
          <app-input-double-um *ngIf="isDoubleUM(move.product_id.value)" [disabled]="false"
            [(ngModel)]="move.product_uom_qty"
            (change)="updateMove('product_uom_qty', move, $event)"
            [product]="move.product_id.value"
          >
          </app-input-double-um>
        
  
      <!-- PREZZO -->
      <td class="">
        {{move.product_id?.value?.list_price}} € / {{move.product_id?.value?.uom_id.name}}
      </td>


      <td class="">
        <button class="btn btn-link" *ngIf="move.forecast_availability >= 0" ><i class="fa fa-check text-success"></i></button>
        <button class="btn btn-link" *ngIf="move.forecast_availability < 0"  ><i class="fa fa-circle text-danger"></i></button>
      </td>
     
    </tr>
    </tbody>
  </table>
  