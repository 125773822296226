<nav class="navbar text-white navbar-dark fixed-top px-1">
  <button class="btn btn-link text-white me-auto" routerLink="../.." >
    <i class="fas fa-chevron-left"></i>      
  </button>
 
  <h1 class="ms-auto me-auto">Collo per ordine {{sale?.name}} </h1>

  <div class="ms-auto">
    <div class="btn-group">
      <button class="btn btn-primary dropdown-toggle text-white" data-toggle="dropdown"> {{getStateLabel()}} </button>
      <div class="dropdown-menu"> 
        <a (click)="setState('package_production')" class="dropdown-item disabled">In produzione</a>
        <a (click)="setState('done')" class="dropdown-item" [ngClass]="{disabled : picking.state != 'draft'}">Pronto</a>
        <a (click)="setState('package_ready_to_ship')" class="dropdown-item" [ngClass]="{disabled : picking.state != 'done'}">Da spedire</a>
      </div>
    </div>
      <!-- <button class="btn btn-link text-white" *ngIf="picking && picking.state != 'done'" (click)="close()">Conferma</button> -->
  </div>
  
  <bar-loader [loading]="loading"></bar-loader>
</nav>
      
        <!-- <div style="margin-top:78px;" class="ms-3">
        </div>
        
        <hr> -->

        <!-- <div class="d-flex align-items-center mb-3" >
            <h2 *ngIf="filterMoves().length > 0" class="m-0 ms-3 me-auto">Caricati</h2>
            <h2 *ngIf="filterMoves().length == 0" class="m-0 ms-3 me-auto">Nessun prodotto caricato</h2>
        </div>
          
        <div class="list-group" *ngIf="picking">
          <a class="list-group-item rounded-0" *ngFor="let move of filterMoves()" >
            {{move.product_id[1]}}
            <div (click)="onMoveEdit(move, relation)"  *ngFor="let relation of move._relations" class="p-2 mt-2  border-primary me-2 d-flex bg-primary rounded">
                <span>{{relation.confezioni_qty_lorda ? relation.confezioni_qty_lorda : relation.qty}} </span>
                &nbsp;{{relation.confezioni_qty_lorda ? relation.sale_product_packaging[1] : move.product_uom[1]}}
                &nbsp;&#8592;&nbsp;
                <label class="m-0"> #{{relation.barcode}}</label>
            </div>
          </a>
        </div>

        <div *ngIf="picking?.state == 'draft'">
            <hr>
            <div class="d-flex align-items-center m-3" >
              <h2  class="m-0 me-auto">Mancanti in Nota</h2>
            </div>
        </div> -->

  <div class="list-group"  style="margin-top:78px;" >
    <div class="list-group-item rounded-0" *ngFor="let orderLine of filterLoadables()" > 
      <div class="mb-1  d-flex align-items-center" (click)="load(orderLine)">
        <span class="badge badge-warning text-left">
          <h3 class="d-inline">{{getMissing(orderLine)}} {{getPackage(orderLine)}}</h3>
          <br>
          {{getMissingQty(orderLine) | number : '1.0-3':'en-EN'}} {{orderLine.product_uom[1]}}
        </span>
        &nbsp;[{{orderLine.default_code}}] {{orderLine.name}}
      </div>

      <div *ngFor="let move of getMoves(orderLine)">
        <div  *ngIf="move.confezioni_qty_lorda"  class="p-2 mt-2  border-dark me-2 d-flex bg-muted rounded">
          <div class="w-100" (click)="onMoveEdit(move)">
            {{move.confezioni_qty_lorda}}
            {{move.product_packaging[1]}}
            &#8594;&nbsp;
            <label class="m-0">#{{move.barcode}}</label>
          </div>
          <a class=" me-2" (click)="deleteMove(move.id)"><i class="fa fa-times"></i></a>
        </div>
        
        <div nongIf="!move.confezioni_qty_lorda || move.confezioni_qty_lorda == 0" class="p-2 mt-2 border-dark me-2 d-flex bg-muted rounded">
          <div class="w-100" (click)="onMoveEdit(move, relation)">
            <label class="m-0">#{{move.barcode}}</label>
            &#8594;&nbsp;
            {{move.qty}}
            {{move.product_uom[1]}}  
          </div>
          <a class="me-2" (click)="deleteMove(move.id)"><i class="fa fa-times"></i></a>
        </div>
      </div>
    </div>
  </div>

  <app-picking-modal *ngIf="showMoveEditor" [orderLine]="selectedOrderLine"  (onValue)="onMoveEditDone($event)" [barcode]="selectedMoveLine.barcode" [packages]="selectedMoveLine.confezioni_qty_lorda" [unit]="selectedMoveLine.qty" [missing]="selectedMoveLine.qty - selectedOrderLine.qty_received" ></app-picking-modal>
  <app-sale-picking-modal *ngIf="showQuantityPicker" [alreadyDone]="getAlreadyDone(selectedOrderLine)" [total]="selectedOrderLine.qty_lorda - selectedOrderLine.qty_delivered"  [orderLine]="selectedOrderLine"  (onSuccess)="onPickings($event)"></app-sale-picking-modal>