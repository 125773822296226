<app-order-header showInventory="true" [isSomethingChecked]="true" [order]="order"
  [canConfirm]="order?.state == 'draft'" [loading]="loading"></app-order-header>




<div id="info" class="card side-card w-25 h-100 rounded-0 d-none" *ngIf="order" [hidden]="infoSideClosed">

  <div class="card-body" data-print-row-header>

    <div class="form-group d-none">
      <input data-print-col value="{{order.name}}">
    </div>

    <div class="form-group d-none">
      <input data-print-col value="{{order.partner_id.name}}">
    </div>

    <div class="form-group d-none">
      <input data-print-col value="{{order.partner_ref}}">
    </div>

    <div class="form-group d-none">
      <input data-print-col value="{{order.currency_id.name}}">
    </div>

    <div class="form-group d-none">
      <input data-print-col value="{{order.date_approve}}">
    </div>

    <!-- <div class="form-group d-none">
      <input data-print-col value="{{order.date_planned}}">
    </div> -->

    <div class="form-group">
      <!-- <label><b></b></label> -->
      <input data-print-col class="form-control" readonly value="{{order.picking_type_id.name}}">
    </div>

    <div class="form-group d-none">
      <input data-print-col value="{{order.notes}}">
    </div>

    <div class="form-group d-none">
      <input data-print-col value="{{order.incoterm_id.name}}">
    </div>
    
    <div class="form-group d-none">
      <input data-print-col value="{{order.payment_term_id.name}}">
    </div>



  </div>
</div>



<!--(actionFromHeaderEmitter)="handleHeaderEvent($event)"-->

<!-- <app-order-inventory 
[inventoryClosed]="inventoryClosed"
(toggleInventory)="inventoryClosed = !inventoryClosed"
(addOrderLine)="addOrderLine($event)"
>
</app-order-inventory> -->



<div class="table-container h-100">

  <table class="table table-bordered tableFixHead table-hover" id="order" style="margin-top:62px" *ngIf="order">
    <thead>
      <tr>

        <th class="table-info">Codice</th>
        <th class="table-info">Prodotto</th>
        <th class="table-info">Descrizione</th>
        <th class="table-info">Descrizione Acquisti</th>
        <th class="table-info">Quantità</th>
        <th class="table-light">Ricevuta</th>
        <th class="table-info">Fatturata</th>
        <th class="table-info">Unità di misura</th>
        <th class="table-info">Confezione</th>
        <th class="table-info">Imballaggi</th>
        <th class="table-info">Prezzo</th>
        <th class="table-info">Imposte</th>
        <th class="table-info">Imponibili</th>

      </tr>
    </thead>
    <tbody>
      <tr style="vertical-align: middle;" *ngFor="let line of order?.order_line?.values ; index as i" data-print-row>
        <td class="text-right">
          <input data-print-col value="{{line.product_id.value.variant_seller_ids.values[0].product_code}}">
        </td>
        <td class="text-right">
          <input data-print-col value="{{line.product_id.name}}">
        </td>
        <td class="text-right">
          <input data-print-col value="{{line.name}}">
        </td>
        <td class="text-right">
          <input data-print-col value="{{line.product_id.value.description_purchase}}">
        </td>


        <td class="text-right">
          <input data-print-col value="{{line.product_qty}}">
        </td>

        <td class="text-right">
          <input data-print-col value="{{line.qty_received}}">
        </td>

        <td class="text-right">
          <input data-print-col value="{{line.qty_invoiced}}">

        </td>

        <td class="text-right">
          <input data-print-col value="{{line.product_uom.name}}">

        </td>
        <td class="text-right">
          <input data-print-col value="{{line.product_packaging_qty}}">

        </td>
        <td class="text-right">
          <input data-print-col value="{{line.product_packaging_id.name}}">

        </td>


        <td class="text-right" data-print-col >
          <span>{{line.price_unit | number : '1.0-3':'it-IT'}}</span>
          <span class="badge badge-muted">€</span>
        </td>

        <td class="text-right" data-print-col >
          <span>{{line.taxes_id.values[0].name}}</span>
        </td>

        <td class="text-right" data-print-col >
          <span>{{line.price_subtotal | number : '1.0-3':'it-IT'}}</span>
          <span class="badge badge-muted">€</span>
        </td>

      </tr>
    </tbody>
  </table>

</div>