import { EventEmitter } from '@angular/core';
import { AfterViewInit, Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { TrelloInsertResult } from '../deals/trello-insert/trello-insert.component';
import { Lead } from '../models/crm.lead.model';
import { DriveFolder } from '../models/drive.folder';
import { OdooModel } from '../models/odoo-model.model';
import { IConnectable, TrelloAttachment, TrelloCardEntry2 } from '../models/trello-card';
import { GapiService } from '../shared/services/g-api.service';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { TrelloService } from '../shared/services/trello.service';

@Component({
  selector: 'app-connect-to',
  templateUrl: './connect-to.component.html'
})
export class ConnectToComponent implements OnInit, AfterViewInit {

  @Input() connectable:IConnectable<any>
  @Input() title:string
  @Input() deal:Lead
  @Input() customSize:String
  @Input() type                           // todo remove or derive
  
  @Input() trelloCards:[] = []
  @Input() trelloTemplateCard
  @Input() trelloListId
  @Input() trelloBoardId
  @Input() trelloAtachments:string[][] = []

  @Input() driveFolders:DriveFolder[] = []

  @Input() driveSource:string
  @Input() driveTarget:string
  @Input() driveIsMerge:boolean = false
  @Input() driveLinkIn:string
  @Input() driveTargetTitle?:string
  @Input() driveLinkTitle:string

  @Input() gmailTC:string                 // Gmail tracking code
  
  @Output() onConnect:EventEmitter<any> = new EventEmitter()
  
  @Input() driveAdditionalTitle?:string // additioanl string to join to title for drive and trello
  @Input() skipAttachments = false 
  @Input() isPos = false  // bad way to handle POS special behaviour
  
  @Input() origin = "" // track link origin

  @Output() loading = new EventEmitter<boolean>();

  constructor(
    private modalService: NgbModal,
    private trelloService: TrelloService,
    private odooEm:OdooEntityManager,
    private gapiService: GapiService
  ) { }

  @ViewChild('content', {static:true}) templateRef: NgbModalRef;

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

  openModal() {
    this.modalService.open(this.templateRef)
  }

  getDriveLinkFromId(drive_link:string) {
    return "https://drive.google.com/drive/u/0/folders/" + drive_link
  }

  async createFolder() {

    try {

      var folderName = this.deal.partner_id.name  + " - " + this.deal.city + " - " + this.deal.name + " F" + this.deal.tracking_code + ""
      if (this.driveTargetTitle)
        folderName = this.driveTargetTitle
      
        if (this.driveAdditionalTitle) {
        folderName = folderName  + this.driveAdditionalTitle
      }
      
      var s = new Subject<string>()
      s.pipe(first()).subscribe(async id => {
        
        await this.odooEm.create<DriveFolder>(new DriveFolder(), {name:this.type,value:id,lead_id:this.deal.id, origin:this.connectable.name}).toPromise()

        if (this.driveLinkIn)
          await this.gapiService.linkFolder(id, this.driveLinkIn, this.driveLinkTitle)
        
        if (this.onConnect)
          this.onConnect.emit()
      })

      
      // var id = await
       this.gapiService.cloneFolder(
        {id:this.driveSource},
        {id:this.driveTarget},
        this.driveIsMerge,
        folderName,s
      ).then(async id => {

        // this.driveId = id
        
        // await this.odooEm.create<DriveFolder>(new DriveFolder(), {name:this.type,value:id,lead_id:this.deal.id, origin:this.connectable.name}).toPromise()

        // if (this.driveLinkIn)
        //   await this.gapiService.linkFolder(id, this.driveLinkIn, this.driveLinkTitle)

        this.gapiService.driveBusyMessage.next(null)

        if (this.onConnect)
          this.onConnect.emit()

          
      })

      

    } catch(e) {
      alert("Errore - " + JSON.stringify(e))
    }

  }

  async onInsert(r:TrelloInsertResult) {
    
    this.loading.next(true) 
    this.modalService.dismissAll()
    var folderName = this.deal.partner_id.name  + " - " + this.deal.city + " - " + this.deal.name + " F" + this.deal.tracking_code 

    if (this.driveAdditionalTitle) {
      folderName = folderName  + this.driveAdditionalTitle
    }

    var t = await this.trelloService.createAndAttach(
                                        this.deal,
                                        this.trelloListId,
                                        this.type,
                                        r.dueDate,
                                        r.fields,
                                        this.trelloTemplateCard,
                                        folderName,
                                        this.connectable.name
                                      )

    // this.connectable.trello_ids.values.push(t)
    // await this.odooEm.update(this.connectable as Lead, {trello_ids: [[4,t.id]]})

    var as = []

    as.push(new TrelloAttachment("Contatti", "https://m.galimberti.eu/contact/" + this.deal.partner_id.id))
    as.push(new TrelloAttachment("Lead Odoo", "https://m.galimberti.eu/leads/" + this.deal.id))

    if (this.driveFolders.length > 0) {
      as.push(new TrelloAttachment("Cartella Drive", this.getDriveLinkFromId(this.driveFolders[0].value)))
    }
  
    if (this.trelloAtachments) 
      as = as.concat(this.trelloAtachments.map(x => new TrelloAttachment(x[0],x[1])))

      if (!this.skipAttachments)
      await this.trelloService.postAttachments(t.card_id, as)

    if (this.onConnect) {
      // hack .. can't get the updated lead after await this.odooEm.update
      setTimeout(x => {
        this.onConnect.emit()
      },100)
    }
    this.loading.next(false)
    // this.modalService.dismissAll()
  }
}