import { Component, OnInit } from '@angular/core';
import { RestapiService } from '../../services/rest-api.service';
import { UserInfo} from 'src/app/models/users'
import { TokenStorage } from '../../services/token-storage.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html'
})
export class UserInfoComponent implements OnInit {
  user: UserInfo;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  logout() {
    window.location.href = "https://o2.galimberti.eu/m2logout"
  }

}
