<div id="inventory" class="card rounded-0" *ngIf="inventoryClosed == false" (outsideClick)="closeInventory()">
  <div class="card-header">
    
    
    <!-- <div class="row" *ngIf="!this.activeTemplate">
      <div class="col-9">
        <input class="ms-3 w-100 p-1" id="search-input" [formControl]="inputSearch" data-test-id="search-input">
      </div>
      <div class="col-1 d-flex align-items-center">
        <i data-test-id="inventoryCloseIcon" (click)="closeInventory()" id="close" class="ms-auto fas fa-xmark"></i>
      </div>
    </div> -->

    <div class="row" >
      <!-- <div class="col-1">
        <button  class="btn btn-primary text-white" (click)="backFromVariants()" [disabled]="variants.length == 0"><i class="fa fa-chevron-left"></i></button>
      </div> -->
      <div class="col-12 d-flex flex-row">
        <input class="w-100 " id="search-input" [formControl]="inputSearch" data-test-id="search-input">
        <button class="btn"><i data-test-id="inventoryCloseIcon" (click)="closeInventory()" id="close" class="ms-auto fas fa-xmark"></i></button>
      </div>
      <!-- <div class="col-1 d-flex align-items-center">
        
      </div> -->
    </div>


  </div>
  <div class="card-body p-0">
    <table class="table table-bordered table-hover" data-test-id="tableInventory">
      <thead class="thead-light">
      <tr>
        <th>ID</th>
        <th></th>
        <th>Prodotto</th> 
        <th>Prezzo</th>
        <th>Qt</th>
      </tr>
      </thead>
      <tbody>

          <tr (click)="addOrderLineFromProduct(p)" *ngFor="let p of products" class="cursor-pointer">
            <td>{{p.id}}</td>
            <td>  
              <i *ngIf="p.product_variant_count > 1" class="fa-solid fa-ruler-combined text-success"></i>
              <!-- <i *ngIf="p.product_variant_count == 1" class="fa-solid fa-ruler-combined text-muted"></i> -->
            </td>
            <td>
               {{p.display_name}}</td>
            <td>{{p.list_price}} €/{{p.uom_id.name}} </td>
            <td>{{p.qty_available}}</td>
            
            <!--
            <th>{{p.codice_fornitore}}</th>
            <td>{{p.description_sale ? p.description_sale : p.name }}</td>
            <td>{{p.list_price | number : '1.2-2':'en-EN'}} €</td>
            -->
          </tr>

      
      </tbody>
    </table>
  </div>
</div>
