

<td class="editable text-right border-0 w-50" data-print-col>
    <input
        type="text"
        (change)="onChange()"
        size="{{innerValue ? innerValue.length : 1}}"
        [(ngModel)]="innerValue">
    <span class="badge badge-muted">{{product?.uom_id.name}}</span>
</td>


<td class="editable text-right border-0 w-50"  data-print-col>
    <input 
        *ngIf="volume > -1"    
        type="text" 
        (change)="onChangeInPcs()" 
        size="{{innerValueInPcs ? innerValueInPcs.length : 1}}"
        [(ngModel)]="innerValueInPcs">
    <span *ngIf="volume > -1" class="badge badge-muted">Pz</span>
</td>