import { AuthService } from 'ngx-auth';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { TokenStorage } from './token-storage.service';
import * as ClientOAuth2 from 'client-oauth2';
import { RestapiService } from './rest-api.service';
import { environment } from '../../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable()
export class AuthenticationService implements AuthService {

  private NETLIFY_FUNCTIONS_PATH = '/.netlify/functions/';

  constructor(private router: Router, private tokenStorage: TokenStorage, private restapi: RestapiService, private http: HttpClient) {
  }

  getAccessToken(): Observable<string> {
    const accessToken = this.tokenStorage.getAccessToken();
    return of(accessToken);
  }

  isAuthorized(): Observable<boolean> {
    const accessToken = this.tokenStorage.getAccessToken();

    if (accessToken !== undefined && accessToken != null) {
      return of(true);
    } else {
      return of(false);
    }
  }

  refreshShouldHappen(response: HttpErrorResponse): boolean {
    return response.status === 401;
  }

  refreshToken(): Observable<any> {
    const accessToken = this.tokenStorage.getAccessToken();
    const refreshToken = this.tokenStorage.getRefreshToken();

    return this.http.post(this.NETLIFY_FUNCTIONS_PATH + 'refreshToken',
      {accessToken, refreshToken, type: 'bearer'})
      .pipe(map(tokenResult => this.storeTokenResult(tokenResult)), catchError(() => {
        this.logout();
        throw new Error('Error');
      }));
  }

  authorize() {
    const baseHref = document.location.host === 'm.galimberti.eu' ? 'https://o.galimberti.eu' : 'https://o-dev.galimberti.eu';
    const uri = `${baseHref}/oauth2/auth`;
    const full = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
    if (environment.test === true) {
      const tokenResult = {accessToken: environment.token, refreshToken: environment.token};
      this.storeTokenResult(tokenResult);
      this.router.navigate(['/home']);
    } else {
      const clientOAUTH = new ClientOAuth2({
        clientId: environment.clientId,
        authorizationUri: uri,
        redirectUri: full + '/callback',
        state: 'make-me-random'
      });
      window.location.href = clientOAUTH.token.getUri();
    }
  }

  requestToken(callbackUrl: string): Observable<any> {
    return this.http.post(this.NETLIFY_FUNCTIONS_PATH + 'requestToken', callbackUrl)
      .pipe(map(tokenResult => this.storeTokenResult(tokenResult)));
  }

  storeTokenResult(tokenResult) {
    this.tokenStorage
      .setAccessToken(tokenResult.accessToken)
      .setRefreshToken(tokenResult.refreshToken);
  }

  skipRequest(request: HttpRequest<any>): boolean {
    return request.url.endsWith('refreshToken');
  }

  logout() {
    this.tokenStorage.clear();
  }


  isRefreshTokenRequest(url: string): boolean {
    console.log('URL3', url);
    return url.endsWith('refreshToken');
  }

}
