import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { SaleOrder } from '../../models/sale-order.model';
// import { PurchaseOrder } from '../../models/purchase-order.model';
import { SaleOrderLine } from '../../models/sale-order-line.model';
import { OdooEntityManager } from '../../shared/services/odoo-entity-manager.service';
import { OdooRelationship } from '../../models/odoo-relationship.model';
import { Alert } from 'selenium-webdriver';
// import { MrpProduction } from 'src/app/models/mrp-production';

@Component({
  selector: 'app-order-header',
  templateUrl: './order-header.component.html',
})
export class OrderHeaderComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() title: string = null ;
  @Input() order: SaleOrder  ;
  @Input() orderLinesChecked: SaleOrderLine[] = [];
  @Input() isSomethingChecked: boolean;
  @Input() loading: boolean = true;
  @Input() showInventory: boolean;
  @Input() canConfirm:boolean = false

  @Output() actionFromHeaderEmitter: EventEmitter<HeaderEmitterFormat> = new EventEmitter();

  // orderType: string;

  linkSupplierModel: OdooRelationship = new OdooRelationship();
  printTypes: { value: string; name: string; }[];
  saleOrder: SaleOrder;

  constructor(private odooEm: OdooEntityManager, private cd: ChangeDetectorRef) {

  }


  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    
    // if (!this.saleOrder && this.order) {
    //   var sorders = await this.odooEm.search<SaleOrder>(new SaleOrder(), [['name', '=', this.order.origin]]).toPromise()
    //   console.log("SORDERS", sorders)
    //   if (sorders.length > 0)
    //     this.saleOrder = sorders[0]
    // }
    
  }


  
   async ngOnInit() {
  
    // this.getLinkSupplierOrders();
    // if (this.order instanceof MrpProduction) {
      

    // }
  }

  async ngAfterViewInit() {
    
    // if (!this.title && this.order)
    //   this.title = this.order.name
    // if (this.order instanceof SaleOrder) {
    //   this.printTypes = [
    //     {value: 'O', name: 'Ordine Cliente'},
    //     // {value: 'H', name: 'Prelievo Hundegger (H)'},
    //     // {value: 'M', name: 'Prelievo Monteggio (M)'},
    //     // {value: 'F', name: 'Prelievo finitura (F)'},
    //     // {value: 'S', name: 'Prelievo Magazzino'}
    //   ];
    // } else {
    //   this.printTypes = [
    //     {value: 'O', name: 'Ordine Fornitore'}
    //   ];
    // }

   

    // Needed to fix expressionchanged error
    // this.cd.detectChanges();
  }


  onInventory() {

  }


  getLinkSupplierOrders(): void {
      return;
    // this.odooEm.search<PurchaseOrder>(new PurchaseOrder(), [['partner_id', '=',
    //   this.orderLinesChecked.length ? this.orderLinesChecked[0].fornitore_id.id : '', 'integer']]).pipe(
    //   catchError(() => of([])) // @TODO Handle Error
    // ).subscribe(purchaseOrders => {
    //   const fakeNewOrder = new PurchaseOrder(null);
    //   fakeNewOrder.name = 'Nuovo';
    //   purchaseOrders.unshift(fakeNewOrder);
    //   this.linkSupplierOrders = purchaseOrders;
    // });
  }

  openDropDownLinkSuppliers(): void {
    // this.linkSupplierModel = this.orderLinesChecked[0].fornitore_id;
    // this.getLinkSupplierOrders();
  }

  // // TODO bloated
  // async linkPurchase() {
  //   this.loading = true;
  //   let params = `sale_order_lines=`;
  //   this.orderLinesChecked.forEach(line => {
  //     if (params[params.length - 1] !== '=') {
  //       params += ',';
  //     }
  //     params += `${line.id}`;
  //   });

  //   params += `&supplier_id=${this.orderLinesChecked[0].fornitore_id.id}`;

  //   if (this.linkSupplierOrders && this.linkSupplierOrders[0].id) {
  //     params += `&purchase_id=${this.linkSupplierOrders[0].id}`;
  //   }

  //   this.odooEm.callArray<SaleOrderLine>(new SaleOrderLine(), 'create_or_edit_purchase_order', params).subscribe(updatedOrderLines => {
  //     // Update rows
  //     updatedOrderLines.forEach(updatedOrderLine => {
  //       const order = this.orderLinesChecked.filter(singleOrderLineChecked => singleOrderLineChecked.id === updatedOrderLine.id)[0];
  //       order.supplier_purchase_id = updatedOrderLine.supplier_purchase_id;
  //       order.purchase_id = updatedOrderLine.purchase_id;
  //       order.checked = false
  //     });
  //   });

  //   this.loading = false;
  // }



  async updateSaleOrder(field:string) {
    var j = {}
    j[field] = this.order[field]
    await this.odooEm.update(this.saleOrder,j).toPromise()
  }

  async update(field:string) {
    var j = {}
    j[field] = this.order[field]
    await this.odooEm.update(this.order,j).toPromise()
  }

  async updateDatetime(field:string) {
    var j = {}
    j[field] = this.order[field].replace("T", " ")
    await this.odooEm.update(this.order,j).toPromise()
    this.emitAction('update')
  }



  pickContact():void {
    const w = window.open('/contact?mode=embedded', '_blank',
      'height=700,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');

    const h = event => {
      const data = JSON.parse(event.data);
      this.linkSupplierModel = new OdooRelationship(data.id, data.name);

      this.getLinkSupplierOrders();
      w.close();
    };
    window.addEventListener('message', h, false);

    w.onbeforeunload = () => {
      window.removeEventListener('message', h);
    };
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes.order && changes.order.currentValue) {
  //     console.log("ORDERRRR", this.order)
  //   }
  // }

  getOrderStateName(): string {
    
    switch (this.order.state) {
      case 'draft':
        return this.order.ORDER_TYPE_NAME === 'SaleOrder' ? 'Preventivo' : 'Bozza';
      case 'sale':
        return 'Confermato';
      case 'in_produzione':
        return 'In preparazione';
      case 'purchase':
        return 'Confermato';
    }
  }

  getHeaderName(): string {
    switch (this.order.ORDER_TYPE_NAME) {
      case 'SaleOrder':
        return 'Vendita';
    }
  }


  async confirm() {
    // await this.odooEm.call(new SaleOrder(), "action_confirm",this.order.id).toPromise()
    this.emitAction("confirm")
  }

  
  emitAction(functionName: string, value?: string | boolean | number) {
    this.actionFromHeaderEmitter.emit({
      functionName,
      value
    } as HeaderEmitterFormat);
  }
}

export interface HeaderEmitterFormat {
  functionName: string;
  value: any;
}


