import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[auto-length]'
})
export class AutoLengthDirective {
  
  constructor(elref: ElementRef) {
    let el = elref.nativeElement
    setTimeout(() => {
      this.updateSize(el)
    })

    el.addEventListener('input', () => {
      this.updateSize(el)
    })

    el.addEventListener('change', () => {
      this.updateSize(el)
    })
    
  }

  updateSize(el) {
    let l = el.value.toString().length
    el.size = l > 0 ? l : 1
  }

}
