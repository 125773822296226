import {
  Component,
  forwardRef,
  Input,
  Provider,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export const WRAPPER_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputNumberComponent),
  multi: true
};

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'input-number',
  template: `<input [disabled]="disabled" type="text" (change)="onChange()" size="{{innerValue ? innerValue.length : 1}}"
                    [(ngModel)]="innerValue">`,
  providers: [WRAPPER_CONTROL_VALUE_ACCESSOR]
})


export class InputNumberComponent implements ControlValueAccessor {
  @Input() disabled = false;
  public innerValue = '';
  private _change: any;
  private _touched: any;
  public value = 'dsa';

  constructor() {
  }

  writeValue(obj: any): void {
    this.innerValue = Number(obj).toLocaleString();
  }

  registerOnChange(fn: any): void {
    this._change = fn;
  }

  registerOnTouched(fn: any): void {
    this._touched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    console.log('... set disabled ');
  }

  onChange() {
    var p = this.innerValue.replace(/\./g, '').replace(/,/, '.');
    this._change(p);
  }
}
