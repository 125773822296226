import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
import { StockPicking } from 'src/app/models/stock-picking';
import { PurchaseOrderLine } from 'src/app/models/purchase-order-line.model';
import { StockMove } from 'src/app/models/stock-move';
import { StockQuantPackage } from 'src/app/models/stock-quant-package';
import { MrpProduction } from 'src/app/models/mrp-production';
import { Product } from 'src/app/models/product.model';
import { ProductTemplateAttributeValue } from 'src/app/models/product.template.attribute.value.model';
import { ProductAttributeValue } from 'src/app/models/product.attribute.value';
import { StockMoveEmitterFormat } from 'src/app/components/production-table/production-table.component';
import { ProductTemplate } from 'src/app/models/product.template.model';
import { ProductTemplateAttributeLine } from 'src/app/models/product.template.attribute.line';
import { ProductSupplierinfo } from 'src/app/models/product.supplierinfo';
import { catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { GapiService } from 'src/app/shared/services/g-api.service';
import { PRINTS_CFG } from 'src/app/models/deal';

@Component({
  selector: 'app-production-detail',
  templateUrl: './production-detail.component.html'
})
export class ProductionDetailComponent implements OnInit {
  loading: boolean;
  productionId: number;
  production: MrpProduction;
  picking: StockPicking;
  purchaseLines: PurchaseOrderLine[] = [];
  stockPackages: StockQuantPackage[] = [];
  inventoryClosed: boolean = true;
  supplierinfos: ProductSupplierinfo[];
  
  constructor(
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private odooEm: OdooEntityManager,
    private gapiService: GapiService
  ) { }

  async ngOnInit() {
    this.route.params.subscribe(async params => {
      this.productionId = params['pid']
      await this.loadProduction()
    })    
  }

  async loadProduction(moves?:StockMove[]) {

    this.loading = true
    
    if (!moves) {
      var production = (await this.odooEm.search<MrpProduction>(new MrpProduction(), [["id","=",this.productionId]]).toPromise())[0]
      await this.odooEm.resolve(production.move_raw_ids).toPromise()
    }
    
    moves = moves ? moves : production.move_raw_ids.values
    if (moves) {
      await this.odooEm.resolveArrayOfSingle<Product,StockMove>(new Product(),moves, 'product_id').toPromise()
      await this.odooEm.resolveArrayOfSingle<ProductTemplate,StockMove>(new ProductTemplate(),moves, 'product_tmpl_id').toPromise()
      // await this.odooEm.resolveArrayOfSingle<Product,StockMove>(new Product(),production.move_raw_ids.values, 'product_tmpl_id').toPromise()
    }

    var products = []
    var templates = []
    moves.forEach(s => {
      if (s.product_id && s.product_id.value)
        products.push(s.product_id.value)
      if (s.product_tmpl_id && s.product_tmpl_id.value)
        templates.push(s.product_tmpl_id.value)
    })
    await this.odooEm.resolveArray(new ProductTemplateAttributeValue(), products, "product_template_attribute_value_ids").toPromise()
    await this.odooEm.resolveArray(new ProductTemplateAttributeLine(), templates, "attribute_line_ids").toPromise()
    await this.odooEm.resolveArray(new ProductSupplierinfo(), products, "variant_seller_ids").toPromise()
    
    // supplierinfos
    this.supplierinfos = await this.odooEm.search<ProductSupplierinfo>(new ProductSupplierinfo()).toPromise()

    this.production = production
    this.loading = false
  }
  

  async addOrderLine(p:Product) {
    console.log("add order line", p)
    var s = await this.odooEm.create(new StockMove(), {
      name: p.name,
      product_uom: p.uom_id.id,
      product_id: p.id,
      location_id: 1,
      location_dest_id: 1,
      raw_material_production_id: this.production.id
    }).toPromise()

    await this.loadProduction()
  }
  

  async updateStockMove(e:StockMoveEmitterFormat) {
    this.loading = true
    if (e && e.field == 'length')  // bad
      this.updateStockMoveVariant(e)
    else {
      var n = {}
      n[e.field] = e.move[e.field]
      await this.odooEm.update(e.move, n).toPromise()
      this.loading = false
    }
  }

  // TODO CLEANUP
  async updateStockMoveVariant(e:StockMoveEmitterFormat) {

    var line
    e.move.product_tmpl_id.value.attribute_line_ids.values.forEach(l => {
      console.log("line",l.display_name, e.field)
      if (l.display_name == e.field)
        line = l
    })

    // check if exist attribute value
    let oldv:ProductAttributeValue[] = await this.odooEm.search<ProductAttributeValue>(new ProductAttributeValue(), 
      [["name","=", e.value],
       ["attribute_id","=", line.attribute_id.id]]
    ).toPromise()

    let v:ProductAttributeValue 
    
    if (oldv.length > 0) 
      v = oldv[0]
    else {
      // new attribute value
      v = await this.odooEm.create<ProductAttributeValue>(new ProductAttributeValue(), {
        "name": e.value,
        "attribute_id": line.attribute_id.id
      }).toPromise()
    }

    var ids = line.value_ids.ids
    ids.splice(ids.length,0,v.id)
    
    await this.odooEm.update(e.move.product_tmpl_id.value, {
         attribute_line_ids:[[1, line.id, {value_ids: [[6, false, ids]]}]]
    }).toPromise()
    
    // fresh template attribute value only after adding value to attriute
    var freshv = (await this.odooEm.search<ProductTemplateAttributeValue>(
      new ProductTemplateAttributeValue(),
      [['product_attribute_value_id', '=', v.id],['product_tmpl_id', '=', e.move.product_tmpl_id.id]]).toPromise())[0]

    // search the just created product variant
    var p:Product[] = await this.odooEm.search<Product>(new Product(), [['active','=', true],['product_template_variant_value_ids','in',freshv.id]]).toPromise()
    // await this.odooEm.update(e.move, {'product_id':p[0].id}).toPromise()
    await this.odooEm.update(e.move, {'product_id':p[0].id}).toPromise()

    // dirty selective update
    await this.odooEm.resolve(p[0].product_template_attribute_value_ids).toPromise()
    e.move.product_id.value = p[0]
    e.move.product_id.id = p[0].id
    e.move.product_id.name = p[0].display_name
    
    this.loading = false
  }

  async handleHeaderEvent(e) {
    if (e.functionName == 'toggleInventory')
      this.toggleInventory()
    else if (e.functionName == 'deleteOrderLines')
      this.deleteLine()
    else if (e.functionName == 'confirm') {
      await this.odooEm.call(new MrpProduction(), "action_confirm",this.production.id).toPromise()
      this.loadProduction()
    } else if (e.functionName == 'print') {
      this.print("")
    }
  }


  async print(typeOfPrint: string): Promise<void> {
    this.loading = true;

    let headerRow; // just one
    this.elementRef.nativeElement.querySelectorAll('[data-print-row-header]').forEach(element => {
      headerRow = this.row2printable(element);
    });

    const headerDatas = []; // more than one
    this.elementRef.nativeElement.querySelectorAll('[data-print-row]').forEach(element => {
      headerDatas.push(this.row2printable(element));
    });

    var sheetid = await this.gapiService.printOrder(
      PRINTS_CFG.template_sheet_id,
      PRINTS_CFG.spool_folder_id, 
      headerRow, 
      headerDatas)

      window.open('https://docs.google.com/spreadsheets/d/' + sheetid, '_blank');
      this.loading = false;

  }

  row2printable(r): any {
    const row = [];
    r.querySelectorAll('[data-print-col]').forEach(element => {
      console.log('row 2 printable', element, element.tagName === 'A');

      if (element.tagName === 'A' || element.tagName === 'SPAN') {
        row.push(element.innerText);
      } else if (element.tagName === 'SELECT') {
        if (element.selectedIndex > -1) {
          row.push(element.options[element.selectedIndex].innerHTML);
        }
        
      } else if (['INPUT', 'TEXTAREA' ].includes(element.tagName)) {

        console.log("PPPR ", element,element.value)
        row.push(element.value);
      } else {
        let t = '';
        element.querySelectorAll('*').forEach(e => {
          t += ((e.value ? e.value : e.innerText) + ' ');
        });
        row.push(t.trim());
      }
    });
    return row;
  }



  
  deleteLine() {
    if (!confirm('Confermi di voler eliminare i prodotti selezionati ?')) {
      return;
    }
    this.loading = true;
    const ids = [];
    const order = this.production;
    order.move_raw_ids.values.forEach((move) => {
      if (move["checked"]) {
        ids.push(move.id);
      }
    });

    this.odooEm.deleteMulti<StockMove>(this.production.move_raw_ids, ids).pipe(catchError(() => of(null)))
      .subscribe(orderline => {
        this.loading = false;
        if (!orderline) {
          // this.toastService.show(`Impossibile eliminare la riga d'ordine corrente`, {classname: 'bg-danger'});
          return;
        }
        // this.toastService.show(((ids.length === 1) ? `Riga d'ordine eliminata` : `Righe d'ordine eliminate`) + ' con successo');
      });

  }

  

  toggleInventory(): void {
    console.log("t")
    this.inventoryClosed = !this.inventoryClosed;
  }
  

}
