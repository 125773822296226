import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Package } from 'src/app/models/package';
import { StockQuantPackage } from 'src/app/models/stock-quant-package';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';


const Dynamsoft = (window as any).Dynamsoft;
// Dynamsoft.DBR.BarcodeScanner.organizationID = "367928";

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss']
})
export class ScannerComponent implements OnInit, OnDestroy {


  @Input() canCreate:boolean = false;
  controls: any;
  @Output() onCode:EventEmitter<string|null> = new EventEmitter<string|null>()
  @Output() onPackage:EventEmitter<StockQuantPackage|null> = new EventEmitter<StockQuantPackage|null>()
  result: string = "";
  i: number = 0;
  canvas: HTMLCanvasElement;
  ctx: any;
  scanner: any;
  needToCreate: boolean;
  resolution: any;

  constructor(
    private elRef: ElementRef,
    private odooEM: OdooEntityManager
  ) { }

  ngOnDestroy(): void {
    if(this.scanner)
    this.scanner.hide()
  }

  async ngOnInit() {
    this.canvas = this.elRef.nativeElement.querySelector("canvas")
    this.ctx = this.canvas.getContext('2d');
    this.scan2()
  }
  
  async create() {
    // var ps = await this.odooEM.search<StockQuantPackage>(new StockQuantPackage(),[["name", "=", this.selectCode]]).toPromise()
    // var p:StockQuantPackage
    // if (ps.length == 0 && confirm("Pacco non trovato, vuoi crearlo ?")) {
    var p = await this.odooEM.create<StockQuantPackage>(new StockQuantPackage(), {
        // 'location_id': this.scanningPackageDest.location_dest_id.id,
        'name': this.result
      }).toPromise()
    // } else if (ps.length > 0) {
    //   p = ps[0]
    // } 
    this.onPackage.emit(p)
  }

  cancel() {
    this.scanner.hide()
    this.onPackage.emit(null)
  }

  async selectCode(c:string) {
    this.onCode.emit(c)
    // var ps = await this.odooEM.search<StockQuantPackage>(new StockQuantPackage(),[["name", "=", c]]).toPromise()
    // if (ps.length == 0) {
    //   this.needToCreate = true
    // }
    // else {
    //   this.onPackage.emit(ps[0])
    // }
  }

  async scan2() {
    return
    this.scanner = await Dynamsoft.DBR.BarcodeScanner.createInstance({
      onFrameRead: (results:any[]) => {
        if (results.length > 0) {
          this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
          
          this.canvas.height = window.innerHeight;


          this.i = this.i + 1

          var offsetx = (1280 - window.innerWidth ) / 2
          var offsety = (720 - window.innerHeight) / 2
          
          results.forEach(result => {


            let x1 = (result.localizationResult.x1 - offsetx) 
            let y1 = result.localizationResult.y1 - offsety
            let x2 = result.localizationResult.x2 - offsetx
            let y2 = result.localizationResult.y2 - offsety
            let x3 = result.localizationResult.x3 - offsetx
            let y3 = result.localizationResult.y3 - offsety
            let x4 = result.localizationResult.x4 - offsetx
            let y4 = result.localizationResult.y4 - offsety

            this.ctx.fillStyle = '#28a745'
            this.ctx.beginPath()
            this.ctx.moveTo(x1, y1)
            this.ctx.lineTo(x2, y2)
            this.ctx.lineTo(x3, y3)
            this.ctx.lineTo(x4, y4)

            this.ctx.fill();
          })


          this.selectCode(results[0].barcodeText)
        }
      }
    });

    const scanner = await this.scanner;
    scanner.setUIElement(this.elRef.nativeElement);
    await scanner.show();
    
    this.resolution =  scanner.getResolution()
    this.canvas.width  = this.resolution[0];

    let scanSettings = await scanner.getScanSettings();
scanSettings.intervalTime = 50;
scanSettings.duplicateForgetTime = 1000;
await scanner.updateScanSettings(scanSettings);
  }

  

}
