import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestapiService } from '../../shared/services/rest-api.service';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { OdooEntityManager } from '../../shared/services/odoo-entity-manager.service';
import { catchError, first, map } from 'rxjs/operators';
import { Contact } from '../../models/contact.model';
import { Observable, of } from 'rxjs';
import { ToastService } from '../../shared/services/toast.service';

class VATResponse {
  valid:boolean
  name:string
  street:string
  city:string
  province:string
  zip:string
  vat:string
}

@Component({
  selector: 'app-edit-contact-detail',
  templateUrl: './edit-contact-detail.component.html'
})
export class EditContactDetailComponent implements OnInit {
  contact: Contact;
  id: any;
  loading = false;
  method: any;
  // contactForm: FormGroup;
  submitted = false;
  isAddingChild: boolean;

  constructor(
    public route: ActivatedRoute,
    public restapi: RestapiService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private odooEm: OdooEntityManager,
    private toastService: ToastService
  ) {
  }

  ngOnInit() {
    // init reactive form
    // this.contactForm = this.formBuilder.group({
    //   company_type: ['company', Validators.required],
    //   name: ['', Validators.required],
    //   street: [''],
    //   city: [''],
    //   vat: [''],
    //   fiscalCode: [''],
    //   uniqueCode: [''],
    //   iban: [''],
    //   creditLimit: [''],
    //   comment: [''],
    //   clientid: ['']
    // });

    this.route.params.subscribe(params => {
      this.id = params.id;
      this.route.snapshot.queryParamMap.get('child');
      // this.contactForm.get('company_type').setValue(this.id ? 'person' : 'company');

      if (this.isNew()) {
        this.contact = new Contact();
        this.isAddingChild = true;
      } else {
        this.isAddingChild = false;
        this.populateForm();
      }
    });
  }

  populateForm(): void {
    this.loading = true;
    alert("TODO")
    // this.odooEm.get<Contact>(new Contact(), this.id)
    //   .pipe(catchError(() => of(null)))
    //   .subscribe(contact => {
    //     this.loading = false;
    //     if (!contact) {
    //       return; // Error, handle it
    //     }
    //     this.contact = contact;
    //     console.log("CONTACT ", this.contact)
    //     // Object.keys(contact).forEach(key => {
    //     //   // const formKey = this.contactForm.get(key);
    //     //   // if (formKey && this.contact[key]) {
    //     //   //   formKey.setValue(this.contact[key]);
    //     //   // }
    //     // });
    //   });
  }

  // save() {
  //   // if (this.contactForm.invalid) {
  //   //   return;
  //   // }
  //   this.loading = true;
  //   const parent = this.route.snapshot.queryParamMap.get('child');
  //   this.createOrUpdateContact(parent)
  //     .pipe(
  //       map(contact => Object.assign(new Contact(), contact)),
  //       catchError(() => of(null)))
  //     .subscribe(res => {
  //       this.loading = false;
  //       if (!res) {
  //         this.toastService.show('Impossibile aggiornare il contatto', {classname: 'bg-danger'});
  //         return;
  //       }
  //       const returnUrl = parent ? ['contact/' + this.id + '/' + res.id] : ['contact/' + res.id];
  //       this.router.navigate(returnUrl);
  //     });
  // }

  // createOrUpdateContact(parent): Observable<Contact> {
  //   if (parent) {
  //     this.contactForm.value.parent_id = this.id;
  //   }
  //   if (this.isNew()) {
  //     return this.odooEm.create<Contact>(this.contact, this.contactForm.value);
  //   } else {
  //     return this.odooEm.update<Contact>(this.contact, this.contactForm.value);
  //   }
  // }


  restore() {
    this.loading = true;
    this.odooEm.update<Contact>(this.contact, {state: 'quotation'})
      .pipe(catchError(() => of(null)))
      .subscribe(res => {
        this.loading = false
        if (!res) {
          return; // Handle error
        }
        this.contact.state = 'quotation'
      });
  }

  // Archiviazione
  obsolete() {
    this.loading = true;
    this.odooEm.update<Contact>(this.contact, {state: 'cancel'})
      .pipe(catchError(() => of(null)))
      .subscribe(res => {
        this.loading = false
        if (!res) {
          return; // Handle error
        }
        this.contact.state = 'cancel'
      });
  }

  isNew() {
    return this.route.snapshot.url.toString().endsWith('new')
  }


  parseXMLVAT(r):VATResponse {
    var v = new VATResponse()
    
    const parser = new DOMParser();
    const xml = parser.parseFromString(r, "text/xml");
    
    console.log("r",r)

    v.valid = (xml.querySelector("valid").textContent == 'true') ? true : false
    if (!v.valid)
      return v

    v.name = xml.querySelector("name").textContent;

    v.vat = xml.querySelector("vatNumber").textContent;

    // badly split address based on cap position
    var address = xml.querySelector("address").textContent;
    
    var ar = address.split('\n')
    v.street = ar[0]
    var sr = ar[1].split(" ")
    v.zip = sr[0]
    v.province = sr[sr.length -1]
    v.city = sr.slice(1,-1).join(" ")
    
      // this.contact.name = xml.querySelector("name").textContent;
    console.log("VAT ", v)
    return v
  }

  onVAT(vat) {
    this.loading = true
    var xmlhttp = new XMLHttpRequest();
            xmlhttp.open('POST', 'checkvat/taxation_customs/vies/services/checkVatService', true);

            // build SOAP request
            var sr =
            "<s11:Envelope xmlns:s11='http://schemas.xmlsoap.org/soap/envelope/'>"+
              "<s11:Body>"+
                "<tns1:checkVat xmlns:tns1='urn:ec.europa.eu:taxud:vies:services:checkVat:types'>"+
                  "<tns1:countryCode>IT</tns1:countryCode>"+
                  "<tns1:vatNumber>" + vat +"</tns1:vatNumber>"+
                "</tns1:checkVat>"+
              "</s11:Body>"+
            "</s11:Envelope>"

            xmlhttp.onreadystatechange = () => {
              this.loading = false
                if (xmlhttp.readyState == 4) {
                    if (xmlhttp.status == 200) {
                        var r = this.parseXMLVAT(xmlhttp.responseText)
                        
                        if (r.valid) {
                          // this.contact.name = r.name
                          // this.contact.city = r.city
                          // this.contact.street = r.street
                          // this.contact.zip = r.postalcode

                          this.bulkUpdate(r)
                        } else 
                          alert("VAT non valida")
                    }
                }
            }
            // Send the POST request
            xmlhttp.setRequestHeader('Content-Type', 'text/xml');
            xmlhttp.send(sr);


  }

  async bulkUpdate(fields) {
    if (this.isAddingChild) {
      fields.state =  "quotation"
      fields.company_type = "company"
      this.contact = await this.odooEm.create<Contact>(new Contact(), fields).pipe(first()).toPromise()
      for (var k in this.contact) {
        if (this.contact[k] == false)
          this.contact[k] = ""
        // console.log("xx-", this.contact[k])
      }
      this.isAddingChild = false
    } else {
      this.contact = await this.odooEm.update<Contact>(this.contact,fields).pipe(first()).toPromise()
    }
    this.loading = false
  }

  async update(prop:string) {
    if (this.isNew())
      return
    this.loading = true
    var p = {}
    p[prop] = this.contact[prop]
    
    // if (this.isAddingChild) {
    //   this.contact.state = "quotation"
    //   this.contact.company_type = "company"
    //   this.contact = await this.odooEm.create<Contact>(new Contact(), this.contact).pipe(first()).toPromise()
    //   this.isAddingChild = false
    // }
    await this.odooEm.update(this.contact,p).pipe(first()).toPromise()
    this.loading = false
  }


  async onSubmit() {
    
  }
}
