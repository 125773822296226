
<ul *ngIf="contact?.child_ids" class="list-inline" >
  <ng-container *ngFor="let v of contact.child_ids.values">
    <li *ngIf="v.name.indexOf('[') == -1" class="bg-white p-3 list-inline-item rounded-0 m-2" style="width: 356px;">
      <div class="d-flex align-items-center justify-content-center">
        
        <div class="w-100" (click)="openContact = (openContact == v) ? true : v ; editingContact = false">
          <span *ngIf="openContact != v || !editingContact"><b>{{v.name}}</b> <span class="text-muted" *ngIf="v.comment"> - {{v.comment}} </span>&nbsp;</span>
          <!-- <b *ngIf="openContact == v && !editingContact">{{v.name}} <span class="text-muted" *ngIf="v.comment"> - {{v.comment}}</span></b> -->
          <input class="form-control" placeholder="Nome" autofocus *ngIf="openContact == v && editingContact" (click)="$event.stopPropagation()" [(ngModel)]="v.name" (change)="onChangeContact(v,'name')" >
        </div>
        
        <!-- call to actions -->
        <div class="d-flex" *ngIf="!editingContact"> 
          <button class="btn btn-link" (click)="openContact = v ;editingContact = true"><i class="fa fa-pencil text-muted"></i></button>
        </div> 

        <button type="button" class="btn btn-link text-muted ms-2" *ngIf="openContact == v && editingContact" (click)="deleteChild(v)">
          <i class="fa fa-trash"></i>
        </button>

        <button type="button" class="btn btn-link" *ngIf="openContact == v && editingContact" (click)="editingContact = false">
          <i class="fa fa-check"></i>
        </button>
      </div>

      <!-- dettagli -->
      <!-- <div class="w-100 pb-4" > -->
      <div class="w-100 pb-4" *ngIf="(openContact != v || !editingContact)">
        <table>
          <ng-container *ngFor="let l of v.link_ids.values">
            <tr >
              <td class="text-no-wrap w-auto pt-3">{{l.type}}</td>
              <td class="text-no-wrap pt-3 text-right pe-2 w-100"><a (click)="onClickLink($event, v)" href="{{getTypeForLink(l)}}:{{l.value}}">{{l.value}}</a></td>
            </tr>
            <tr *ngIf="l.note">
              <td colspan="2" class="w-100 text-muted">{{l.note}}</td>
            </tr>
          </ng-container>
        </table>
      </div>

      <!-- modifca dettagli -->
      <div class="w-100 " *ngIf="openContact == v && editingContact">
        
        <input class="form-control mt-2" placeholder="Note" [(ngModel)]="v.comment" (change)="onChangeContact(v,'comment')">

        <table class="my-3">
          <ng-container *ngFor="let l of v.link_ids.values">
            <tr>
              <td class="pt-4">
                <select class="form-control w-auto" [(ngModel)]="l.type" (change)="onChangeLink(l,'type')">
                  <option value="Tel">Tel</option>
                  <option value="Cell">Cell</option>
                  <option value="Email">Email</option>
                  <option value="PEC">PEC</option>
                  <option value="URL">URL</option>
                </select>
              </td>
              <td class="w-100 pt-4">
                <input class="form-control w-100" [(ngModel)]="l.value" (change)="onChangeLink(l,'value')">
              </td>
              <td rowspan="2">
                <button class="btn btn-link text-muted" (click)="onDeleteLink(v, l)">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
            <tr class="mt-4 mb-4">
              <td colspan="2" class="pt-2 w-100">
                <input class="w-100 form-control" placeholder="Note" [(ngModel)]="l.note" (change)="onChangeLink(l,'note')">
              </td>
            </tr>
          </ng-container>
        </table>

        <button class="btn btn-link d-block mx-auto" (click)="newLink(v)">  
          <span class="fa-stack">
            <i class="fas fa-circle text-muted fa-stack-2x"></i>
            <i class="fas fa-plus text-white fa-stack-1x fa-inverse"></i>
          </span>
        </button>
        
      </div>
      
    </li>
    </ng-container>
    <!-- add child contact -->
    <li class="list-inline-item rounded-0"  class="bg-white p-3 list-inline-item rounded-0 m-2 align-items-center justify-content-center d-flex" style="width: 356px;">
      <button class="btn btn-link d-block mx-auto" (click)="newChild(v)">  
        <span class="fa-stack">
          <i class="fas fa-circle text-primary fa-stack-2x"></i>
          <i class="fas fa-plus fa-stack-1x fa-inverse"></i>
        </span>
      </button>
    </li>

</ul>