import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QuantityPickerResult } from 'src/app/models/picking';


@Component({
  selector: 'app-picking-modal',
  templateUrl: './picking-modal.component.html',
})

export class PickingModalComponent implements OnInit {

  @Input() orderLine: any;
  @Output() onValue: EventEmitter<QuantityPickerResult> = new EventEmitter<QuantityPickerResult>();
  @Input() missing: number
  @Input() unit?: number  = 0
  @Input() packages?: number = 0
  @Input() barcode: string = null
  @Input() expectedBarcode: null
  @Input() multiple: boolean 

  loading: number
  isScanning: boolean
  mode: string = "unit"
  
  constructor() {}

  ngOnInit() {
    if (this.unit)
      this.loading = this.unit
    // bad hack to work with both orderline and move line
    // if (this.packages && this.packages > 0) {
    //   this.loading = this.packages
    //   this.mode = "packages"
    // } else if (this.unit && this.unit > 0) {
    //   this.loading = this.unit
    //   this.mode = "unit"
    // } else 
    //   throw("app-picking-modal requires at least a unit or packages param")

  }

  getMissing() {
    return this.missing - this.loading
  }

  getBarcodesNumber() {
    // single package
    if (this.orderLine.flag_pacco_only) return 1
    // 
    return (this.mode == "packages") ? this.loading : 1
  }

  onOk() {
    // if we already a barcode we are editing an existing relation, skip scan
    if (this.barcode)
      this.onBarcodeScan([this.barcode])
    else
      this.isScanning = true
  }

  onBarcodeScan(barcode:Array<string>) {

    console.log("bacodescan", barcode)
    if (!barcode) {
      this.isScanning = false
      return 
    }
    
    if (this.expectedBarcode && this.expectedBarcode != barcode) {
      confirm("Il barcode non e' corretto\nLetto " + barcode + ", mi aspettavo " + this.expectedBarcode)
      return
    }
  
    var r = new QuantityPickerResult()
    if (this.mode == "unit")
      r.unit = this.loading
    else
      r.packages = this.loading
    r.barcodes = barcode

    this.onValue.next(r)
  }

  onCancel() {
    this.onValue.next(null)
  }

  getTitle() {
    return "[" + this.orderLine.default_code + "] " + this.orderLine.name + (this.orderLine.flag_pacco_only ? "\nPacco unico" : "Pacchi multipli")
  }

  getUOM() {
    return "pz"
    return this.orderLine.product_uom_po ? this.orderLine.product_uom_po[1] : this.orderLine.product_uom[1]
  }

  getPackage() {
    // console.log("package", this.orderLine)
    return "pz"
    return this.orderLine.product_packaging ? this.orderLine.product_packaging[1]: this.orderLine.sale_product_packaging[1]
  }

}
