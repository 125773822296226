import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CustomField } from 'src/app/models/trello-card';
import { TrelloService } from 'src/app/shared/services/trello.service';



export class TrelloInsertResult {
  dueDate:string
  fields:[] = []
}

@Component({
  selector: 'app-trello-insert',
  templateUrl: './trello-insert.component.html'
})
export class TrelloInsertComponent implements OnInit {

  @Input() title:string
  @Input() trelloListId
  @Input() trelloBoardId
  @Input() trelloTemplateCard
  @Output() onInsert = new EventEmitter<TrelloInsertResult>();
  
  isLoading = false

  fields: any = [];
  due:string

  constructor(private formBuilder: UntypedFormBuilder,
    private trello: TrelloService) {
  }

  async ngOnInit() {
    this.fields = await this.trello.getCustomFieldsOnBoard(this.trelloBoardId)
    console.log("FIELDS ", this.fields)
    
    // if we have a template, we should look at its description for additional fields to insert 
    if (this.trelloTemplateCard) {
      var tc = await this.trello.getCard(this.trelloTemplateCard)
      this.fields = this.fields.concat(this.extractDescriptionFields(tc.desc))
    }
  }

  
  // todo move in trello service
  extractDescriptionFields(desc: string):CustomField[] {
    var regExp = /\[(.*?)\]/g;
    var matches = desc.match(regExp);
    var ret = []
    for (var i = 0; i < matches.length; i++) {
        var str = matches[i].slice(1,-1)
        ret.push({type : "text",  name: str, id:str , isDescription: true})
    }
    return ret
  }


  async insert() {
    var r = new TrelloInsertResult()
    r.dueDate = this.due
    r.fields = this.fields
    this.onInsert.next(r)
  }

}