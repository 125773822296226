import { JsonPipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, debounce, debounceTime, firstValueFrom, Observable, Subject } from 'rxjs';
import { io } from 'socket.io-client';
import { StockLocation } from 'src/app/models/stock-location';
import { StockPicking } from 'src/app/models/stock-picking';
import { StockQuantPackage } from 'src/app/models/stock-quant-package';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
const Dynamsoft = (window as any).Dynamsoft;


const FILA_1_ID = 66
const FILA_2_ID = 726
const FILA_3_ID = 727


@Component({
  selector: 'app-forklift-page',
  templateUrl: './forklift-page.component.html',
  styleUrls: ['./forklift-page.component.scss']
})
export class ForkliftPageComponent implements OnInit, AfterViewInit {

  loading:boolean = false
  video: HTMLVideoElement;
  forkliftid: string;
  pickings = []
  canvas: HTMLCanvasElement;
  ctx: any;
  rsl: any;
  variables: any = {}
  search$:Subject<number> = new Subject<number>()
  packages: StockQuantPackage[];
  searchString:number 
  package: StockQuantPackage;
  s7variable: any;
  socket: any;
  scanner: any;
  packageReaded: boolean = false;
  constructor(
    private elementRef:ElementRef,
    private route: ActivatedRoute,
    private odooEM: OdooEntityManager
  ) {

  }

  async ngOnInit(): Promise<void> {

    this.video = this.elementRef.nativeElement.querySelector("video");
    this.canvas = this.elementRef.nativeElement.querySelector("canvas") as HTMLCanvasElement
    this.ctx = this.canvas.getContext('2d');

    this.video.addEventListener("play", x => {
      this.initScanner(this.video.srcObject)
    })

    this.forkliftid = this.route.snapshot.paramMap.get('id');
    this.start()

    var c = [['state','in',['assigned','waiting']]]
    this.pickings = await this.odooEM.search<StockPicking>(new StockPicking(),c,200,"","state").toPromise()

    this.search$.pipe(debounceTime(200)).subscribe(async s => {
      if (s == null)
        this.packages = []
      else 
        this.packages = await firstValueFrom(this.odooEM.search<StockQuantPackage>(new StockQuantPackage(), [['name', "ilike", s.toString()]],  50))
    })
    
  }

  onChangeSearch() {
    this.search$.next(this.searchString)
  }

  formatLocation(name:string) {
    if (name && name.includes("Magazzino C"))
      return (name.split("/").slice(-3).join("/"))
    if (name && name.includes("LOM/Stock/"))
      return (name.split("/").slice(2).join("/"))
    return name
  }

  async onPackage(p:StockQuantPackage) {
    this.package = p
    this.packageReaded = false

    if (this.scanner)
      await this.scanner.open()

    let ls = await firstValueFrom(this.odooEM.search<StockLocation>(new StockLocation(),[["location_id", "parent_of", p.location_id.id]])) 
    let row = ls.find(l => { return l.id == FILA_1_ID || l.id == FILA_2_ID || l.id == FILA_3_ID})
    this.s7variable = row ? row.name : null

  }

  async onPackageDone() {
    try {
      this.scanner.pause()
    } catch(e) {}
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
    this.package = null
    this.search$.next(null)
  }
  
  start() {

    let peerConnection;
    const config = {
      iceServers: [
        { 
          "urls": "stun:stun.l.google.com:19302",
        }
      ]
    };

    this.socket = io("http://192.168.30.153:1337");
    

    this.socket.on("offer", (id, description) => {
      peerConnection = new RTCPeerConnection(config);
      peerConnection
        .setRemoteDescription(description)
        .then(() => peerConnection.createAnswer())
        .then(sdp => peerConnection.setLocalDescription(sdp))
        .then(() => {
          this.socket.emit("answer", id, peerConnection.localDescription);
        });
      peerConnection.ontrack = event => {
        this.video.srcObject = event.streams[0];
      };
      peerConnection.onicecandidate = event => {
        if (event.candidate) {
          this.socket.emit("candidate", id, event.candidate);
        }
      };
    });

    this.socket.on("plc_read", (message) => {
      console.log("PLC READ", message)
      var vs = JSON.parse(message)
      vs.forEach(v => {
        this.variables[v.name] = v
      })
    })

    this.socket.on("candidate", (id, candidate) => {
      peerConnection
        .addIceCandidate(new RTCIceCandidate(candidate))
        .catch(e => console.error(e));
    });

    this.socket.on("connect", () => {
      this.socket.emit("watcher", this.forkliftid);
    });

    this.socket.on("broadcaster", (x) => {
      this.socket.emit("watcher",this.forkliftid)
    });
  }
  

  openRow(varid) {

    if (varid == "F1")
     var values = [true,false,false]
    else if (varid == "F2")
      var values = [false,true,false]
    else if (varid == "F3")
      var values = [false,false,true]

    this.socket.emit("plc_write", "COMPATTABILE", ["F1", "F2", "F3"], values)
  }


  getPackage(pack:StockQuantPackage) {
    let p = new StockPicking()
    // p.picking_type_id = 
    p.serialize()
  }


  async ngAfterViewInit(): Promise<void> {

  }  

  async initScanner(s) {


    // let enhancer = await Dynamsoft.DCE.CameraEnhancer.createInstance();
    Dynamsoft.DBR.BarcodeScanner.organizationID = "367928";
    Dynamsoft.DBR.BarcodeReader.license = 'DLS2eyJoYW5kc2hha2VDb2RlIjoiMzY3OTI4LVRYbFhaV0pRY205cVgyUmljZyIsIm9yZ2FuaXphdGlvbklEIjoiMzY3OTI4IiwiY2hlY2tDb2RlIjotMjE0NDE2NTA3MX0=';
    // Dynamsoft.DBR.BarcodeScanner.license = 'DLS2eyJoYW5kc2hha2VDb2RlIjoiMzY3OTI4LVRYbFhaV0pRY205cVgyUmljZyIsIm9yZ2FuaXphdGlvbklEIjoiMzY3OTI4IiwibWFpblNlcnZlclVSTCI6Imh0dHBzOi8vbWx0cy5keW5hbXNvZnQuY29tLyIsInN0YW5kYnlTZXJ2ZXJVUkwiOiJodHRwczovL3NsdHMuZHluYW1zb2Z0LmNvbS8iLCJjaGVja0NvZGUiOjkyMDY3NDY0NX0=';

    this.scanner = await Dynamsoft.DBR.BarcodeScanner.createInstance({
      videoSrc : s,
      expectedBarcodesCount: 10
    })


    console.log("START SCANNER", this.scanner,s)
    this.scanner.videoSrc = s
    

    console.log("SS", s)
    var ss = s.getVideoTracks()[0].getSettings()
    
    // size canvas as the stream
    this.canvas.width = ss.width
    this.canvas.height = ss.height

    // ratio 
    var rw = this.video.offsetWidth  / this.canvas.width 
    var rh = this.video.offsetHeight  / this.canvas.height

    var l = ((this.video.offsetWidth ) - (this.canvas.width * rh) ) / 2
    this.canvas.style.transform = "scale(" + rw + ")"
    this.canvas.style.transformOrigin = "0 0"
    this.canvas.style.left = l.toString() + "px"
    
    // this.canvas.style.top = t.toString() + "px"
    
    this.rsl = await this.scanner.getResolution()

    // let scanSettings = await scanner.getScanSettings();
    // scanSettings.expectedBarcodesCount = 3
    // await scanner.updateScanSettings(scanSettings);

    let runtimeSettings = await this.scanner.getRuntimeSettings();
    // runtimeSettings.region.regionLeft = 25;
    // runtimeSettings.region.regionTop = 0;
    // runtimeSettings.region.regionRight = 75;
    // runtimeSettings.region.regionBottom = 50;
    // runtimeSettings.region.regionMeasuredByPercentage = true;
    runtimeSettings.expectedBarcodesCount = 10;
    // runtimeSettings.deblurLevel = 0;

    // runtimeSettings.deblurModes[0] = Dynamsoft.DBR.EnumDeblurMode.DM_SHARPENING; // sets the highest priority item to Gray Equalization

    runtimeSettings.minResultConfidence = 10;

    // runtimeSettings.furtherModes.imagePreprocessingModes[0] = Dynamsoft.DBR.EnumImagePreprocessingMode.IPM_GRAY_SMOOTH;
    // runtimeSettings.furtherModes.imagePreprocessingModes[1] = Dynamsoft.DBR.EnumImagePreprocessingMode.IPM_SHARPEN_SMOOTH;

    await this.scanner.updateRuntimeSettings(runtimeSettings);

    console.log("TTT2 ", window.innerHeight,  this.canvas.height, rw)

    this.scanner.onFrameRead = (results:any) => {
      if (results.length > 0) {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

        var offsetx = 0
        var offsety = 0
        var scale = 1
        
        console.log("RES", results)
        
        results.forEach(result => {
          
          let x1 = (result.localizationResult.x1 - offsetx) / scale
          let y1 = (result.localizationResult.y1 - offsety) / scale
          let x2 = (result.localizationResult.x2 - offsetx) / scale
          let y2 = (result.localizationResult.y2 - offsety) / scale
          let x3 = (result.localizationResult.x3 - offsetx) / scale
          let y3 = (result.localizationResult.y3 - offsety) / scale
          let x4 = (result.localizationResult.x4 - offsetx) / scale
          let y4 = (result.localizationResult.y4 - offsety) / scale

          if (result.barcodeText == this.package?.name) {
            this.ctx.fillStyle = '#28a745'
            this.packageReaded = true
          } else {
            this.ctx.fillStyle = 'red'
          }
          this.ctx.beginPath()
          this.ctx.moveTo(x1, y1)
          this.ctx.lineTo(x2, y2)
          this.ctx.lineTo(x3, y3)
          this.ctx.lineTo(x4, y4)

          this.ctx.fill();
        })

    }
  }

  }
}
