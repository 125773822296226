import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { debounce, debounceTime } from 'rxjs/operators';
import { StockQuantPackage } from 'src/app/models/stock-quant-package';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';

@Component({
  selector: 'app-picking-package-location-picker',
  templateUrl: './picking-package-location-picker.component.html',
  styleUrls: ['./picking-package-location-picker.component.scss']
})
export class PickingPackageLocationPickerComponent implements OnInit {

  @Output() onPackage:EventEmitter<StockQuantPackage|null> = new EventEmitter<StockQuantPackage|null>()

  textInput:ReplaySubject<string> = new ReplaySubject(1)
  code: any;
  needToCreate: boolean = false;


  constructor(
    private el:ElementRef,
    private odooEM:OdooEntityManager
  ) { }
  
  ngOnInit() {
    this.textInput.pipe(debounceTime(300)).subscribe(x=> {
      this.el.nativeElement.querySelector('input').value = ""
      this.onCode(x)
    })
  }

  selectCode(code) {
    
  }

  async create() {
    var p = await this.odooEM.create<StockQuantPackage>(new StockQuantPackage(), {
      // 'location_id': this.scanningPackageDest.location_dest_id.id,
      'name': this.code
    }).toPromise()
    this.onPackage.emit(p)
  }

  async onCode(c) {
    
    var ps = await this.odooEM.search<StockQuantPackage>(new StockQuantPackage(),[["name", "=", c]]).toPromise()
    this.code = c
    if (ps.length == 0) {
      this.needToCreate = true
    }
    else {
      this.onPackage.emit(ps[0])
    }
  }

  cancel() {
    this.onPackage.emit(null)
  }
  
  onPackageScanner(p) {
    this.onPackage.emit(p)
  }

  scan() {
    setTimeout(x=> {
      this.el.nativeElement.querySelector('input').focus()
    },300)
  }

}
