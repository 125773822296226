
<div class="dropdown d-inline me-3" *ngIf="gmailTC">
    <button class="btn" type="button" id="gmaildd" data-toggle="dropdown" aria-expanded="false">
        <i class="fad fa-envelope text-primary" [ngClass]="customSize ? customSize : 'fa-3x'"></i><br> GMail
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <li><a class="dropdown-item py-2" target="_blank" href="https://mail.google.com/mail/u/0/#inbox?compose=new&subject={{gmailTC}}">Invia Email</a></li>
      <li><a class="dropdown-item py-2" target="_blank" href="https://mail.google.com/mail/u/0/#search/{{gmailTC}}">Cerca su Gmail</a></li>
      <li><a class="dropdown-item py-2" target="_blank" href="https://groups.google.com/a/galimberti.eu/g/odoo/search?q={{gmailTC}}">Cerca in Gruppi</a></li>
    </ul>
</div>

<button 
    *ngIf="driveFolders && driveFolders.length == 0"
    class="btn " 
    (click)="createFolder()"
>
    <i class="fa fa-folder text-muted" [ngClass]="customSize ? customSize : 'fa-3x'"></i><br> Drive
</button>

<a 
    *ngIf="driveFolders && driveFolders.length > 0"
    target="_blank"
    class="btn " 
    [href]="getDriveLinkFromId(driveFolders[0].value)"
>
    <i class="fa fa-folder text-primary" [ngClass]="customSize ? customSize : 'fa-3x'"></i><br> Drive
</a>

<ng-container *ngIf="trelloBoardId">
    <a  *ngFor="let c of trelloCards"
        class="btn  text-center" 
        href="https://trello.com/c/{{c.card_id}}"
        target="_blank"
    >
        <i class="fab fa-trello text-primary" [ngClass]="customSize ? customSize : 'fa-3x'"></i><br> Trello
    </a>
</ng-container>

<button *ngIf="trelloBoardId && (!trelloCards || trelloCards.length == 0)"
    class="btn me-3 text-center" 
    (click)="openModal()"
    [disabled]="driveFolders && driveFolders.length == 0"
>
    <i class="fab fa-trello text-dark" [ngClass]="customSize ? customSize : 'fa-3x'"></i><br> Trello
</button>



<!-- <button
    class="btn me-3 text-center" 
>
    <i class="fad fa-envelope text-primary" [ngClass]="customSize ? customSize : 'fa-3x'"></i><br> GMail
</button> -->


<ng-template #content let-modal>
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

        <app-trello-insert 
            *ngIf="!isPos" 
            [title]="cfg" 
            [trelloTemplateCard]="trelloTemplateCard"
            [trelloListId]="trelloListId" 
            [trelloBoardId]="trelloBoardId" 
            (onInsert)="onInsert($event)" >
        </app-trello-insert>

    </div>
</ng-template>


