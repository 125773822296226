import { OdooModel } from './odoo-model.model';
import { OdooSerializableInterface } from '../shared/interfaces/odoo-serializable-interface';
import { OdooRelationship } from './odoo-relationship.model';
import { GenericOrderLine } from './generic-order-line.model';
import { OdooMultiRelationship } from './odoo-multi-relationship.model';
import { Placeholder } from './placeholder.model';
import { Product } from './product.model';
import { StockLocationRoute } from './stock-location-route.model';
import { StockMove } from './stock-move';

export class SaleOrderLine extends GenericOrderLine implements OdooSerializableInterface<SaleOrderLine> {

  route_id:OdooRelationship<StockLocationRoute> = new OdooRelationship<StockLocationRoute>()
  
  move_ids:OdooMultiRelationship<StockMove> = new OdooMultiRelationship<StockMove>(StockMove)

  public ODOO_MODEL = 'sale.order.line';
  virtual_available_at_date:number = 0;
  forecast_expected_date:boolean = false;
  scheduled_date:string="";
  qty_available_today:number = 0;
  free_qty_today:number = 0;
  
  // qty_delivered_sale: number;
  // confezioni_qty_lorda_delivered: number;
  // purchase_line_id: OdooRelationship;
  // fornitore_id: OdooRelationship;
  // purchase_id: OdooRelationship;
  // supplier_purchase_id: OdooRelationship;
  // notes: string;
  // working_code: string;
  // qty_lorda_so_pz = 0;

  static createFromProduct(orderId: number, product?: Product) {
    return {
      order_id: orderId,
      product_id: product ? product.id : null,
      date_planned: new Date().toDateString(),
      product_uom_qty: 0,
      pezzi: 1,
      price_unit: null,
      product_qty: 0,
      product_qty_po: 0,
      
      // default_code: product.default_code,
      product_uom: (product && product.uom_id) ? product.uom_id.id : null,
      ODOO_MODEL: 'sale.order.line'
    };
  }

  create(): SaleOrderLine {
    return new SaleOrderLine();
  }
}


