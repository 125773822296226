

<app-order-header
  showInventory="true"
  (actionFromHeaderEmitter)="handleHeaderEvent($event)"
  [isSomethingChecked]="true"
  [order]="order"
  [canConfirm]="order?.state == 'draft'"
  [loading]="loading"
></app-order-header>


<app-order-inventory 
  [inventoryClosed]="inventoryClosed"
  (toggleInventory)="inventoryClosed = !inventoryClosed"
  (addOrderLine)="addOrderLine($event)"
>
</app-order-inventory>



<div class="table-container h-100" >

  <table class="table table-bordered tableFixHead table-hover" id="order" style="margin-top:62px" *ngIf="order">
    <thead>
    <tr valign="middle">
      <th class="table-light">ID</th>
      <th class="table-light">Prodotto</th>
      <th class="table-info">Larg</th>
      <th class="table-info">Spes</th>
      <th class="table-info">Lung</th>
      <th class="table-light">Quantità</th>
      <th class="table-info">Disp</th>
      <th class="table-info" *ngIf="order.state == 'sale'">Consegnati</th>
      <th class="table-light" *ngIf="order.state == 'sale'">Operazioni</th>
      <th class="table-light">Percorso</th>
    </tr>
    </thead>
    <tbody>
    <tr style="vertical-align: middle;" *ngFor="let line of order?.order_line?.values ; index as i" data-print-row>
      <td>
        <!-- <input type="checkbox" [checked]="move.checked"
               (change)="move.checked = !move.checked; updateOrderLine()">
        &nbsp;<span data-print-col>{{move.product_id.id}}</span> -->
      </td>
      <td data-print-col>
        <a class="text-dark" href="https://o2.galimberti.eu/web?debug=1#id={{line.product_id.value.product_tmpl_id.id}}&cids=1&menu_id=182&action=288&model=product.template&view_type=form" target="_blank" (click)="addNote(move)">{{line.name}}</a>
      </td>
  
      

  
      <!-- DIMENSIONI-->
       <td class="text-right">
        <input-number
          data-print-col
          [disabled]="true"
          [ngModel]="getVariantAttribute(line, 'width')?.name"></input-number>
        <span class="badge badge-muted">mm</span>
      </td>

      <td class="text-right">
        <input-number
          data-print-col
          [disabled]="true"
          [ngModel]="getVariantAttribute(line, 'height')?.name"></input-number>
        <span class="badge badge-muted">mm</span>
      </td>

      <td class="text-right" *ngIf="isLineDoubleUM(line.product_id.value)" [ngClass]="{'editable': isLineEditable(line)}">
        <input-number
          data-print-col
          [disabled]="!isLineEditable(line)"
          (change)="updateVariant(line, $event)"
          [ngModel]="getVariantAttribute(line, 'length')?.name"></input-number>
        <span class="badge badge-muted">mm</span>
      </td>
      
      
    
      <app-input-double-um *ngIf="isLineDoubleUM(line.product_id.value)" [disabled]="false"
        [(ngModel)]="line.product_uom_qty"
        (change)="updateLine('product_uom_qty', line, $event)"
        [product]="line.product_id.value"
      >
      </app-input-double-um>
    
      
      <!-- disponibilità -->
      <!-- <td>
        ..{{line.qty_available_today}}..{{line.free_qty_today}}..{{line.product_uom_qty}}

      </td> -->

      <td>
        <a  *ngIf="line.product_uom_qty > 0 && line.free_qty_today <= 0 "
            href="https://o2.galimberti.eu/web?debug=1#cids=1&menu_id=256&action=429&model=stock.warehouse.orderpoint&view_type=list" 
            target="_blank" 
            class="fa fa-2x fa-square text-danger">
        </a>

        <a  *ngIf="line.product_uom_qty > 0 && line.free_qty_today > 0 && line.free_qty_today < line.product_uom_qty" 
            href="https://o2.galimberti.eu/web?debug=1#cids=1&menu_id=256&action=363&active_id=62616&active_model=product.template" 
            target="_blank"
            class="d-flex flex-row align-items-center">
          <i class="fa fa-2x fa-square text-warning me-2"></i>
          <div>
            <span>{{line.forecast_expected_date | date}}</span>
            <br>Solo {{line.free_qty_today}} !
          </div>
        </a>

        <a  *ngIf="line.product_uom_qty > 0 && line.free_qty_today >= line.product_uom_qty"
            href="https://o2.galimberti.eu/web?debug=1#cids=1&menu_id=256&action=363&active_id={{line.product_id.id}}&active_model=product.product" 
            target="_blank"
            class="d-flex flex-row align-items-center">
          <i class="fa fa-2x fa-square text-success me-2"></i>
          <span *ngIf="line.forecast_expected_date">{{line.forecast_expected_date | date}}</span>
        </a>
      </td>
      <!-- <td class="" *ngIf="line.free_qty_today > 0 && line.free_qty_today < line.product_uom_qty" >
        <a href="https://o2.galimberti.eu/web?debug=1#cids=1&menu_id=256&action=363&active_id=62616&active_model=product.template" 
          target="_blank"
          class="d-flex flex-row align-items-center">
          <i class="fa fa-2x fa-square text-warning me-2"></i>
          <div>
          <span>{{line.forecast_expected_date | date}}</span>
          <br>Solo {{line.free_qty_today}} !
          </div>
        </a>
      </td>

      <td *ngIf="line.free_qty_today >= line.product_uom_qty" >
        <a  href="https://o2.galimberti.eu/web?debug=1#cids=1&menu_id=256&action=363&active_id={{line.product_id.id}}&active_model=product.product" 
            target="_blank"
            class="d-flex flex-row align-items-center">
          <i class="fa fa-2x fa-square text-success me-2"></i>
          <span *ngIf="line.forecast_expected_date">{{line.forecast_expected_date | date}}</span>
        </a>
      </td> -->
      
      
      
      <td class="" data-print-col *ngIf="order.state == 'sale'">
      </td>
      <td class="" data-print-col *ngIf="order.state == 'sale'">
        <a
          class="badge text-white "
          *ngFor="let mo of getMoves(line.move_ids.values[0])"
          [ngClass]="getMoveClass(mo)"
          target="_blank"
          href="/picking/{{mo.picking_id.id}}"
          
        > 
        {{mo.rule_id.name}}
           </a>
        <!-- href="https://o2.galimberti.eu/web#id={{mo.picking_id.id}}&cids=1&menu_id=256&action=416&model=stock.picking&view_type=form" -->
        <!-- <a class="badge text-white bg-success" *ngFor="let mo of line.move_ids.values" click="alert('a')">{{mo.picking_id.value.name}} -- {{mo.picking_id.value.state}}</a> -->
      </td>
      <td class="editable" data-print-col>
        <select [(ngModel)]="line.route_id.id" (change)="updateLineRoute(line)">
            <option *ngFor="let r of selectableRoutes" [ngValue]="r.id">{{r.name}}</option>
        </select>
      </td>
    </tr>
    </tbody>
  </table>

</div>