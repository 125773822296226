import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Product } from '../../models/product.model';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { OdooEntityManager } from '../../shared/services/odoo-entity-manager.service';
import { ProductTemplate } from 'src/app/models/product.template.model';

@Component({
  selector: 'app-order-inventory',
  templateUrl: './order-inventory.component.html',
  
})

export class OrderInventoryComponent implements OnInit {
  // @Input() inventoryClosed:boolean ;
  @Output() toggleInventory = new EventEmitter();
  @Output() addOrderLine = new EventEmitter<Product>();
  //@HostBinding('class.d-none') 
  @Input() inventoryClosed = true;


  inputSearch: UntypedFormControl;
  activeTemplate: ProductTemplate|null;

  products: Product[];
  variants: Product[] = []
  groups: any;

  constructor(private odooEm: OdooEntityManager) {
  }


  backFromVariants() {
    this.variants = []
  }
  
  browseVariants(t:ProductTemplate) {
    this.variants = t.product_variant_ids.values
  }
  
  ngOnInit() {
    this.inputSearch = new UntypedFormControl('');

    this.inputSearch.valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(() => {
        return this.getProducts(this.inputSearch.value)
          .pipe(catchError(() => of([])));
      }),
    ).subscribe(products => {
      products.sort((a,b) => b.qty_available - a.qty_available)
      this.products = products;
    });

    console.log("inventoryClosed ", this.inventoryClosed)
  }

  getProducts(input): Observable<Product[]> {
    if (!input && !input.length) {
      return of([]);
    }
    const filters = [];
    input.split(' ').forEach(v => {
      filters.push(
        '&',
        ['display_name', 'ilike', v.replace(/[\s\*]/g, "%25")],
        ['active', '=', 'True']
      );
    });
    return this.odooEm.search<Product>(new Product(), filters, 2000,null,"qty_available ASC");
  }

  closeInventory() {
    // this.toggleInventory.emit();
  }

  async addOrderLineFromProduct(product:ProductTemplate|Product) {

    
    console.log("add product ", product)
    if (product instanceof ProductTemplate) {
      alert("k")
      await this.odooEm.resolve(product.product_variant_ids).toPromise()
      // this.activeTemplate = product
      this.browseVariants(product)
    } else {
      this.addOrderLine.emit(product);
    }

    // var x = await this.odooEm.resolve(product.product_template_attribute_value_ids).toPromise()
    // console.log("xxx", x)
  }

}
