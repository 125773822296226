import { AfterViewInit, Component, forwardRef, Input, OnChanges, OnInit, Provider, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Product } from 'src/app/models/product.model';

export const WRAPPER_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputDoubleUmComponent),
  multi: true
};

@Component({
  selector: 'app-input-double-um',
  templateUrl: './input-double-um.component.html',
  styleUrls: ['./input-double-um.component.scss'],
  providers: [WRAPPER_CONTROL_VALUE_ACCESSOR]
})
export class InputDoubleUmComponent implements OnInit,ControlValueAccessor,OnChanges {
  innerValue:string = ''
  innerValueInPcs:string = ''
  @Input() product:Product
  
  private change: any;
  private touched: any;
  volume: number;

  constructor() { }


  ngOnChanges(changes: SimpleChanges): void {
    console.log("changes", changes)
    this.updateVolume()
    if (this.change)
      this.onChangeInPcs()
  }

  updateVolume() {

    // return
    var x,y,z
    if (!this.product)
      return


    this.product.product_template_attribute_value_ids.values.forEach(a => {
      if (!a || !a.name)
      return 
      if (a.attribute_id.name == "length")
        z = Number(a.name.replace(/\,/g, '.').replace(/,/, '.'))
      if (a.attribute_id.name == "width")
        x = Number(a.name.replace(/\,/g, '.').replace(/,/, '.'))
      if (a.attribute_id.name == "height")
        y = Number(a.name.replace(/\,/g, '.').replace(/,/, '.'))
    })

    if (!x || !y || !z)
      this.volume = - 1
    else
      this.volume = (x * y * z)/1000000000;
  }

  
  ngOnInit() {

    console.log("NNNNNN ",this )
    this.updateVolume()
    var p = this.innerValue.replace(/\./g, '').replace(/,/, '.');
    this.innerValueInPcs = (Number(p) / this.volume).toLocaleString()
  }

  writeValue(obj: any): void {
    console.log("NNNNN WRITE VALUE",obj)
    this.innerValue = Number(obj).toLocaleString();
    console.log(Number(this.innerValue) , this.volume)
    var p = this.innerValue.replace(/\./g, '').replace(/,/, '.');
    this.innerValueInPcs = (Math.ceil(Number(p) / this.volume)).toLocaleString()
  }

  registerOnChange(fn: any): void {
    this.change = fn;
  }

  registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
  }

  onChange() {
    console.log("NNNNN  WRITE Change")
    var p = this.innerValue.replace(/\./g, '').replace(/,/, '.');
    var inpcs = (Math.ceil((Number(p) / this.volume)))
    var inum = inpcs * this.volume
    this.innerValueInPcs = inpcs.toLocaleString()
    this.innerValue = inum.toLocaleString()
    this.change(inum);
  }

  onChangeInPcs() {
    console.log("NNNNN onchangins")
    var p = this.innerValueInPcs.replace(/\./g, '').replace(/,/, '.');
    var inpcs = (Math.ceil(Number(p)))
    var inum = inpcs * this.volume
    this.innerValueInPcs = inpcs.toLocaleString()
    this.innerValue = inum.toLocaleString()
    this.change(inum);
  }


}
