import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OdoorpcService } from '../shared/services/odoorpc.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public pwd: string = "admin";
  public user: string;
  loading: boolean;

  constructor(
    private rpc: OdoorpcService,
    private router:Router,
    private route:ActivatedRoute,
      ) {}

  ngOnInit() {
    var sid = this.route.snapshot.paramMap.get('sid');
    alert(sid)
    // document.cookie = "session_id=" + sid;
    this.rpc.sid = sid
    this.router.navigate(["home"])

  }

  // async login() {

  //   this.loading = true
  //   var l = await this.rpc.login({
  //     db: "galimberti2",
  //     password: this.pwd,
  //     login: this.user
  //   })
    
  //   if (l.error) 
  //     alert("Impossibile collegarsi. Controllare nome utente e password e riprovare")
  //   else  {
  //     this.router.navigate(["home"])

  //   }
  //   // this.authenticationService.authorize();
  // }


  // loginWithGoogle() {
  //   var url = 'https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=470272109138-9eb2kmvo6h844vg8hkrc2e8kfhiucv13.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost:4201%2Fauth_oauth%2Fsignin&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&state=%7B%22d%22%3A+%22galimberti2%22%2C+%22p%22%3A+3%2C+%22r%22%3A+%22https%253A%252F%252Fo2.galimberti.eu%252Fweb%22%7D'
  //   document.location.href = url;
  // }
}
