<nav class="navbar navbar-expand  navbar-dark fixed-top px-1">
  <button class="btn btn-link text-white " routerLink="..">
    <i class="fas fa-chevron-left"></i>      

  </button>
  <!-- <button class="btn btn-link text-white ml-auto " (click)="save()" [disabled]="contactForm.pristine">Salva</button> -->
  <bar-loader [loading]="loading"></bar-loader>
</nav>
<router-outlet></router-outlet>

<div style="margin-top:54px" *ngIf="contact">
  <form >

    <h3 class="d-flex">Anagrafica </h3>
    <!-- <div class="row py-1">
      <div class="col-4">Tipo</div>
      <div class="col">
        <select class="form-control" invalid >
          <option value="person">Persona</option>
          <option value="company">Società</option>
        </select>
          <small class="form-text text-muted">
          Questo dato è obbligatorio.
        </small>
      </div>
    </div> -->
    
    <div class="row py-1">
      <div class="col-4">Nome</div>
      <div class="col">
        <input name="name" [(ngModel)]="contact.name" (change)="update('name')" class="form-control uppercase">
      </div>
    </div>


    <div class="row py-1">
      <div class="col-4">P. IVA / Cod. Fisc.</div>
      <div class="col">
        <div class="input-group">
          <input class="form-control" name="vat" [(ngModel)]="contact.vat" (change)="update('vat')">
          <div class="input-group-append" *ngIf="contact.vat">
            <button class="btn btn-muted text-white" type="button" (click)="onVAT(contact.vat)">Controlla</button>
          </div>
        </div>
      </div>
    </div>



    <div class="row py-1">
      <div class="col-4">Via</div>
      <div class="col">
        <input name="street" [disabled]="isNew()" [(ngModel)]="contact.street" (change)="update('street')" class="form-control uppercase">
      </div>
    </div>

    <div class="row py-1">
      <div class="col-4">Città</div>
      <div class="col">
        <input name="city" [(ngModel)]="contact.city" (change)="update('city')" class="form-control uppercase">
      </div>
    </div>

    <div class="row py-1">
      <div class="col-4">CAP</div>
      <div class="col">
        <input name="zip" [(ngModel)]="contact.zip" (change)="update('zip')" class="form-control uppercase">
      </div>
    </div>

    <!-- <div class="row py-1">
      <div class="col-4">Note</div>
      <div class="col">
        <input name="comment" [(ngModel)]="contact.comment" (change)="update('comment')" class="form-control uppercase">
      </div>
    </div> -->


    <!-- <div class="row py-1">
      <div class="col-4">Cod. Fiscale</div>
      <div class="col">
        <input class="form-control"  name="fiscal_code" [(ngModel)]="contact.fiscal_code" (change)="update('fiscal_code')">
      </div>
    </div> -->

    <!-- <div class="row py-1">
      <div class="col-4">Cod. Univoco</div>
      <div class="col">
        <input class="form-control"  name="unique_code" [(ngModel)]="contact.unique_code" (change)="update('unique_code')">
      </div>
    </div>

    <div class="row py-1">
      <div class="col-4">IBAN</div>
      <div class="col ">
        <input class="form-control"  name="iban" [(ngModel)]="contact.iban" (change)="update('iban')">
      </div>
    </div> -->

    <br>
    <!-- fidi -->
   

    <!-- <app-trello-insert 
            title="Nuovo" 
            [trelloListId]="trelloListId" 
            [trelloBoardId]="trelloBoardId" 
            (onInsert)="onInsert($event)" >
        </app-trello-insert> -->


    <!-- <div class="row py-1">
      <div class="col-4">Clienti id</div>
      <div class="col">
        <input class="form-control" formControlName="clientid"></div>
    </div> -->
    <!-- <button (click)="obsolete()" *ngIf="contact.state && contact.state != 'cancel'"
            class="btn btn-primary text-white w-100 mt-3">Archivia
    </button>
    <button (click)="restore()" *ngIf="contact.state &&  contact.state == 'cancel'"
            class="btn btn-primary text-white w-100 mt-3">Ripristina
    </button> -->
  </form>
</div>
