import { Component, EventEmitter, HostBinding, HostListener, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-keyboard-scanner',
  templateUrl: './keyboard-scanner.component.html',
  styleUrls: ['./keyboard-scanner.component.scss'],


})
export class KeyboardScannerComponent implements OnInit, OnDestroy {
  listener;
  
  @Output() @HostBinding('class.active') active: boolean = true;

  @HostListener('click', ['$event']) onClick(e) {
    this.active = !this.active
    if (this.active) this.activate()
    else this.deactivate()
  }


  @Output() onCode: EventEmitter<string> = new EventEmitter();
  keybuffer: string = "";

  constructor() { }
  


  ngOnInit(): void {
   if (this.activate)
    this.activate()
  }

  ngOnDestroy(): void {
    if (this.activate)
      this.deactivate()
  }

  onKeyDown(e) {
    if (e.key == "Enter") {
      this.onCode.emit(this.keybuffer)
      this.keybuffer = ""
    } else 
      this.keybuffer = this.keybuffer + e.key
  }

  private activate() {
    this.listener = this.onKeyDown.bind(this)
    window.addEventListener("keydown", this.listener)
  }

  private deactivate() {
    window.removeEventListener("keydown", this.listener)
    this.listener = null
  }

}
