<router-outlet>
</router-outlet>

<nav class="navbar navbar-expand  navbar-dark fixed-top px-1">
  <button class="btn btn-link text-white " routerLink="../">
    <i class="fas fa-chevron-left"></i>      
  </button>
  




  <div class="dropdown ms-auto dropend">
    <button class="btn btn-link text-white dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      <i class="fa fa-bars"></i>
    </button>
    <ul class="dropdown-menu">
      <li><a class="dropdown-item" (click)="onDelete()">Archivia</a></li>
    </ul>
  </div>



  <bar-loader [loading]="loading"></bar-loader>
</nav>

<div *ngIf="contact">
  <div *ngIf="contact && contact.state == 'cancel'" class="d-flex bg-muted p-2 align-items-center full-width" style="margin-top: 40px;">
    Contatto archiviato
    <button class="ms-auto btn btn-success me-2" (click)="restore()">Ripristina</button>
  </div>

  <div *ngIf="contact.state == 'to_check'" class="d-flex bg-muted p-2 align-items-center full-width" style="margin-top: 40px;">
    Contatto non confermato
    <button class="ms-auto btn btn-danger me-2" (click)="obsolete()">Archivia</button>
    <button class="btn btn-success text-white" (click)="confirm()">Conferma</button>
  </div>
</div>

<div style="margin-top:50px" *ngIf="contact">

  <!-- form -->
    <div class="row py-1 mt-2">
      <div class="col-4">Nome</div>
      <div class="col">
        <input name="name" [(ngModel)]="contact.name" (change)="update('name')" class="form-control uppercase">
      </div>
    </div>

    <div class="row py-1">
      <div class="col-4">P. IVA / Cod. Fisc.</div>
      <div class="col">
        <div class="input-group">
          <input class="form-control" name="vat" [(ngModel)]="contact.vat" (change)="update('vat')">
          <div class="input-group-append" *ngIf="contact.vat">
            <button class="btn btn-muted text-white" type="button" (click)="onVAT(contact.vat)">Controlla</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row py-1">
      <div class="col-4">Via</div>
      <div class="col">
        <input name="street" [(ngModel)]="contact.street" (change)="update('street')" class="form-control uppercase">
      </div>
    </div>

    <div class="row py-1">
      <div class="col-4">Città</div>
      <div class="col">
        <input name="city" [(ngModel)]="contact.city" (change)="update('city')" class="form-control uppercase">
      </div>
    </div>

    <div class="row py-1">
      <div class="col-4">CAP</div>
      <div class="col">
        <input name="zip" [(ngModel)]="contact.zip" (change)="update('zip')" class="form-control uppercase">
      </div>
    </div>

  <ul *ngIf="!isViewingChild" class="nav nav-tabs mt-5">
    <li class="nav-item"><a href="" class="active nav-link" data-toggle="tab" data-target="#tabcontatti">Contatti</a>
    </li>
    <li class="nav-item"><a href="" class="nav-link" data-toggle="tab" data-target="#tabassociati">Associati</a></li>
  </ul>
  <div *ngIf="!isViewingChild" class="tab-content mb-3">
    
    <!-- contatti 2 -->
    <div class="tab-pane fade show  active border p-lg-3" id="tabcontatti" role="tabpanel">
      <app-contact-picker [loading]="loading" [contact]="contact"></app-contact-picker>
    </div>

    <!-- associati -->
    <div class="tab-pane fade" id="tabassociati" role="tabpanel">
      <div *ngIf="contact?.contatti_ids?.values?.length" class="list-group">
        <a (click)="redirectContact(c.id)" *ngFor="let c of contact.contatti_ids.values"
           class="list-group-item list-group-item-action rounded-0">
          {{c.name}} <span *ngIf="c.comment" class="text-muted"> - {{c.comment}}</span>
        </a>
      </div>
      <div *ngIf="!contact?.contatti_ids?.values?.length" class="list-group ">
        <a class="list-group-item rounded-0">
          Nessun associato
        </a>
      </div>
    </div>

    <div class="tab-pane fade" id="tabvendite" role="tabpanel">
      <div class="list-group">
        <a (click)='createSaleOrder()' data-test-id="createSaleOrderButton"
           class="d-flex align-items-center list-group-item list-group-item-action rounded-0">
          Nuovo ordine di vendita
        </a>
        <a (click)="redirectSale(s.id)" *ngFor="let s of sales"
           class="d-flex align-items-center list-group-item list-group-item-action rounded-0">
          <div class="mb-1 lead me-auto">{{s.name}}
          </div>
          <div class="badge badge-muted bg-muted">{{localizeOrderState(s.state)}}</div>
        </a>
      </div>
      <div *ngIf="!sales || sales.length == 0" class="list-group ">
        <a class="list-group-item rounded-0">
          Nessun ordine di vendita
        </a>
      </div>
    </div>
    <div class="tab-pane fade" id="tabaquisti" role="tabpanel">
      <div class="list-group">
        <a (click)='createPurchaseOrder()' data-test-id="createPurchasesOrderButton"
           class="d-flex align-items-center list-group-item list-group-item-action rounded-0">
          Nuovo ordine d'acquisto
        </a>
        <a (click)="redirectPurchase(p.id)" *ngFor="let p of purchases"
           class="list-group-item list-group-item-action rounded-0">
          {{p.name}}
        </a>
      </div>
      <div *ngIf="!purchases || purchases.length == 0 " class="list-group ">
        <a class="list-group-item rounded-0">
          Nessun ordine di acquisto
        </a>
      </div>
    </div>
  </div>
</div>
