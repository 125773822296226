<table class="table table-bordered table-hover tableFixHead " id="order">
  <thead>
  <tr>
    <th colspan="3" scope="colgroup" class="table-light">Prodotto</th>
    <th colspan="3" scope="colgroup" class="table-info">Dimensioni</th>
    <th colspan="2" scope="colgroup" class="table-light">Qt richieste</th>
    <th colspan="4" scope="colgroup" class="table-info">Qt lorde</th>
    <th colspan="4" scope="colgroup" class="table-light">Prezzo</th>
    <th colspan="4" scope="colgroup" class="table-info">Vendita</th>
  </tr>
  <tr>
    <th class="table-light">ID</th>
    <th class="table-light">ID F</th>
    <th class="table-light">Descrizione</th>
    <th class="table-info">Larg</th>
    <th class="table-info">Spes</th>
    <th class="table-info">Lung</th>
    <th class="table-light">Unità</th>
    <th class="table-light">UM</th>
    <th class="table-info">Unità</th>
    <th class="table-info">UM</th>
    <th class="table-info">Desc</th>
    <th class="table-info">Arriv</th>
    <th class="table-light">Prezzo</th>
    <th class="table-light">Sconto<br></th>
    <th class="table-light">Finale</th>
    <th class="table-light">Totale</th>
    <th class="table-info">Ordine</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let c of purchaseOrderLines ; index as i" class="highlight" data-print-row>
    <th >
      <input [disabled]="purchaseOrder.state !== 'draft' && purchaseOrder.state !== 'sent'" type="checkbox" (change)="c.checked = !c.checked; updateOrderLine()">
      &nbsp; &nbsp;
      <a data-print-col class="text-dark" target="_blank" href="/inventory/search/{{c.default_code}}">{{c.default_code}}</a>

    <td data-print-col>{{c.product_code}}</td>

    <td data-print-col><a class="text-dark" target="_blank" href="/inventory/search/{{c.default_code}}">{{c.name }}</a>
    </td>

  

    <!-- <td *ngIf="!c.lunghezza_editable" class="text-right" data-print-col>
    </td> -->

    <td *ngIf="c.larghezza_editable" class="editable text-right" data-print-col>
      <input-number
        [disabled]="purchaseOrder.state !== 'draft' && purchaseOrder.state !== 'sent'"
        (change)="updateOrderLine('larghezza',c)"
        [(ngModel)]="c.larghezza"></input-number>
      <span class="badge badge-muted">{{c.larghezza_uom.name}}</span>
    </td>

    <td *ngIf="!c.larghezza_editable" class="text-right" data-print-col>
    </td>

    <!-- <td *ngIf="c.spessore_editable" class="editable text-right" data-print-col>
      <input-number
        [disabled]="purchaseOrder.state !== 'draft' && purchaseOrder.state !== 'sent'"
        (change)="updateOrderLine('spessore', c)"
        [(ngModel)]="c.spessore"></input-number>
      <span class="badge badge-muted">{{c.spessore_uom.name}}</span>
    </td>

    <td *ngIf="!c.spessore_editable" class="text-right" data-print-col>
    </td> -->

      <!-- LUNGHEZZE -->
      <td *ngIf="c.lunghezza_editable || !c.lunghezza_editable" class="editable text-right" data-print-col>
        <input-number
          [disabled]="purchaseOrder.state !== 'draft' && purchaseOrder.state !== 'sent'"
          (change)="updateOrderLine('lunghezza', c)"
          [(ngModel)]="c.lunghezza"></input-number>
        <span class="badge badge-muted">{{c.lunghezza_uom.name}}</span>
      </td>


    <!-- RICHIESTE -->
    <td class="editable text-right" data-print-col>
      <input-number
        data-print-col
        [disabled]="purchaseOrder.state !== 'draft' && purchaseOrder.state !== 'sent'"
        (change)="updateOrderLine('pezzi', c)"
        [(ngModel)]="c.pezzi"></input-number>
      <span class="badge badge-muted">pz</span>
    </td>


    <td class="editable text-right" data-print-col>
      <input-number
        [disabled]="true"
        (change)="updateOrderLine('product_qty_po', c)"
        [(ngModel)]="c.product_qty_po"></input-number>
      <span class="badge badge-muted">{{c.getUOM()}}</span>
    </td>


    <!-- <td class="editable text-right" data-print-col>
        <input [disabled]="order.state != 'draft'" type="text" pattern="[\d\.]+" (keyup.enter)="onEnter($event)" (change)="updateOrderLineToOdoo('product_qty_po', c)" [size]="c.product_qty_po ? c.product_qty_po.length : 1" [(ngModel)]="c.product_qty_po">
        <span class="badge badge-muted">{{c.product_uom_po[1]}}</span>
    </td> -->

    <!-- LORDE -->
    <td class="text-right" data-print-col>
      <span>{{c.qty_lorda_po_pz | number : '1.0-3':'it-IT'}}</span>
      <span class="badge badge-muted">pz</span>
    </td>

    <!-- lorda -->
    <td class="text-right" data-print-col>
      <span>  
        {{c.getQtyLordaUOM() | number : '1.0-3':'it-IT' }}
      </span>
      <span class="badge badge-muted">{{c.getUOM() }}</span>
    </td>


    <td class="text-right" data-print-col>
      <span>{{c.confezioni_qty_lorda | number : '1.0-3':'it-IT'}}</span>
      <span class="badge badge-muted">{{c.product_packaging.name}}</span>
    </td>
    <td data-print-col>
      <span>{{c.qty_received_po | number : '1.0-3':'it-IT'}}</span>
<!--      <span class="badge badge-muted">{{c.qty_received_po}}</span>-->
    </td>
    <!-- PREZZO -->
    <td class="editable text-right" data-print-col>
      <input-number
        [disabled]="purchaseOrder.state !== 'draft' && purchaseOrder.state !== 'sent'"
        (change)="updateOrderLine('price_unit', c)"
        [(ngModel)]="c.price_unit"></input-number>
      <span class="badge badge-muted">€</span></td>
    <td class="editable text-right" data-print-col>
      <input-number
        [disabled]="purchaseOrder.state !== 'draft' && purchaseOrder.state !== 'sent'"
        (change)="updateOrderLine('discount', c)"
        [(ngModel)]="c.discount"></input-number>
      <span class="badge badge-muted">%</span>
    </td>
    <td class="text-right" data-print-col>
      <span>{{c.price_reduce| number : '1.0-3':'it-IT'}}</span>
      <span class="badge badge-muted">€</span>
    </td>
    <td class="text-right" data-print-col>
      <span>{{c.price_subtotal| number : '1.0-3':'it-IT'}}</span>
      <span class="badge badge-muted">€</span>
    </td>

    <!-- VENDITA -->
    <td class="text-left" data-print-col>
      <a href="/sales/{{c?.sale_order_id?.id}}"
         target="_blank">SO{{c?.sale_order_id?.id}}</a>
    </td>
  </tr>
  </tbody>
</table>
